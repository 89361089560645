export class DatePolicy {
  public getPartnershipRequestExpired(date: Date): string {
    const day = date.getDate();

    let expiredDate = 15;
    if (day >= 15) {
      expiredDate = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    }

    return `${date.getFullYear()}년 ${date.getMonth() + 1}월 ${expiredDate}일까지`;
  }
}

export const datePolicy = new DatePolicy();
