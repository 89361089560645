import React from 'react';
import { useRecoilValue } from 'recoil';
import { css } from 'aphrodite';

import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';
import { Image } from '../../../../shared/css/Image';
import { Assets } from '../../shared/Assets';
import { robotoRegular, spoqa } from '../../../../shared/css/css-utils';

const STEP_LIST = [
  {
    stepText: 'STEP 1',
    contentDesktop: '고객 접수',
    contentMobile: '고객 접수',
  },
  {
    stepText: 'STEP 2',
    contentDesktop: '전문가 1:1 배정 및 고객 요구사항 분석',
    contentMobile: '전문가 1:1 배정 및\n' +
      '고객 요구사항 분석',
  },
  {
    stepText: 'STEP 3',
    contentDesktop: '전문가 온/오프라인 미팅',
    contentMobile: '전문가 \n' +
      '온/오프라인 미팅',
    isMobileThird: true,
  },
  {
    stepText: 'STEP 4',
    contentDesktop: '가맹계약',
    contentMobile: '가맹계약',
  },
  {
    stepText: 'STEP 5',
    contentDesktop: '프랜차이즈 창업',
    contentMobile: '프랜차이즈 창업',
    isLast: true,
  },
];

function MatchProcess() {
  const { isMobile, isTablet, isDesktop } = useRecoilValue(totalScreenState);
  const styles = MatchProcessStyle(isMobile, isTablet);

  return (
    <div className={css(styles.wrapper)}>
      <div className={css(styles.titleText)}>
        {isDesktop
          ? <>예비창업자는 어떻게 매칭되나요?</>
          : <>예비창업자는 <span style={{fontWeight: 500}}>어떻게 매칭되나요?</span></>
        }
      </div>
      <div className={css(styles.subTitleText)}>
        {isDesktop
          ? <>예비창업자의 선호도, 예산, 지역 등을 종합적으로 고려해 매칭됩니다.</>
          : <>예비창업자의 선호도, 예산, 지역 등을<br/>종합적으로 고려해 매칭됩니다.</>
        }
      </div>
      {isDesktop ?
        <>
          <div className={css(styles.stepTotalBox)}>
            {STEP_LIST.map((item, index) => (
              <div key={index} className={css(styles.stepBox)}>
                <div>
                  <div className={css(styles.stepText)}>{item.stepText}</div>
                  <Image
                    className={css(styles.stepCircleImage)}
                    src={Assets.Partnership.PartnerStepCircle}
                    alt='점'
                  />
                  <div className={css(styles.contentText)}>
                    {item.contentDesktop}
                  </div>
                </div>
                {!item.isLast &&
                <div className={css(styles.stepLine)}/>
                }
              </div>
            ))}
          </div>
          <div className={css(styles.bottomText)}>
            * 전문가는 프랜차이즈창업센터의 창업 전문가를 의미합니다.
          </div>
        </>
        :
        <>
          <div className={css(styles.stepTotalBox)}>
            {STEP_LIST.filter(
              (item, filterIndex) => filterIndex <= 2).map(
                (item, mapIndex) => (
                  <div key={mapIndex} className={css(styles.stepBox)}>
                    <div>
                      <div className={css(styles.stepText)}>{item.stepText}</div>
                      <Image
                        className={css(styles.stepCircleImage)}
                        src={Assets.Partnership.PartnerStepCircle}
                        alt='점'
                      />
                      <div className={css(styles.contentText)}>
                        {item.contentMobile}
                      </div>
                    </div>
                    {!item.isMobileThird &&
                    <div className={css(styles.stepLine)}/>
                    }
                  </div>
                )
            )}
            <div className={css(styles.stepLineMobileFirstRight)}/>
          </div>
          <div className={css(styles.stepTotalBox)}>
            {STEP_LIST.filter(
              (item, filterIndex) => filterIndex > 2).map(
                (item, mapIndex) => (
                  <div key={mapIndex} className={css(styles.stepBox)}>
                    <div>
                      <div className={css(styles.stepText)}>{item.stepText}</div>
                      <Image
                        className={css(styles.stepCircleImage)}
                        src={Assets.Partnership.PartnerStepCircle}
                        alt='점'
                      />
                      <div className={css(styles.contentText)}>
                        {item.contentMobile}
                      </div>
                    </div>
                    {!item.isLast &&
                    <div className={css(styles.stepLine)}/>
                    }
                  </div>
                )
            )}
            <div className={css(styles.stepLineMobileSecondLeft)}/>
          </div>
        </>
      }
    </div>
  );
}

export default MatchProcess;

const MatchProcessStyle = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  wrapper: {
    width: '100%',
    height: '450px/540px',
    background: '#F9FAFB',

    fontFamily: spoqa,
    fontWeight: 300,
    color: '#454F5D',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    paddingTop: '44px/89px',
  },
  titleText: {
    fontSize: '24px/40px',
    lineHeight: '34px/60px',
    letterSpacing: '-1px/-1px',

    marginBottom: '6px/12px',
  },
  subTitleText: {
    fontSize: '14px/22px',
    lineHeight: '24px/32px',
    textAlign_: 'center/',

    marginBottom: '24px/56px',
  },
  stepTotalBox: {
    display: 'flex',
    justifyContent: 'center',

    position: 'relative',

    width: '100%/',
    height: '144px/',
  },
  stepBox: {
    display: 'flex',

    position: 'relative',
  },
  stepCircleImage: {
    width: '35px/35px',
    height: '35px/35px',
  },
  stepLine: {
    width: '80px/240px',

    margin: '16px 10px/16px 26px',

    borderTop: '1.5px solid #E5E9EA',
  },

  stepLineMobileFirstRight: {
    position: 'absolute',
    top: 0,
    right: 0,

    width: '39px/240px',
    margin: '16px 0/',

    borderTop: '1.5px solid #E5E9EA',
  },
  stepLineMobileSecondLeft: {
    position: 'absolute',
    top: 0,
    left: 0,

    width: '107px/240px',
    margin: '16px 0/',

    borderTop: '1.5px solid #E5E9EA',
  },

  stepText: {
    position: 'absolute',
    top: '50px/50px',
    left: '-10px/-82px',
    textAlign: 'center',

    width: '60px/200px',

    fontFamily: robotoRegular,
    fontSize: '12px/15px',
    lineHeight: '14px/18px',
    letterSpacing: '2px/2px',
    color: '#5E8EC0/#9CBEE1',
  },
  contentText: {
    position: 'absolute',
    top: '70px/77px',
    left: '-43px/-132px',
    textAlign: 'center',

    width: '120px/300px',

    fontWeight_: `${400}/${300}`,
    fontSize: '14px/18px',
    lineHeight: '24px/35px',
    color: '#454F5D',

    whiteSpace_: 'pre/',
  },
  bottomText: {
    fontSize: '/17px',
    lineHeight: '/21px',
    opacity: 0.5,

    marginTop: '/157px',
  },
}, isMobile || isTablet, false);
