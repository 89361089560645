import React from 'react';
import { useRecoilValue } from 'recoil';
import { css } from 'aphrodite';

import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';
import { spoqa } from '../../../../shared/css/css-utils';
import { ReactComponent as ReImg } from '../../../assets/svgs/report-img.svg';
import { NotDesktopBr } from '../../../../shared/presentation/NotDesktopBr';

const Report = () => {
  const {isMobile, isTablet, isDesktop} = useRecoilValue(totalScreenState);
  const styles = ReportStyles(isMobile, isTablet);
  return <div className={css(styles.container)}>
    <div className={css(styles.titleBox)}>
      <div className={css(styles.title)}>
        막막한 프랜차이즈 창업의 솔루션,<br/>
        가맹거래사의&nbsp;<NotDesktopBr/>
        <span className={css(isDesktop ? styles.bold700 : styles.bold500)}>‘프랜차이즈 분석 의견서’</span>
      </div>
      <div className={css(styles.desc)}>
        창업희망자에게 적합한 아이템부터 상권분석,<br/>
        전국 11,741개 가맹본부 중 최적의 브랜드, 창업비용, 수익성<br/>
        분석까지 <span className={css(styles.bold500)}>가맹거래사의 종합의견서</span>를 작성해드립니다.
      </div>
    </div>
    <div>
      <ReImg className={css(styles.reportImage)}/>
    </div>
  </div>;
};

export default Report;

const ReportStyles = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  container: {
    height: '627px/764px',
    display: 'flex',
    flexDirection_: 'column/',
    alignItems_: 'center/',

    padding: '41px 0 0/110px 343px 0 303px',

    color: '#454F5D',
    fontFamily: spoqa,
    backgroundColor: '#F4F4F5',

    textAlign_: 'center/',
    overflow: 'hidden',
  },
  titleBox: {
    width: '/596px',
    marginRight: '/129px',
    marginBottom: '43px/',
  },
  title: {
    fontSize: '24px/34px',
    lineHeight: '34px/48px',
    letterSpacing: '-1px',
    fontWeight: 300,

    margin: '0 0 12px/176px 0 29px',
  },
  bold700: {
    fontWeight: 700,
  },
  bold500: {
    fontWeight: 500,
  },
  desc: {
    fontSize: '14px/22px',
    lineHeight: '24px/32px',
    fontWeight: 300,
    position: 'relative',
  },
  reportImage: {
    width: '301px/548px',
    height: '393px/714px',
  }
}, isMobile || isTablet, false);
