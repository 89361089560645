import React from 'react';
import { useRecoilValue } from 'recoil';
import { css } from 'aphrodite';

import { totalScreenState } from '../../../../shared/presentation/MobileFrame';
import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { Image } from '../../../../shared/css/Image';
import { Assets } from '../../shared/Assets';
import { spoqa } from '../../../../shared/css/css-utils';
import { ReactComponent as UnderLine } from '../../../assets/svgs/underLine.svg';
import { ReactComponent as Advantage } from '../../../assets/svgs/advantage-list.svg';
import { ReactComponent as AdvantageMobile } from '../../../assets/svgs/advantage-list-m.svg';

function CustomMatch() {
  const {isMobile, isTablet, isDesktop} = useRecoilValue(totalScreenState);
  const styles = CustomMatchStyle(isMobile, isTablet);

  return (
    <div className={css(styles.wrapper)}>
      {isDesktop ?
        <>
          <div className={css(styles.backgroundDarker)}/>
          <Image
            src={Assets.CustomBackDesktop}
            alt={''}
            className={css(styles.backgroundImage)}
          />
        </>
        :
        <Image
          src={Assets.CustomBackMobile}
          alt={''}
          className={css(styles.backgroundImage)}
        />
      }
      {isDesktop ?
        <>
          <div className={css(styles.firstTitle)}>
            프랜차이즈 창업, 아직도 <span style={{fontWeight: 500}}>본사로 직접</span> 문의하시나요?
          </div>
          <div className={css(styles.secondTitle)}>
            프랜차이즈창업센터에서는 선별된 프랜차이즈를 ‘비교분석’해드려요
          </div>
        </>
        :
        <>
          <div className={css(styles.firstTitle)}>
            선별된 프랜차이즈를<br/>'비교분석' 매칭해드려요
          </div>
          <div className={css(styles.secondTitle)}>
            프랜차이즈 창업, 아직도 <span style={{fontWeight: 500}}>본사로 직접</span> 문의하시나요?
          </div>
        </>
      }
      <div className={css(styles.middleBox)}>
        <div className={css(styles.sideText, styles.leftText)}>예비창업자</div>
        <div className={css(styles.blueLine)}/>
        <div className={css(styles.franchiseTextBox)}>
          <div className={css(styles.franchiseText)}>
            프랜차이즈창업센터
          </div>
          {isDesktop &&
          <div className={css(styles.freeText)}>무료 매칭·분석 서비스</div>
          }
        </div>
        <div className={css(styles.blueLine)}/>
        <div className={css(styles.sideText)}>
          <div className={css(styles.lastBox)}>
            <div className={css(styles.center)}>
              <div className={css(styles.bold)}>11,741개</div>
              <UnderLine className={css(styles.underLine)}/>
              <div className={css(styles.lastLineHeight)}>프랜차이즈 브랜드</div>
            </div>
          </div>
        </div>
      </div>
      <div className={css(styles.bottomBox)}>
        <div className={css(styles.blurBox)}/>
        {
          !isDesktop ?
            <AdvantageMobile className={css(styles.advantageImg)}/>
            :
            <Advantage className={css(styles.advantageImg)}/>
        }
      </div>
    </div>
  );
}

export default CustomMatch;

const CustomMatchStyle = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  wrapper: {
    width: '100%',
    height: '715px/840px',

    color: 'white',

    position: 'relative',

    fontFamily: spoqa,
    fontWeight: 400,

    paddingTop: '61px/169px',
    overflow: 'hidden',
  },
  backgroundDarker: {
    position: 'absolute',
    top: 0,
    left: 0,

    width: '100%',
    height: '100%',

    zIndex: -5,
    backgroundColor: '#1F242A',
    opacity: 0.65,
  },
  backgroundImage: {
    position: 'absolute',
    top: 0,
    left: 0,

    width: '100%',
    height: '100%',

    zIndex: -10,
  },
  firstTitle: {
    fontWeight: 300,
    fontSize: '24px/23px',
    lineHeight: '34px/34px',
    textAlign: 'center',
    letterSpacing: '-1px/',

    marginBottom: '12px/12px',
  },
  secondTitle: {
    fontWeight: 300,
    fontSize: '14px/40px',
    lineHeight: '24px/60px',
    textAlign: 'center',

    marginBottom: '17px/56px',
  },
  middleBox: {
    display: 'flex',
    flexDirection_: 'column/',
    alignItems_: 'center/',
    justifyContent: 'center',
  },
  sideText: {
    fontWeight_: `${300}/${400}`,
    fontSize: '19px/28px',
    lineHeight: '29px/39px',
    color: '#9cbee1',

    marginTop: '4px/',
    width: '/300px',

    display: 'flex',
    flexDirection: 'column',

    ':nth-child(1)': {
      marginTop: '/16px',
    }
  },
  lastBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
  },
  underLine: {
    width: '84px/117px',
    alignSelf: 'center',
    position: 'relative',
    top: '-5px',
  },
  lastLineHeight: {
    lineHeight: '/39px',
  },
  leftText: {
    textAlign: 'right',

    marginBottom: '3px/',
  },
  blueLine: {
    width: '/104px',
    height: '27px/',
    borderTop: '/1px solid #9cbee1',
    borderLeft: '1px solid #9cbee1/',

    margin: '/30px 20px 0',
  },
  franchiseTextBox: {
    margin: '10px 0/',
  },
  franchiseText: {
    fontWeight_: `${400}`,
    fontSize: '19px/27px',
    lineHeight: '29px/39px',
    color: '#E5E9EA',
    textAlign: 'center',

    width: '185px/280px',
    height: '46px/65px',

    border: '1px solid #9cbee1',
    borderRadius: '38px/38px',

    paddingTop: '8px/14px',
    marginBottom: '/16px',
  },
  freeText: {
    fontWeight_: `/${300}`,
    fontSize: '/20px',
    lineHeight: '/34px',
    textAlign: 'center',
  },
  bottomBox: {
    display: 'flex',
    flexWrap_: 'wrap/',
    justifyContent: 'center',

    marginTop: '53px/73px',
  },
  blurBox: {
    position: 'absolute',
    bottom: 0,

    width: '100%',
    height: '159px',
    background: 'linear-gradient(180deg, rgba(31, 36, 42, 0) 0%, #1F242A 100%)',
  },
  advantageBox: {
    textAlign: 'center',
  },
  advantageImg: {
    width: '414px/1920px',
    height: '263px/327px',
  },
  icon: {
    width: '60px/91px',
    height: '60px/89px',

    margin: '0 21px 13px/0 32px 20px',
  },
  title: {
    fontWeight_: `${400}`,
    fontSize: '13px/20px',
    lineHeight: '20px/30px',

    marginBottom: '28px/',
  },
  bold: {
    fontWeight: 700,
  },
}, isMobile || isTablet, false);
