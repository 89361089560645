import React, { useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Link } from 'react-router-dom';
import { useAtomValue } from 'jotai';
import { css } from 'aphrodite';

import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';
import { spoqa } from '../../../../shared/css/css-utils';
import { ReactComponent as PolicyChecker } from '../../../assets/svgs/policy-checker.svg';
import SuccessPopup from './SuccessPopup';
import { isLoadingState } from '../../../../shared/presentation/LoadingFrame';
import { useInjection } from '../../../../shared/presentation/InversifyProvider';
import { PrivacyPolicyNotCheckedError } from '../../../../shared/store/PrivacyPolicyNotCheckedError';
import { InvalidRequestError } from '../../../../shared/store/InvalidRequestError';
import { PHONE_FORMAT_POLICY, PhoneFormatPolicy } from '../../../../shared/domain/PhoneFormatPolicy';
import {
  FRANCHISE_REQUEST_REPOSITORY,
  FranchiseRequestRepository
} from '../../../../shared/domain/FranchiseRequestRepository';
import { franchisePartnerFormState } from '../../../../shared/infra/AirtableFranchiseRequestRepository';
import { FranchisePartnerForm } from '../../../../shared/infra/FranchisePartnerForm';
import { totalKeywordAtom } from '../../../../shared/presentation/KeywordAtom';

function Request() {
  const {isMobile, isTablet, isDesktop} = useRecoilValue(totalScreenState);
  const styles = RequestStyle(isMobile, isTablet);

  const setIsLoading = useSetRecoilState(isLoadingState);
  const [isSuccess, setIsSuccess] = useState(false);
  const [partnerForm, setPartnerForm] = useRecoilState<FranchisePartnerForm>(franchisePartnerFormState);
  const requestRepository = useInjection<FranchiseRequestRepository>(FRANCHISE_REQUEST_REPOSITORY);
  const phoneFormatPolicy = useInjection<PhoneFormatPolicy>(PHONE_FORMAT_POLICY);
  const togglePolicy = () => setPartnerForm(v => v.copy().setIsPrivacyPolicyChecked(!v.isPrivacyPolicyChecked));
  const keyword = useAtomValue(totalKeywordAtom);

  const requestPartnership = async () => {
    setIsLoading(true);

    try {
      partnerForm.checkIsValid();
      await requestRepository?.addRequestForPartner(partnerForm, keyword);
      setIsSuccess(true);
    } catch (e) {
      if (e instanceof PrivacyPolicyNotCheckedError) {
        alert('개인정보취급방침에 동의해주세요.');
        return;
      }
      if (e instanceof InvalidRequestError) {
        alert('필수 사항을 입력해주세요.');
        return;
      }
      alert('간편신청에 실패했습니다.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={css(styles.wrapper)} id={'request'}>
      {isSuccess &&
      <SuccessPopup onClickClose={() => setIsSuccess(false)}/>
      }
      <div className={css(styles.titleText)}>
        {isDesktop
          ? <>가맹본사 <span style={{fontWeight: 700}}>제휴 신청</span></>
          : <>가맹본사 <span style={{fontWeight: 500}}>제휴 신청</span></>
        }
      </div>
      <div className={css(styles.subTitleText)}>
        {isDesktop ?
          <>
            가맹본사 제휴 신청을 해주시면, 확인 후 빠른 시간내에 연락드리겠습니다.
          </>
          :
          <>
            가맹본사 제휴 신청을 해주시면,<br/>
            확인 후 빠른 시간내에 연락드리겠습니다.
          </>
        }
      </div>

      <div className={css(styles.essentialComment)}>*은 필수 사항 입니다.</div>
      <div className={css(styles.totalRequestBox)}>
        <div className={css(styles.labelAndInput)}>
          <div className={css(styles.label)}>업체명*</div>
          <div className={css(styles.inputBox)}>
            <input
              className={css(styles.input)}
              placeholder={'업체명을 입력해주세요'}
              value={partnerForm.companyName}
              onChange={e => setPartnerForm(form => form.copy().setProps({
                companyName: e.target.value,
              }))}
            />
          </div>
        </div>
        <div className={css(styles.labelAndInput)}>
          <div className={css(styles.label)}>담당자*</div>
          <div className={css(styles.inputBox)}>
            <input
              className={css(styles.input)}
              placeholder={'성함을 입력해주세요'}
              value={partnerForm.userName}
              onChange={e => setPartnerForm(form => form.copy().setProps({
                userName: e.target.value,
              }))}
            />
          </div>
        </div>
        <div className={css(styles.labelAndInput)}>
          <div className={css(styles.label)}>연락처*</div>
          <div className={css(styles.inputBox)}>
            <input
              className={css(styles.input)}
              placeholder={'숫자만 입력해주세요'}
              value={partnerForm.phone}
              onChange={e => setPartnerForm(form => form.copy().setProps({
                phone: phoneFormatPolicy?.changeFormat(e.target.value),
              }))}
            />
          </div>
        </div>
        <div className={css(styles.labelAndInput)}>
          <div className={css(styles.label)}>이메일</div>
          <div className={css(styles.inputBox)}>
            <input
              className={css(styles.input)}
              placeholder={'이메일 주소를 입력해주세요'}
              value={partnerForm.email}
              onChange={e => setPartnerForm(form => form.copy().setProps({
                email: e.target.value,
              }))}
            />
          </div>
        </div>
        <div className={css(styles.labelAndInput)}>
          <div className={css(styles.label)}>특이사항</div>
          <div className={css(styles.textareaBox)}>
            <textarea
              className={css(styles.textarea)}
              placeholder={'특이사항이 있으실 경우 적어주세요'}
              value={partnerForm.question}
              onChange={e => setPartnerForm(form => form.copy().setProps({
                question: e.target.value,
              }))}
            />
          </div>
        </div>

        <div className={css(styles.privacyPolicyCheckBox)}>
          <div className={css(styles.policyCheckBox)} onClick={togglePolicy}>
            {partnerForm.isPrivacyPolicyChecked &&
            <PolicyChecker className={css(styles.policyChecker)}/>
            }
          </div>
          <Link
            className={css(styles.policyContentLink)}
            to={'/privacy_policy'}
            target={'_blank'}
          >
            개인정보취급방침
          </Link>
          <div className={css(styles.policyText)}>에 동의합니다 (필수)</div>
        </div>

        <div className={css(styles.bottomArea)}>
          <div
            className={css(styles.requestButton)}
            onClick={requestPartnership}
          >
            프랜차이즈 파트너십 신청
          </div>
        </div>
      </div>
    </div>
  );
}

export default Request;

const RequestStyle = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  wrapper: {
    width: '100%',
    height: '994px/1330px',
    background: '/#f9fafb',

    fontFamily: spoqa,
    fontWeight_: 300,
    color: '#454F5D',

    paddingTop: '48px/130px',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  titleText: {
    fontSize: '24px/40px',
    lineHeight: '36px/60px',
    letterSpacing: '-1px/-1px',

    marginBottom: '8px/7px',
  },
  subTitleText: {
    fontSize: '14px/22px',
    lineHeight: '24px/32px',
    textAlign_: 'center/',

    marginBottom: '24px/30px',
  },
  essentialComment: {
    fontWeight_: 400,
    fontSize: '14px/18px',
    lineHeight: '24px/30px',
    opacity_: `/${0.5}`,
    textAlign: 'right',

    width: '100%/1200px',

    marginBottom: '8px/12px',
    marginRight: '22px/',
  },
  totalRequestBox: {
    width: '100%/1200px',
    height: '100%/900px',
    background: 'white',

    padding: '0 21px/80px 164px',
  },
  labelAndInput: {
    display_: '/flex',
  },
  label: {
    fontWeight_: 400,
    fontSize: '14px/18px',
    lineHeight: '24px/30px',
    textAlign_: '/right',

    width: '100%/67px',
    height: '24px/60px',

    padding: '/14px 0',

    marginBottom: '8px/',
  },
  inputBox: {
    width: '100%/780px',
    height: '52px/60px',

    marginBottom: '16px/33px',
    marginLeft: '/24px',
  },
  input: {
    width: '100%/780px',
    height: '52px/60px',

    border: '1px solid #e5e9ea',
    borderRadius: '5px/4px',

    paddingLeft: '18px/20px',

    fontFamily: spoqa,
    fontWeight_: `${300}/${400}`,
    fontSize: '14px/14px',
    lineHeight: '20px/18px',
    color: '#1F242A',

    '::placeholder': {
      color: '#B5B9BE/#C9CFD7',
    },

    ':-ms-input-placeholder': {
      color: '#B5B9BE/#C9CFD7',
    },
  },
  textareaBox: {
    width: '100%/780px',
    height: '175px/193px',

    marginBottom: '20px/21px',
    marginLeft: '/24px',
  },
  textarea: {
    width: '100%/780px',
    height: '175px/193px',

    border: '1px solid #e5e9ea',
    borderRadius: '5px/4px',

    paddingLeft: '18px/20px',
    paddingTop: '20px/22px',

    fontFamily: spoqa,
    fontWeight_: `${300}/${400}`,
    fontSize: '14px/14px',
    lineHeight: '20px/18px',
    color: '#1F242A',


    '::placeholder': {
      color: '#B5B9BE/#C9CFD7',
    },

    ':-ms-input-placeholder': {
      color: '#B5B9BE/#C9CFD7',
    },

    resize: 'none',
  },
  privacyPolicyCheckBox: {
    display: 'flex',
    alignItems: 'center',

    fontWeight_: `${400}/${300}`,
    fontSize: '15px/15px',
    lineHeight: '15px/19px',
    color: '#454F5D',

    marginLeft: '/91px',
  },
  policyCheckBox: {
    width: '27px/22px',
    height: '27px/22px',
    border: '1px solid #EDEEEF/solid 1px #e5e9ea',
    borderRadius: '5px/',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    marginRight: '11px/12px',

    cursor: 'pointer',
  },
  policyChecker: {
    width: '18px/13px',
    height: '14px/9px',

    fill: 'black',
  },
  policyContentLink: {
    borderBottom: '1px solid #454f5d',
    paddingTop: '/1px',

    cursor: 'pointer',
  },
  policyText: {
    borderBottom: '1px solid #ffffff',
    paddingTop: '/1px',
  },

  bottomArea: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    marginTop: '32px/63px',
  },
  requestButton: {
    width: '100%/407px',
    height: '52px/70px',
    background: '#1f242a',
    borderRadius: '5px/5px',

    fontWeight_: `${500}`,
    fontSize: '15px/18px',
    lineHeight: '19px/23px',
    color: 'white',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    cursor: 'pointer',

    ':hover': {
      backgroundColor: 'white',
      color: '#454F5D',
      border: '1px solid #454F5D',
      transition: '0.3s',
    }
  },
}, isMobile || isTablet, false);
