import React from 'react';
import { useRecoilValue } from 'recoil';
import { css } from 'aphrodite';

import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';
import { spoqa } from '../../../../shared/css/css-utils';
import { ReactComponent as CloseButton } from '../../../assets/partnership/success-popup-close.svg';

interface ISuccessPopupProps {
  onClickClose: () => void,
}

function SuccessPopup({onClickClose}: ISuccessPopupProps) {
  const {isMobile, isTablet, isDesktop} = useRecoilValue(totalScreenState);
  const styles = SuccessPopupStyle(isMobile, isTablet);

  return (
    <div className={'popup background'}>
      <div className={css(styles.wrapper)}>
        <div className={css(styles.mainText)}>
          신청이 완료되었습니다
        </div>
        <div className={css(styles.subText)}>
          가맹본점 제휴 신청이 정상적으로 완료되었습니다.<br/>
          프랜차이즈창업센터 제휴팀에서 연락드려 구체적인<br/>
          사항에 대해 안내드리겠습니다. 감사합니다.
        </div>
        <div
          className={css(styles.confirmButton)}
          onClick={onClickClose}
        >
          확인
        </div>
        {isDesktop &&
        <CloseButton
          className={css(styles.closeButton)}
          onClick={onClickClose}
        />
        }
      </div>
      <div
        className={'background touchable'}
        onClick={onClickClose}
      />
    </div>
  );
}

export default SuccessPopup;

const SuccessPopupStyle = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  wrapper: {
    width: '100%/457px',
    height: '280px/320px',

    backgroundColor: 'white',
    borderRadius: '15px 15px 0 0/5px',

    fontFamily: spoqa,
    fontWeight_: 300,
    color: '#454F5D',

    padding: '48px 24px 40px/50px 40px 31px',

    position_: 'fixed/relative',
    left: '0/',
    bottom: '0/',
  },
  closeButton: {
    width: '/21px',
    height: '/21px',

    position: 'absolute',
    top: '/27px',
    right: '/20px',

    fill: '#454F5D',

    cursor: 'pointer',
  },
  mainText: {
    fontWeight_: `${300}/${400}`,
    fontSize: '24px/26px',
    lineHeight: '34px/39px',
    textAlign_: 'center/',

    marginBottom: '8px/12px',
  },
  subText: {
    fontSize: '14px/17px',
    lineHeight: '24px/30px',
    textAlign_: 'center/',

    marginBottom: '26px/38px'
  },
  confirmButton: {
    width: '100%/377px',
    height: '52px/60px',

    background: '#1F242A',
    borderRadius: '5px/5px',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    fontWeight_: 400,
    fontSize: '15px/16px',
    lineHeight: '19px/20px',
    color: 'white',

    cursor: 'pointer',
  }
}, isMobile || isTablet, false);
