import { selectorFamily } from 'recoil';
import AxiosWrapper from '../AxiosWrapper';

export interface BlogContents {
  img: string;
  desc: string;
  author: string;
  date: string;
  link: string;
}

export const blogContentsState = selectorFamily<BlogContents[], string>({
  key: 'blogContentsState',
  get: domain => {
    return async () => {
      try {
        const { data } = await AxiosWrapper.get(`/${domain}/articles`);
        return [...data, ...data];
      } catch (e) {
        return domain === 'fc' ? DUMMY_DATA_FC : DUMMY_DATA_SB;
      }
    };
  }
});

export const DUMMY_DATA_FC = [
  {
    'img': 'https://blog.k-franchise.org/wp-content/uploads/2021/12/6.-브랜드-분석.png',
    'desc': '※ 2020년 등록 정보공개서를 기준으로 작성된 포스팅 – – (1) 가맹본부의 기본정보 ​ – □ 비에이치씨(BHC)는 2004년 09월 10일에 법인을 설립하면서 본격적으로 치킨 가맹사업을 개시합니다. □ 임금옥 대표님께서 운영하는 (주)비에이치씨의 주소지는 서울 송파구 올림픽로에 위치한 대한제당빌딩입니다. ​ – [비에이치씨(BHC)의 영업표지] Read more…',
    'author': 'Posted By 프랜차이즈창업센터',
    'date': '12월 2, 2021',
    'link': 'https://blog.k-franchise.org/2021/12/02/%ec%a0%84%ec%a7%80%ed%98%84c-%eb%b9%84%ec%97%90%ec%9d%b4%ec%b9%98%ec%94%a8bhc-%ec%b0%bd%ec%97%85%ec%a0%95%eb%b3%b4/'
  },
  {
    'img': 'https://blog.k-franchise.org/wp-content/uploads/2021/11/5.-브랜드-분석.png',
    'desc': '※ 2020년 등록 정보공개서를 기준으로 작성된 포스팅 – – 편의점 창업하면 가장 먼저 생각나는 이미지는 ‘이미 포화상태 아니야?’ 라는 의문입니다. ​ 하지만 편의점은 ‘고객지향 마케팅‘으로 포화시장의 위기를 기회로 바꾼 성공사례라고 평가합니다. – – ​ 편의점 포화론은 과거부터 현재까지 여전히 거론됩니다. Read more…',
    'author': 'Posted By 프랜차이즈창업센터',
    'date': '11월 25, 2021',
    'link': 'https://blog.k-franchise.org/2021/11/25/%ec%84%b8%ea%b3%84-%ec%b5%9c%ec%b4%88-%ed%8e%b8%ec%9d%98%ec%a0%90-%eb%b8%8c%eb%9e%9c%eb%93%9c-%ec%84%b8%eb%b8%90%ec%9d%bc%eb%a0%88%eb%b8%90-%ec%b0%bd%ec%97%85%ec%a0%95%eb%b3%b4/'
  },
  {
    'img': 'https://blog.k-franchise.org/wp-content/uploads/2021/11/4.-브랜드-분석.png',
    'desc': '※ 2020년 등록 정보공개서를 기준으로 작성된 포스팅 (1) 기본정보 분석 ​□ 역전할머니맥주는2016년 10월 27일 (주)역전F&C라는 상호로 법인을 설립하고 2016년 11월 04일 가맹사업을 본격적으로 시작합니다. – [(주)역전에프앤씨 일반 현황] – ​□ 역사를 찾아보면 1970년도에 김칠선님이 잃어버린 딸을 찾기 위해서 1980년도 익산역 Read more…',
    'author': 'Posted By 프랜차이즈창업센터',
    'date': '11월 24, 2021',
    'link': 'https://blog.k-franchise.org/2021/11/24/%eb%86%80%eb%9d%bc%ec%9a%b4-%ec%84%b1%ec%9e%a5%ec%84%b8-%ec%97%ad%ec%a0%84%ed%95%a0%eb%a8%b8%eb%8b%88%eb%a7%a5%ec%a3%bc-1982-%ec%b0%bd%ec%97%85%ec%a0%95%eb%b3%b4/'
  },
  {
    'img': 'https://blog.k-franchise.org/wp-content/uploads/2021/11/3.-브랜드-분석.png',
    'desc': '※ 2021년 등록 정보공개서를 기준으로 작성된 포스팅 – – 1. 가맹본부 기본정보 ​ – □ 배스킨라빈스는 1987년 03월 15일에 아이스크림업으로 가맹사업을 개시하였고 법인은 1985년 06월 07일에 등기를 경료하였습니다. 법인의 상호명은 비알코리아(주)입니다. ​ – □ 비알코리아(주)의 주소지는 충청북도 음성군에 위치하고 2020년도 Read more…',
    'author': 'Posted By 프랜차이즈창업센터',
    'date': '11월 23, 2021',
    'link': 'https://blog.k-franchise.org/2021/11/23/3-31%ea%b0%80%ec%a7%80-%eb%8b%a4%ec%96%91%ed%95%9c-%eb%a7%9b-%eb%b0%b0%ec%8a%a4%ed%82%a8%eb%9d%bc%eb%b9%88%ec%8a%a4-%ec%b0%bd%ec%97%85%ec%a0%95%eb%b3%b4/'
  },
  {
    'img': 'https://blog.k-franchise.org/wp-content/uploads/2021/11/2.-브랜드-분석.png',
    'desc': '※ 2020년 등록 정보공개서를 기준으로 작성된 포스팅 – – □ 요즘 음료 브랜드 중 가장 인기가 있는 곳은 어디냐고 물어본다면 당연 ‘공차’​일 것입니다. □ 공차는 중국 황실에서만 맛 볼 수 있던 프리미엄 퀄리티의 차를 바친다는 의미를 지니고 있습니다. □ 최상의 Read more…',
    'author': 'Posted By 프랜차이즈창업센터',
    'date': '11월 22, 2021',
    'link': 'https://blog.k-franchise.org/2021/11/22/2-%ed%99%a9%ec%8b%a4%ec%97%90-%eb%b0%94%ec%b9%98%eb%8a%94-%ec%b0%a8%ef%a7%be-%ea%b3%b5%ec%b0%a8-%ec%b0%bd%ec%97%85%ec%a0%95%eb%b3%b4/'
  },
  {
    'img': 'https://blog.k-franchise.org/wp-content/uploads/2021/11/1.-창업정보3.png',
    'desc': '– ※ 해당 포스팅은 작성인(가맹거래사)의 개인적 견해로 제작되었습니다. – – (1) 1억원 이하 소자본 아이템 – 프랜차이즈 창업시장의 트렌드는 소자본 창업입니다. 이에따라 과거에는 치킨 창업이 급증을 하였으며 최근에는 밀키트 업체가 우후죽순 생겨나고 있습니다. 치킨 업종과 밀키트 업체의 빠른 성장의 공통점은 Read more…',
    'author': 'Posted By 프랜차이즈창업센터',
    'date': '11월 18, 2021',
    'link': 'https://blog.k-franchise.org/2021/11/18/1-%eb%82%98%ec%9d%98-%ec%b0%bd%ec%97%85%ec%9e%90%ea%b8%88%ec%97%90-%eb%a7%9e%eb%8a%94-%ed%94%84%eb%9e%9c%ec%b0%a8%ec%9d%b4%ec%a6%88-%ec%b0%bd%ec%97%85-%ec%9c%a0%ed%98%95%ec%9d%80/'
  },
  {
    'img': 'https://blog.k-franchise.org/wp-content/uploads/2021/11/1.-브랜드-분석3.png',
    'desc': '※ 2020년 등록 정보공개서를 기준으로 작성된 포스팅 – – (1) 가맹본부의 기본정보 – □ 지코바는 1996년 01월 30일 가맹사업을 개시하고 2016년 10월 12일에 법인을 설립하면서 본격적으로 이름이 알려지기 시작합니다. – □ 지코바를 알게 된 기억이 얼마되지 않은 것 같은데 1990년대부터 Read more…',
    'author': 'Posted By 프랜차이즈창업센터',
    'date': '11월 17, 2021',
    'link': 'https://blog.k-franchise.org/2021/11/17/1-%ec%b9%98%eb%b0%a5%ec%9d%84-%ec%9c%a0%ed%96%89%ec%8b%9c%ed%82%a8-%e3%80%8c%ec%a7%80%ec%bd%94%eb%b0%94%e3%80%8d%ec%b0%bd%ec%97%85%ec%a0%95%eb%b3%b4-%ec%95%8c%ec%95%84%eb%b3%b4%ea%b8%b0/'
  },
  {
    'img': 'https://blog.k-franchise.org/wp-content/uploads/2021/12/6.-브랜드-분석.png',
    'desc': '※ 2020년 등록 정보공개서를 기준으로 작성된 포스팅 – – (1) 가맹본부의 기본정보 ​ – □ 비에이치씨(BHC)는 2004년 09월 10일에 법인을 설립하면서 본격적으로 치킨 가맹사업을 개시합니다. □ 임금옥 대표님께서 운영하는 (주)비에이치씨의 주소지는 서울 송파구 올림픽로에 위치한 대한제당빌딩입니다. ​ – [비에이치씨(BHC)의 영업표지] Read more…',
    'author': 'Posted By 프랜차이즈창업센터',
    'date': '12월 2, 2021',
    'link': 'https://blog.k-franchise.org/2021/12/02/%ec%a0%84%ec%a7%80%ed%98%84c-%eb%b9%84%ec%97%90%ec%9d%b4%ec%b9%98%ec%94%a8bhc-%ec%b0%bd%ec%97%85%ec%a0%95%eb%b3%b4/'
  },
  {
    'img': 'https://blog.k-franchise.org/wp-content/uploads/2021/11/5.-브랜드-분석.png',
    'desc': '※ 2020년 등록 정보공개서를 기준으로 작성된 포스팅 – – 편의점 창업하면 가장 먼저 생각나는 이미지는 ‘이미 포화상태 아니야?’ 라는 의문입니다. ​ 하지만 편의점은 ‘고객지향 마케팅‘으로 포화시장의 위기를 기회로 바꾼 성공사례라고 평가합니다. – – ​ 편의점 포화론은 과거부터 현재까지 여전히 거론됩니다. Read more…',
    'author': 'Posted By 프랜차이즈창업센터',
    'date': '11월 25, 2021',
    'link': 'https://blog.k-franchise.org/2021/11/25/%ec%84%b8%ea%b3%84-%ec%b5%9c%ec%b4%88-%ed%8e%b8%ec%9d%98%ec%a0%90-%eb%b8%8c%eb%9e%9c%eb%93%9c-%ec%84%b8%eb%b8%90%ec%9d%bc%eb%a0%88%eb%b8%90-%ec%b0%bd%ec%97%85%ec%a0%95%eb%b3%b4/'
  },
  {
    'img': 'https://blog.k-franchise.org/wp-content/uploads/2021/11/4.-브랜드-분석.png',
    'desc': '※ 2020년 등록 정보공개서를 기준으로 작성된 포스팅 (1) 기본정보 분석 ​□ 역전할머니맥주는2016년 10월 27일 (주)역전F&C라는 상호로 법인을 설립하고 2016년 11월 04일 가맹사업을 본격적으로 시작합니다. – [(주)역전에프앤씨 일반 현황] – ​□ 역사를 찾아보면 1970년도에 김칠선님이 잃어버린 딸을 찾기 위해서 1980년도 익산역 Read more…',
    'author': 'Posted By 프랜차이즈창업센터',
    'date': '11월 24, 2021',
    'link': 'https://blog.k-franchise.org/2021/11/24/%eb%86%80%eb%9d%bc%ec%9a%b4-%ec%84%b1%ec%9e%a5%ec%84%b8-%ec%97%ad%ec%a0%84%ed%95%a0%eb%a8%b8%eb%8b%88%eb%a7%a5%ec%a3%bc-1982-%ec%b0%bd%ec%97%85%ec%a0%95%eb%b3%b4/'
  },
  {
    'img': 'https://blog.k-franchise.org/wp-content/uploads/2021/11/3.-브랜드-분석.png',
    'desc': '※ 2021년 등록 정보공개서를 기준으로 작성된 포스팅 – – 1. 가맹본부 기본정보 ​ – □ 배스킨라빈스는 1987년 03월 15일에 아이스크림업으로 가맹사업을 개시하였고 법인은 1985년 06월 07일에 등기를 경료하였습니다. 법인의 상호명은 비알코리아(주)입니다. ​ – □ 비알코리아(주)의 주소지는 충청북도 음성군에 위치하고 2020년도 Read more…',
    'author': 'Posted By 프랜차이즈창업센터',
    'date': '11월 23, 2021',
    'link': 'https://blog.k-franchise.org/2021/11/23/3-31%ea%b0%80%ec%a7%80-%eb%8b%a4%ec%96%91%ed%95%9c-%eb%a7%9b-%eb%b0%b0%ec%8a%a4%ed%82%a8%eb%9d%bc%eb%b9%88%ec%8a%a4-%ec%b0%bd%ec%97%85%ec%a0%95%eb%b3%b4/'
  },
  {
    'img': 'https://blog.k-franchise.org/wp-content/uploads/2021/11/2.-브랜드-분석.png',
    'desc': '※ 2020년 등록 정보공개서를 기준으로 작성된 포스팅 – – □ 요즘 음료 브랜드 중 가장 인기가 있는 곳은 어디냐고 물어본다면 당연 ‘공차’​일 것입니다. □ 공차는 중국 황실에서만 맛 볼 수 있던 프리미엄 퀄리티의 차를 바친다는 의미를 지니고 있습니다. □ 최상의 Read more…',
    'author': 'Posted By 프랜차이즈창업센터',
    'date': '11월 22, 2021',
    'link': 'https://blog.k-franchise.org/2021/11/22/2-%ed%99%a9%ec%8b%a4%ec%97%90-%eb%b0%94%ec%b9%98%eb%8a%94-%ec%b0%a8%ef%a7%be-%ea%b3%b5%ec%b0%a8-%ec%b0%bd%ec%97%85%ec%a0%95%eb%b3%b4/'
  },
  {
    'img': 'https://blog.k-franchise.org/wp-content/uploads/2021/11/1.-창업정보3.png',
    'desc': '– ※ 해당 포스팅은 작성인(가맹거래사)의 개인적 견해로 제작되었습니다. – – (1) 1억원 이하 소자본 아이템 – 프랜차이즈 창업시장의 트렌드는 소자본 창업입니다. 이에따라 과거에는 치킨 창업이 급증을 하였으며 최근에는 밀키트 업체가 우후죽순 생겨나고 있습니다. 치킨 업종과 밀키트 업체의 빠른 성장의 공통점은 Read more…',
    'author': 'Posted By 프랜차이즈창업센터',
    'date': '11월 18, 2021',
    'link': 'https://blog.k-franchise.org/2021/11/18/1-%eb%82%98%ec%9d%98-%ec%b0%bd%ec%97%85%ec%9e%90%ea%b8%88%ec%97%90-%eb%a7%9e%eb%8a%94-%ed%94%84%eb%9e%9c%ec%b0%a8%ec%9d%b4%ec%a6%88-%ec%b0%bd%ec%97%85-%ec%9c%a0%ed%98%95%ec%9d%80/'
  },
  {
    'img': 'https://blog.k-franchise.org/wp-content/uploads/2021/11/1.-브랜드-분석3.png',
    'desc': '※ 2020년 등록 정보공개서를 기준으로 작성된 포스팅 – – (1) 가맹본부의 기본정보 – □ 지코바는 1996년 01월 30일 가맹사업을 개시하고 2016년 10월 12일에 법인을 설립하면서 본격적으로 이름이 알려지기 시작합니다. – □ 지코바를 알게 된 기억이 얼마되지 않은 것 같은데 1990년대부터 Read more…',
    'author': 'Posted By 프랜차이즈창업센터',
    'date': '11월 17, 2021',
    'link': 'https://blog.k-franchise.org/2021/11/17/1-%ec%b9%98%eb%b0%a5%ec%9d%84-%ec%9c%a0%ed%96%89%ec%8b%9c%ed%82%a8-%e3%80%8c%ec%a7%80%ec%bd%94%eb%b0%94%e3%80%8d%ec%b0%bd%ec%97%85%ec%a0%95%eb%b3%b4-%ec%95%8c%ec%95%84%eb%b3%b4%ea%b8%b0/'
  }
];

export const DUMMY_DATA_SB = [
  {
    'img': 'https://blog.k-startbiz.org/wp-content/uploads/2022/06/사본-Thumbnail-6.png',
    'desc': '오늘은 부동산 법인설립을 고려하고 계시는 분들을 위해, 부동산 법인 v.s. 개인사업자를 비교해 보는 시간을 가져보겠습니다. 부동산 법인의 신규 설립 비율이 다음과 같이, 전국적으로 작년 3월부터 다시 증가 추세에 있습니다. “이처럼 부동산 법인설립이 증가하는 이유는 무엇일까요?” 1. 세금 부담 감소 부동산 법인을 설립하시는 이유로 ‘절세’를 Read more…',
    'author': 'Posted By 법인설립지원센터',
    'date': '6월 13, 2022',
    'link': 'https://blog.k-startbiz.org/2022/06/13/%eb%b6%80%eb%8f%99%ec%82%b0-%eb%b2%95%ec%9d%b8%ec%84%a4%eb%a6%bd%ea%b3%bc-%ea%b0%9c%ec%9d%b8%ec%82%ac%ec%97%85%ec%9e%90-%ec%a1%b0%ea%b1%b4-%eb%b9%84%ea%b5%90%ed%95%b4%eb%b3%b4%ea%b8%b0/'
  },
  {
    'img': 'https://blog.k-startbiz.org/wp-content/uploads/2022/04/사본-Thumbnail-1.png',
    'desc': '안녕하세요! 법인설립지원센터입니다. 오늘은 개인사업자에서 법인사업자로 전환을 고려 중이신 분들을 위해 ‘법인 전환’에 대한 핵심 내용만을 준비했습니다. 1. 왜 법인으로 전환할까? 대부분 소규모로 사업을 시작하실 때는 사업자등록 및 세금신고 과정이 간단한 개인사업자로 시작하십니다. 그러나 수입이 늘게 되며 안정화 되면, 법인의 유리한 조건 때문에 법인 전환을 고려하게 되십니다. Read more…',
    'author': 'Posted By 법인설립지원센터',
    'date': '5월 13, 2022',
    'link': 'https://blog.k-startbiz.org/2022/05/13/%ea%b0%9c%ec%9d%b8%ec%82%ac%ec%97%85%ec%9e%90-%eb%b2%95%ec%9d%b8-%ec%a0%84%ed%99%98-%ec%8b%9c-%ec%9e%a5%ec%a0%90-%eb%b0%8f-%eb%b0%a9%eb%b2%95/'
  },
  {
    'img': 'https://blog.k-startbiz.org/wp-content/uploads/2022/04/사본-Thumbnail-2.png',
    'desc': '안녕하세요! 법인설립지원센터입니다. 1인 사업자로 고소득을 내시는 사업자가 늘어나며 소규모 법인 설립이 활발해지고 있습니다. 그래서 오늘은 많은 분들이 관심을 갖고 계신 ‘1인 법인설립’을 위해 알아두셔야 하는 모든 것을 알아보도록 하겠습니다. 1. 법인설립 요건 법인을 설립하기 위해서는 주주 1명과 (사내)이사 1명의 구성원이 있어야 합니다.  주주는 반드시 주식을 Read more…',
    'author': 'Posted By 법인설립지원센터',
    'date': '5월 4, 2022',
    'link': 'https://blog.k-startbiz.org/2022/05/04/1%ec%9d%b8-%eb%b2%95%ec%9d%b8%ec%84%a4%eb%a6%bd-%eb%ac%b4%eb%a3%8c%eb%a1%9c-%ea%b0%80%eb%8a%a5%ed%95%98%eb%8b%a4/'
  },
  {
    'img': 'https://blog.k-startbiz.org/wp-content/uploads/2022/04/사본-Thumbnail-3.png',
    'desc': '안녕하세요! 법인설립지원센터입니다. 법인설립을 준비하시기 전, 반드시 알아야만 하는 정보들을 정리했습니다. 1. 법인설립 과정 법인설립 전문가에게 의뢰를 하실 경우, 다음과 같은 과정으로 진행됩니다. 법인설립 과정 신청 → 수수료 결제 → 정보/서류 이메일로 전달→ 공과금 결제 → *(전자등기)전자서명/ * (서면등기)인감날인→ 세무서 방문하여 사업자등록 Read more…',
    'author': 'Posted By 법인설립지원센터',
    'date': '4월 29, 2022',
    'link': 'https://blog.k-startbiz.org/2022/04/29/%eb%b2%95%ec%9d%b8%ec%84%a4%eb%a6%bd-%ec%9d%b4%ea%b2%83%eb%a7%8c%ec%9d%80-%ea%bc%ad-%ec%95%8c%ec%95%84%eb%91%90%ec%9e%90/'
  },
  {
    'img': 'https://blog.k-startbiz.org/wp-content/uploads/2022/04/사본-Thumbnail-5.png',
    'desc': '안녕하세요! 법인설립지원센터입니다. 법인설립을 위해 꼭 알아두어야 할 ‘절차 및 서류’에 대한 핵심 내용을 준비했습니다. 1. 법인설립을 하면 이런 점이 좋아요 1) 세금 감소 개인사업자는 사업 수익을 종합소득세로 과세하여 세율이 최대 45%입니다.  한편, 법인사업자의 법인세는 세율이 최대 25%입니다. 따라서 수익에 따라 법인세 적용이 Read more…',
    'author': 'Posted By 법인설립지원센터',
    'date': '4월 13, 2022',
    'link': 'https://blog.k-startbiz.org/2022/04/13/%eb%b2%95%ec%9d%b8%ec%84%a4%eb%a6%bd-%ec%a0%88%ec%b0%a8-%eb%b0%8f-%ec%84%9c%eb%a5%98/'
  },
  {
    'img': 'https://blog.k-startbiz.org/wp-content/uploads/2022/06/사본-Thumbnail-6.png',
    'desc': '오늘은 부동산 법인설립을 고려하고 계시는 분들을 위해, 부동산 법인 v.s. 개인사업자를 비교해 보는 시간을 가져보겠습니다. 부동산 법인의 신규 설립 비율이 다음과 같이, 전국적으로 작년 3월부터 다시 증가 추세에 있습니다. “이처럼 부동산 법인설립이 증가하는 이유는 무엇일까요?” 1. 세금 부담 감소 부동산 법인을 설립하시는 이유로 ‘절세’를 Read more…',
    'author': 'Posted By 법인설립지원센터',
    'date': '6월 13, 2022',
    'link': 'https://blog.k-startbiz.org/2022/06/13/%eb%b6%80%eb%8f%99%ec%82%b0-%eb%b2%95%ec%9d%b8%ec%84%a4%eb%a6%bd%ea%b3%bc-%ea%b0%9c%ec%9d%b8%ec%82%ac%ec%97%85%ec%9e%90-%ec%a1%b0%ea%b1%b4-%eb%b9%84%ea%b5%90%ed%95%b4%eb%b3%b4%ea%b8%b0/'
  },
  {
    'img': 'https://blog.k-startbiz.org/wp-content/uploads/2022/04/사본-Thumbnail-1.png',
    'desc': '안녕하세요! 법인설립지원센터입니다. 오늘은 개인사업자에서 법인사업자로 전환을 고려 중이신 분들을 위해 ‘법인 전환’에 대한 핵심 내용만을 준비했습니다. 1. 왜 법인으로 전환할까? 대부분 소규모로 사업을 시작하실 때는 사업자등록 및 세금신고 과정이 간단한 개인사업자로 시작하십니다. 그러나 수입이 늘게 되며 안정화 되면, 법인의 유리한 조건 때문에 법인 전환을 고려하게 되십니다. Read more…',
    'author': 'Posted By 법인설립지원센터',
    'date': '5월 13, 2022',
    'link': 'https://blog.k-startbiz.org/2022/05/13/%ea%b0%9c%ec%9d%b8%ec%82%ac%ec%97%85%ec%9e%90-%eb%b2%95%ec%9d%b8-%ec%a0%84%ed%99%98-%ec%8b%9c-%ec%9e%a5%ec%a0%90-%eb%b0%8f-%eb%b0%a9%eb%b2%95/'
  },
  {
    'img': 'https://blog.k-startbiz.org/wp-content/uploads/2022/04/사본-Thumbnail-2.png',
    'desc': '안녕하세요! 법인설립지원센터입니다. 1인 사업자로 고소득을 내시는 사업자가 늘어나며 소규모 법인 설립이 활발해지고 있습니다. 그래서 오늘은 많은 분들이 관심을 갖고 계신 ‘1인 법인설립’을 위해 알아두셔야 하는 모든 것을 알아보도록 하겠습니다. 1. 법인설립 요건 법인을 설립하기 위해서는 주주 1명과 (사내)이사 1명의 구성원이 있어야 합니다.  주주는 반드시 주식을 Read more…',
    'author': 'Posted By 법인설립지원센터',
    'date': '5월 4, 2022',
    'link': 'https://blog.k-startbiz.org/2022/05/04/1%ec%9d%b8-%eb%b2%95%ec%9d%b8%ec%84%a4%eb%a6%bd-%eb%ac%b4%eb%a3%8c%eb%a1%9c-%ea%b0%80%eb%8a%a5%ed%95%98%eb%8b%a4/'
  },
  {
    'img': 'https://blog.k-startbiz.org/wp-content/uploads/2022/04/사본-Thumbnail-3.png',
    'desc': '안녕하세요! 법인설립지원센터입니다. 법인설립을 준비하시기 전, 반드시 알아야만 하는 정보들을 정리했습니다. 1. 법인설립 과정 법인설립 전문가에게 의뢰를 하실 경우, 다음과 같은 과정으로 진행됩니다. 법인설립 과정 신청 → 수수료 결제 → 정보/서류 이메일로 전달→ 공과금 결제 → *(전자등기)전자서명/ * (서면등기)인감날인→ 세무서 방문하여 사업자등록 Read more…',
    'author': 'Posted By 법인설립지원센터',
    'date': '4월 29, 2022',
    'link': 'https://blog.k-startbiz.org/2022/04/29/%eb%b2%95%ec%9d%b8%ec%84%a4%eb%a6%bd-%ec%9d%b4%ea%b2%83%eb%a7%8c%ec%9d%80-%ea%bc%ad-%ec%95%8c%ec%95%84%eb%91%90%ec%9e%90/'
  },
  {
    'img': 'https://blog.k-startbiz.org/wp-content/uploads/2022/04/사본-Thumbnail-5.png',
    'desc': '안녕하세요! 법인설립지원센터입니다. 법인설립을 위해 꼭 알아두어야 할 ‘절차 및 서류’에 대한 핵심 내용을 준비했습니다. 1. 법인설립을 하면 이런 점이 좋아요 1) 세금 감소 개인사업자는 사업 수익을 종합소득세로 과세하여 세율이 최대 45%입니다.  한편, 법인사업자의 법인세는 세율이 최대 25%입니다. 따라서 수익에 따라 법인세 적용이 Read more…',
    'author': 'Posted By 법인설립지원센터',
    'date': '4월 13, 2022',
    'link': 'https://blog.k-startbiz.org/2022/04/13/%eb%b2%95%ec%9d%b8%ec%84%a4%eb%a6%bd-%ec%a0%88%ec%b0%a8-%eb%b0%8f-%ec%84%9c%eb%a5%98/'
  }
];
