import React from 'react';

import Header from '../shared/Header';
import Main from './1-main/Main';
import Reason from './2-reason/Reason';
import MatchProcess from './3-match-process/MatchProcess';
import CaseGraph from './4-case/Case';
import Request from './5-request/Request';
import Footer from '../shared/Footer';

function Partnership() {
  return (
    <>
      <Header/>
      <div id={'main'}>
        <Main/>
      </div>
      <div id={'reason'}>
        <Reason/>
      </div>
      <div id={'match-process'}>
        <MatchProcess/>
      </div>
      <div id={'case-graph'}>
        <CaseGraph/>
      </div>
      <div id={'request'}>
        <Request/>
      </div>
      <Footer/>
    </>
  )
}

export default Partnership;
