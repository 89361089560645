import React, { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { css } from 'aphrodite';
import { useAtomValue, useSetAtom } from 'jotai';

import { CreateStyle } from '../../../shared/presentation/shared/CreateStyle';
import { totalScreenState } from '../../../shared/presentation/MobileFrame';
import { spoqa } from '../../../shared/css/css-utils';
import { requestPage, useMyHistory } from '../../../shared/presentation/HistoryHelper';
import { isLoadingState } from '../../../shared/presentation/LoadingFrame';
import { useInjection } from '../../../shared/presentation/InversifyProvider';
import { IRequestType, requestAtom, resetAtom } from '../../../shared/store/RequestAtom';
import { CustomRequestService } from '../../../shared/service/RequestService';
import { totalKeywordAtom } from '../../../shared/presentation/KeywordAtom';

function CheckProcess() {
  const { isMobile, isTablet } = useRecoilValue(totalScreenState);
  const styles = CheckProcessStyle(isMobile, isTablet);
  const history = useMyHistory();
  const request: IRequestType = useAtomValue<IRequestType>(requestAtom);
  const setIsLoading = useSetRecoilState(isLoadingState);
  const keyword = useAtomValue(totalKeywordAtom);
  // const resetRequest = useResetAtom(requestAtom);
  const resetRequest = useSetAtom(resetAtom);
  const requestService = useInjection(CustomRequestService);

  useEffect(() => {
    if (request.phone === '') {
      history.goBack();
    }
  }, [request.phone, history]);

  const sendRequest = async () => {
    let error = false;
    setIsLoading(true);
    try {
      await requestService?.sendFinalRequest(request, keyword);
      history.push(requestPage(2));
    } catch (e) {
      console.log(e);
      error = true;
      alert('간편신청에 실패했습니다.');
    } finally {
      setIsLoading(false);
      if (!error) {
        resetRequest();
      }
    }
  };

  return (
    <div className={css(styles.wrapper)}>
      <div className={css(styles.checkTitle)}>
        휴대폰 번호가 맞으시면 아래의 확인버튼을 눌러주세요.
      </div>
      <div className={css(styles.checkPhone)}>
        {request.phone}
      </div>
      <div className={css(styles.buttonsBox)}>
        <div
          className={css(styles.reInputNumberButton)}
          onClick={() => history.goBack()}
        >
          휴대폰 번호 재입력
        </div>
        <div
          className={css(styles.okayButton)}
          onClick={sendRequest}
        >
          확인
        </div>
      </div>
    </div>
  );
}

export default CheckProcess;

const CheckProcessStyle = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  wrapper: {
    padding: '24px 27px/74px 73px 54px 70px',

    fontFamily: spoqa,
    fontWeight: 300,
    color: '#454f5d',
  },
  checkTitle: {
    fontWeight_: `${300}/${400}`,
    fontSize: '14px/14px',
    lineHeight: '24px/25px',

    marginBottom: '4px/4px'
  },
  checkPhone: {
    height: '32px/60px',

    fontSize: '26px/30px',
    lineHeight: '32px/60px',
    letterSpacing: '/1px',
  },
  buttonsBox: {
    display_: '/flex',

    marginTop: '244px/371px',
  },
  reInputNumberButton: {
    width: '100%/192px',
    height: '56px/60px',
    border: '1px solid #454f5d',
    borderRadius: '5px/5px',

    fontWeight: 400,
    fontSize: '15px/16px',
    lineHeight: '19px/20px',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    marginRight: '/13px',
    marginBottom: '11px/',

    cursor: 'pointer',
  },
  okayButton: {
    width: '100%/192px',
    height: '56px/60px',
    background: 'black',
    borderRadius: '5px/5px',

    fontWeight: 400,
    fontSize: '15px/16px',
    lineHeight: '19px/20px',
    color: 'white',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    cursor: 'pointer',
  },
}, isMobile || isTablet, false);
