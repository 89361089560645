import React, { useCallback, useEffect, useRef } from 'react';
import * as _ from 'lodash';

export interface IScrollFadeIn {
  direction?: string,
  duration?: number,
  delay?: number,
  children?: React.ReactNode,
  className?: string,
  degree?: string
  id?: string
  cascadeDuration?: number,
  skips?: number[]
  log?: boolean
  timingFunc?: string,
  customHidden?: object,
  customShown?: object,
  isStartAni?: boolean,
  play?: () => void,
  stop?: () => void,
  rootMargin?: string,
}

const handleDirection = (name: string, degree: string) => {
  switch (name) {
    case 'up':
      return `translate3d(0, ${degree}, 0)`;
    case 'down':
      return `translate3d(0, -${degree}, 0)`;
    case 'left':
      return `translate3d(${degree}, 0, 0)`;
    case 'right':
      return `translate3d(-${degree}, 0, 0)`;
    case 'spread':
      return 'scale(0, 100%)';
    default:
      return '';
  }
};

const completeTransform = (name: string) => {
  switch (name) {
    case 'up':
    case 'down':
    case 'left':
    case 'right':
      return `translate3d(0, 0, 0)`;
    case 'spread':
      return 'scale(100%, 100%)';
    default:
      return '';
  }
};

export function FadeIn(options: IScrollFadeIn) {
  const dom = useRef<HTMLDivElement>(null);
  const {
    direction = 'up',
    delay = 0,
    duration = 1,
    degree = '200px',
  } = options;

  const handleScroll = useCallback(([entry]: IntersectionObserverEntry[]) => {
    const {current} = dom;
    const isDirectionDownOrStart = entry.boundingClientRect.y > (entry.rootBounds?.top ?? 0);
    const isAnimation = (entry.intersectionRatio < 0.6 && isDirectionDownOrStart) || options.isStartAni;

    if (entry.isIntersecting) {
      options.play?.();
      current!.style.transitionProperty = 'all';
      current!.style.transitionDuration = isAnimation ? `${duration}s` : '0s';
      current!.style.transitionTimingFunction = 'cubic-bezier(0, 0, 0.2, 1)';
      current!.style.transitionDelay = isAnimation ? `${delay}s` : '0s';
      current!.style.opacity = '1';
      if (options.customShown) {
        _.forEach(options.customShown, (value, key) => {
          current!.style.setProperty(key, value);
        });
        return;
      }
      current!.style.transform = completeTransform(direction);
      return;
    }

    if (isDirectionDownOrStart) {
      options.stop?.();
      current!.style.opacity = '0';
      current!.style.transition = '';
      if (options.customHidden) {
        _.forEach(options.customHidden, (value, key) => {
          current!.style.setProperty(key, value);
        });
        return;
      }
      current!.style.transform = handleDirection(direction, degree);
    }
  }, [degree, delay, direction, duration, options.customHidden, options.customShown, options.isStartAni, options.play, options.stop]);

  useEffect(() => {
    const windowBottom = window.pageYOffset;

    let observer: IntersectionObserver;
    const {current} = dom;

    if (current) {
      observer = new IntersectionObserver(handleScroll, {threshold: 0.0, rootMargin: '-50px 40px'});
      if (options.isStartAni) {
        setTimeout(() => observer.observe(current), 500);
      } else {
        observer.observe(current);
      }

      if (windowBottom > current.offsetTop) {
        current!.style.opacity = '1';
        current!.style.transition = '';
        current!.style.transform = completeTransform(direction);
      }
    }
    return () => observer && observer.disconnect();
  }, [handleScroll, direction, options.isStartAni]);

  return (
    <div
      ref={dom}
      style={{
        opacity: 0,
        transform: handleDirection(direction, degree),
        ...options.customHidden,
      }}
      className={options.className}
      id={options.id}
    >
      {options.children}
    </div>
  );
}

export function FadeInCascade(options: IScrollFadeIn) {
  const myDom = useRef<HTMLDivElement>(null);
  const domRefs = useRef<any[]>([]);
  const direction = options?.direction ?? 'up';
  const delay = options?.delay ?? 0;
  const duration = options?.duration ?? 1;
  const degree = options?.degree ?? '50%';
  const cascadeDuration = options?.cascadeDuration ?? 0.5;
  const timingFunc = options.timingFunc ?? 'cubic-bezier(0.0, 0.0, 0.2, 1)';
  const rootMargin = options.rootMargin ?? '-50px 40px';

  const handleScroll = useCallback(([entry]: IntersectionObserverEntry[]) => {
    const isDirectionDownOrStart = entry.boundingClientRect.y > (entry.rootBounds?.top ?? 0);
    const isAnimation = (entry.intersectionRatio < 0.6 && isDirectionDownOrStart) || options.isStartAni;
    let counting = 0;
    domRefs.current.forEach((cur, index) => {
      if (entry.isIntersecting) {
        options.play?.();
        if (cur!.show != null) {
          cur!.show?.();
          return;
        }
        if (cur!.style) {
          cur!.style.transitionProperty = 'all';
          cur!.style.transitionDuration = isAnimation ? `${duration}s` : '0s';
          cur!.style.transitionTimingFunction = timingFunc;
          cur!.style.transitionDelay = isAnimation ? `${delay + counting * cascadeDuration}s` : '0s';
          cur!.style.opacity = '1';
          cur!.style.transform = 'translate3d(0, 0, 0)';
          counting++;

          const timeout = (delay + index * cascadeDuration + duration) * 1000;
          setTimeout(() => cur!.style.transitionDelay = '0s', timeout);
        }
        return;
      }

      if (isDirectionDownOrStart) {
        options.stop?.();
        cur!.hide?.();
        if (cur!.style) {
          cur!.style.opacity = '0';
          cur!.style.transform = handleDirection(direction, degree);
          cur!.style.transition = '';
        }
      }
    });
  }, [cascadeDuration, degree, delay, direction, duration, options.isStartAni, options.play, options.stop, timingFunc]);

  useEffect(() => {
    const windowBottom = window.innerHeight + window.pageYOffset;
    let observer: IntersectionObserver;
    const {current} = myDom;

    if (current) {
      observer = new IntersectionObserver(handleScroll, {threshold: [0.0], rootMargin});
      if (options.isStartAni) {
        setTimeout(() => observer.observe(current), 500);
      } else {
        observer.observe(current);
      }

      if (windowBottom > current.offsetTop) {
        domRefs.current.forEach((cur, index) => {
          cur!.show?.();
          if (cur!.style) {
            cur!.style.opacity = '1';
            cur!.style.transform = completeTransform(direction);
            cur!.style.transition = '';
          }
        });
      }
    }
    return () => observer && observer.disconnect();
  }, [handleScroll, direction, options.isStartAni, rootMargin]);

  return (
    <div className={options.className} ref={myDom}>
      {React.Children.toArray(options.children).map((child: any, index) => {
        if (_.isString(child) || _.isNumber(child)) {
          return child;
        }

        if (_.includes(options.skips, index)) {
          return child;
        }

        if (_.isFunction(child.type)) {
          return React.cloneElement(child.type(child.props), {
            ref: (element: any) => {
              if (element != null && !_.includes(domRefs.current, element)) {
                domRefs.current.push(element);
              }
            },
            style: {
              opacity: 0,
              transform: handleDirection(direction, degree),
            },
            key: 'fadein-' + index,
          });
        }

        domRefs.current = [];
        return React.cloneElement(child, {
          ref: (element: any) => {
            if (element != null && !_.includes(domRefs.current, element)) {
              domRefs.current.push(element);
            }
          },
          style: {
            opacity: 0,
            transform: handleDirection(direction, degree),
          },
          key: 'fadein-' + index,
        });
      })}
    </div>
  );
}

export function TriggerAnimate(options: IScrollFadeIn) {
  const dom = useRef<HTMLDivElement>(null);
  const rootMargin = options.rootMargin ?? '25% 20%';

  const handleScroll = useCallback(([entry]: IntersectionObserverEntry[]) => {
    const isDirectionDownOrStart = entry.boundingClientRect.y > (entry.rootBounds?.top ?? 0);
    const isAnimation = (entry.intersectionRatio < 0.6 && isDirectionDownOrStart) || options.isStartAni;

    if (entry.isIntersecting && isAnimation && entry.intersectionRatio > 0.25) {
      options.play?.();
      return;
    }

    if (isDirectionDownOrStart && entry.intersectionRatio < 0.1) {
      options.stop?.();
    }
  }, [options.isStartAni, options.play, options.stop]);

  useEffect(() => {
    const windowBottom = window.pageYOffset;

    let observer: IntersectionObserver;
    let observerStop: IntersectionObserver;
    const {current} = dom;

    if (current) {
      observer = new IntersectionObserver(handleScroll, {threshold: 0.3, rootMargin});
      observerStop = new IntersectionObserver(handleScroll, {threshold: 0, rootMargin});
      if (options.isStartAni) {
        setTimeout(() => observer.observe(current), 500);
      } else {
        observer.observe(current);
      }
      observerStop.observe(current);

      if (windowBottom > current.offsetTop) {
        options.play?.();
      }
    }
    return () => {
      observer && observer.disconnect();
      observerStop && observerStop.disconnect();
    };
  }, [handleScroll, options.isStartAni, options.play, options.stop, rootMargin]);

  return (
    <div
      ref={dom}
      className={options.className}
      id={options.id}
    >
      {options.children}
    </div>
  );
}
