import React from 'react';
import { css } from 'aphrodite';
import { useRecoilValue } from 'recoil';

import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';
import { spoqa } from '../../../../shared/css/css-utils';
import { ReactComponent as FirstRow } from '../../../assets/svgs/first-row.svg';
import { ReactComponent as SecondRow } from '../../../assets/svgs/second-row.svg';
import { ReactComponent as ThirdRow } from '../../../assets/svgs/third-row.svg';

import { ReactComponent as FirstRowMobile } from '../../../assets/svgs/first-row-m.svg';
import { ReactComponent as SecondRowMobile } from '../../../assets/svgs/second-row-m.svg';
import { ReactComponent as ThirdRowMobile } from '../../../assets/svgs/third-row-m.svg';
import { ReactComponent as FourthRowMobile } from '../../../assets/svgs/fourth-row-m.svg';
// import { ReactComponent as FifthRowMobile } from '../../../assets/svgs/fifth-row-m.svg';
// import { ReactComponent as SixthRowMobile } from '../../../assets/svgs/sixth-row-m.svg';
import { isIE } from '../../../../shared/helper/Util';
import { NotDesktopBr } from '../../../../shared/presentation/NotDesktopBr';

const BizType = () => {
  const {isMobile, isTablet, isDesktop} = useRecoilValue(totalScreenState);
  const styles = BizTypeStyles(isMobile, isTablet);
  const animationClass = (index: number) => {
    if (isIE()) {
      return undefined;
    }
    return index % 2 === 0 ? styles.even_back_ani : styles.odd_back_ani;
  };

  return <div className={css(styles.container)}>
    <div className={css(styles.titleBox)}>
      <div className={css(styles.title)}>어떤 업종을 고민하고 계신가요?</div>
      <div className={css(styles.desc)}>프랜차이즈창업센터는 <span
        className={css(styles.bold500)}>업종별 빅데이터를 기반</span>으로<NotDesktopBr/> 최적의 창업을
        도와드립니다.
      </div>
    </div>
    <div className={css(styles.bizTypes)}>
      {
        isDesktop ?
          <>
            <div className={css(animationClass(1), styles.flexBox)}>
              <FirstRow className={css(styles.bizRow)}/>
              <FirstRow className={css(styles.bizRow)}/>
            </div>
            <div className={css(animationClass(2), styles.flexBox)}>
              <SecondRow className={css(styles.bizRow)}/>
              <SecondRow className={css(styles.bizRow)}/>
            </div>
            <div className={css(animationClass(3), styles.flexBox)}>
              <ThirdRow className={css(styles.bizRow)}/>
              <ThirdRow className={css(styles.bizRow)}/>
            </div>
          </>
          : <>
            <div className={css(animationClass(1), styles.flexBox)}>
              <FirstRowMobile className={css(styles.bizRow)}/>
              <FirstRowMobile className={css(styles.bizRow)}/>
            </div>
            <div className={css(animationClass(2), styles.flexBox)}>
              <SecondRowMobile className={css(styles.bizRow)}/>
              <SecondRowMobile className={css(styles.bizRow)}/>
            </div>
            <div className={css(animationClass(3), styles.flexBox)}>
              <ThirdRowMobile className={css(styles.bizRow)}/>
              <ThirdRowMobile className={css(styles.bizRow)}/>
            </div>
            <div className={css(animationClass(4), styles.flexBox)}>
              <FourthRowMobile className={css(styles.bizRow)}/>
              <FourthRowMobile className={css(styles.bizRow)}/>
            </div>
            {/*<div className={css(animationClass(5), styles.flexBox)}>*/}
            {/*  <FifthRowMobile className={css(styles.bizRow)}/>*/}
            {/*  <FifthRowMobile className={css(styles.bizRow)}/>*/}
            {/*</div>*/}
            {/*<div className={css(animationClass(6), styles.flexBox)}>*/}
            {/*  <SixthRowMobile className={css(styles.bizRow)}/>*/}
            {/*  <SixthRowMobile className={css(styles.bizRow)}/>*/}
            {/*</div>*/}
          </>
      }
    </div>
  </div>;
};

export default BizType;

const rowAni = {
  '0%': {
    transform: 'translateX(0)',
  },
  '100%': {
    transform: 'translateX(-50%)',
  }
};

const BizTypeStyles = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  container: {
    height: '451px/664px',
    backgroundColor: '#F9FAFB',
    fontFamily: spoqa,
    color: '#454F5D',

    padding: '47px 0 30px/129px 0 121px',

    position: 'relative',
    overflow: 'hidden',
  },
  titleBox: {
    textAlign: 'center',
    marginBottom: '/26px',
    height: '94px/',
  },
  title: {
    fontWeight: 300,
    fontSize: '24px/40px',
    lineHeight: '34px/60px',
    letterSpacing: '-1px',
    marginBottom: '12px/13px',
  },
  desc: {
    fontSize: '14px/22px',
    lineHeight: '24px/32px',
    fontWeight: 300,
    letterSpacing: '-0.3px',
  },
  bold500: {
    fontWeight: 500,
  },
  bizTypes: {
    position: 'absolute',
    left_: '/-165px',
    display: 'flex',
    flexDirection: 'column',

    height: '219px/283px',
    marginTop: '28px/',
    padding: '12px 0/21px 0 20px',
  },
  bizRow: {
    height: '32px/45px',
    marginTop: '14px/28px',
    marginLeft: '-10px/',

    ':nth-child(2)': {
      marginLeft: '/15px',
    },
  },
  bizType: {
    width: 'max-content',
    height: '/46px',
    padding: '/3px 12px',
    backgroundColor: '#FFF',

    margin: '/0 12px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '/4px',
  },
  odd_back_ani: {
    animationName: [rowAni],
    animationDuration: '50s',
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite',
  },
  even_back_ani: {
    animationName: [rowAni],
    animationDuration: '60s',
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite',
    animationDirection: 'reverse',
  },
  flexBox: {
    display: 'flex',
  }
}, isMobile || isTablet, false);
