import React from 'react';
import { useRecoilValue } from 'recoil';
import { css } from 'aphrodite';

import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';
import { robotoRegular, spoqa } from '../../../../shared/css/css-utils';
import { Image } from '../../../../shared/css/Image';
import { Assets } from '../../shared/Assets';

const STEP_LIST = (isDesktop: boolean) => [
  {
    stepImage: Assets.ProcessStep1,
    contentText: isDesktop ? '간편신청\n(전화, 홈페이지)' : '간편신청',
    stepText: 'STEP 1',
  },
  {
    stepImage: Assets.ProcessStep2,
    contentText: isDesktop ? '가맹거래사 1:1 미팅 및\n 고객 요구사항 분석' : '가맹거래사 1:1 미팅',
    stepText: 'STEP 2',
  },
  {
    stepImage: Assets.ProcessStep3,
    contentText: isDesktop ? '브랜드 분석 의견서\n제공 및 컨설팅' : '브랜드 컨설팅',
    stepText: 'STEP 3',
  },
  {
    stepImage: Assets.ProcessStep4,
    contentText: '가맹계약',
    stepText: 'STEP 4',
  },
  {
    stepImage: Assets.ProcessStep5,
    contentText: '세이브택스 세무사 상담',
    stepText: 'STEP 5',
    isLast: true,
  },
];

function Process() {
  const {isMobile, isTablet, isDesktop} = useRecoilValue(totalScreenState);
  const styles = ProcessStyle(isMobile, isTablet);

  return (
    <div className={css(styles.wrapper)}>
      {isDesktop ?
        <>
          <div className={css(styles.titleText)}>
            정확하고 안전한, 프랜차이즈&nbsp;
            <span style={{fontWeight: 700}}>가맹 프로세스</span>
          </div>
          <div className={css(styles.processArea)}>
            {STEP_LIST(isDesktop).map((item, index) => (
              <div className={css(styles.flexBox)} key={index}>
                <div className={css(styles.stepBox)}>
                  <Image
                    src={item.stepImage}
                    alt={''}
                    className={css(styles.stepImage)}
                  />
                  <div className={css(styles.contentText)}>{item.contentText}</div>
                  <div className={css(styles.stepText)}>{item.stepText}</div>
                </div>
                {!item.isLast && <div className={css(styles.stepLine)}/>}
              </div>
            ))}
          </div>
        </>
        :
        <>
          <div className={css(styles.titleText)}>
            정확하고 안전한, <br/>
            프랜차이즈&nbsp;
            <span style={{fontWeight: 500}}>가맹 프로세스</span>
          </div>
          <div className={css(styles.processArea)}>
            {STEP_LIST(isDesktop).slice(0, 3).map(
              (item, mapIndex) => (
                <div className={css(styles.flexBox)} key={mapIndex}>
                  <div className={css(styles.stepBox)}>
                    <Image
                      src={item.stepImage}
                      alt={''}
                      className={css(styles.stepImage)}
                    />
                    <div className={css(styles.contentText)}>{item.contentText}</div>
                    <div className={css(styles.stepText)}>{item.stepText}</div>
                  </div>
                </div>
              )
            )}
            <div className={css(styles.stepLineMobileFirst)}/>
          </div>
          <div className={css(styles.processArea)}>
            {STEP_LIST(isDesktop).slice(3).map(
              (item, mapIndex) => (
                <div className={css(styles.flexBox, styles.mobileSecondLine)} key={mapIndex}>
                  <div className={css(styles.stepBox)}>
                    <Image
                      src={item.stepImage}
                      alt={''}
                      className={css(styles.stepImage)}
                    />
                    <div className={css(styles.contentText)}>{item.contentText}</div>
                    <div className={css(styles.stepText)}>{item.stepText}</div>
                  </div>
                </div>
              )
            )}
            <div className={css(styles.stepLineMobileSecond)}/>
          </div>
        </>
      }
    </div>
  );
}

export default Process;

const ProcessStyle = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  wrapper: {
    width: '100%',
    height: '500px/635px',

    backgroundColor: '#FFF',

    fontFamily: spoqa,
    fontWeight: 300,

    paddingTop: '41px/113px',
  },
  titleText: {
    fontSize: '24px/40px',
    lineHeight: '36px/60px',
    letterSpacing: '-1px/-1px',
    textAlign: 'center',
    color: '#454F5D',

    marginBottom: '34px/74px',
  },

  processArea: {
    display: 'flex',
    justifyContent: 'center',

    position: 'relative',
  },
  flexBox: {
    display: 'flex',
    justifyContent: 'center',

    position: 'relative',

    width: '120px/',
  },
  mobileSecondLine: {
    width: '140px/',
  },
  stepBox: {
    display_: 'flex/',
    flexDirection_: 'column/',
    alignItems_: 'center/'
  },
  stepImage: {
    width: '75px/150px',
    height: '75px/150px',

    borderRadius: '37px/75px',

    marginBottom: '8px/20px',

    zIndex: 5,
  },
  contentText: {
    fontWeight: 300,
    fontSize: '14px/18px',
    lineHeight: '22px/30px',
    color: '#454F5D',
    whiteSpace: 'pre',
    textAlign: 'center',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    height: '/60px'
  },
  stepText: {
    fontFamily: robotoRegular,
    fontSize: '12px/15px',
    lineHeight: '14px/18px',
    letterSpacing: '1px/2px',
    textAlign: 'center',
    color: '#5E8EC0/#9CBEE1',

    marginTop: '7px/12px',
    marginBottom: '23px/',
  },
  stepLine: {
    borderTop: '1px solid #E5E9EA',
    width: '/142px',
    marginTop: '/75px',
  },
  stepLineMobileFirst: {
    borderTop: '1px solid #454F5D',
    opacity: 0.1,
    width: '80%/',

    position: 'absolute',
    top: '38px/',
    left: '20%',

    paddingLeft: '100px/',
  },
  stepLineMobileSecond: {
    borderTop: '1px solid #454F5D',
    opacity: 0.1,
    width: '60%/',

    position: 'absolute',
    top: '38px/',
    right: '40%',
  }
}, isMobile || isTablet, false);
