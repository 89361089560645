import React from 'react';
import { useRecoilValue } from 'recoil';
import { css } from 'aphrodite';

import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';
import { Assets } from '../../shared/Assets';
import { Image } from '../../../../shared/css/Image';
import { spoqa } from '../../../../shared/css/css-utils';

function Case() {
  const { isMobile, isTablet, isDesktop } = useRecoilValue(totalScreenState);
  const styles = CaseStyle(isMobile, isTablet);

  return (
    <div className={css(styles.wrapper)}>
      {isDesktop ?
        <Image
          src={Assets.Partnership.PartnerCaseBackDesktop}
          alt={''}
          className={css(styles.backgroundImage)}
        />
        :
        <Image
          src={Assets.Partnership.PartnerCaseBackMobile}
          alt={''}
          className={css(styles.backgroundImage)}
        />
      }
      <div className={css(styles.titleText)}>
        A 가맹본부 사례(2020년)
      </div>
      <div className={css(styles.subTitleText)}>
        2020년 제휴 이후 가맹점 모집 수 200% 증가
      </div>
      <Image
        src={Assets.Partnership.PartnerGraph}
        alt={''}
        className={css(styles.graphImage)}
      />
    </div>
  );
}

export default Case;

const CaseStyle = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  wrapper: {
    width: '100%',
    height: '569px/710px',

    position: 'relative',

    fontFamily: spoqa,
    fontWeight: 300,
    color: 'white',

    paddingTop: '50px/100px',
    paddingLeft: '/275px',

    display_: 'flex/',
    flexDirection_: 'column/',
    alignItems_: 'center/',
  },
  backgroundImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',

    zIndex: -5,
  },
  titleText: {
    fontSize: '24px/40px',
    lineHeight: '34px/60px',
    letterSpacing: '-1px/-1px',

    marginBottom: '8px/4px',
  },
  subTitleText: {
    fontSize: '14px/22px',
    lineHeight: '24px/32px',

    marginBottom: '40px/51px',
  },
  graphImage: {
    width: '281px/470px',
    height: '213px/355px',
  },
}, isMobile || isTablet, false);
