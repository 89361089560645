import React from 'react';
import { useRecoilValue } from 'recoil';
import { css } from 'aphrodite';

import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';
import { spoqa } from '../../../../shared/css/css-utils';
import { Image } from '../../../../shared/css/Image';
import { Assets } from '../../shared/Assets';

const REASON_LIST = [
  {
    title: '맞춤형 창업',
    descriptionDesktop: '고객선호도, 예산, 지역 등에\n' +
      '따른 \'맞춤 창업\'으로\n' +
      '계약률이 높습니다.',
    descriptionMobile: '고객선호도, 예산, 지역 등에 따른\n' +
      '\'맞춤 창업\'으로 계약률이 높습니다.',
    reasonImageDesktop: Assets.Partnership.PartnerReason01Desktop,
    reasonImageMobile: Assets.Partnership.PartnerReason01Mobile
  },
  {
    title: '지속적 매칭',
    descriptionDesktop: '매월 수많은 예비창업자의\n' +
      '문의가 들어오는 만큼\n' +
      '꾸준한 매칭이 가능합니다.',
    descriptionMobile: '매월 수많은 예비창업자의 문의가 \n' +
      '들어오는 만큼 꾸준한 매칭이 가능합니다.',
    reasonImageDesktop: Assets.Partnership.PartnerReason02Desktop,
    reasonImageMobile: Assets.Partnership.PartnerReason02Mobile
  },
  {
    title: '높은 신뢰성',
    descriptionDesktop: '자체 검증시스템으로\n' +
      '고객에게 높은 신뢰성을\n' +
      '보장하고 있습니다.',
    descriptionMobile: ' 자체 검증시스템으로 고객에게\n' +
      '높은 신뢰성을 보장하고 있습니다.',
    reasonImageDesktop: Assets.Partnership.PartnerReason03Desktop,
    reasonImageMobile: Assets.Partnership.PartnerReason03Mobile
  },
]

function Reason() {
  const { isMobile, isTablet, isDesktop } = useRecoilValue(totalScreenState);
  const styles = ReasonStyle(isMobile, isTablet);

  return (
    <div className={css(styles.wrapper)}>
      <div className={css(styles.titleText)}>
        왜 <span style={{fontWeight: 500}}>프랜차이즈창업센터인가요?</span>
      </div>
      <div className={css(styles.reasonsBox)}>
        {REASON_LIST.map((item, index) => (
          <div className={css(styles.reasonBox)} key={index}>
            {isDesktop ?
              <Image
                src={item.reasonImageDesktop}
                alt={''}
                className={css(styles.reasonImage)}
              />
              :
              <Image
                src={item.reasonImageMobile}
                alt={''}
                className={css(styles.reasonImage)}
              />
            }
            <div className={css(styles.reasonTitle)}>
              {item.title}
            </div>
            <div className={css(styles.reasonDesc)}>
              {isDesktop ? item.descriptionDesktop : item.descriptionMobile}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Reason;

const ReasonStyle = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  wrapper: {
    width: '100%',
    height: '881px/820px',
    background: 'white',

    paddingTop: '41px/120px',

    fontFamily: spoqa,
    fontWeight: 300,

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  titleText: {
    fontSize: '24px/40px',
    lineHeight: '34px/60px',
    letterSpacing: '-1px/-1px',

    marginBottom: '8px/40px',
  },
  reasonsBox: {
    display_: '/flex',
    justifyContent: 'center'
  },
  reasonBox: {
    width: '370px/393px',
    height: '240px/500px',
    borderRadius: '10px/10px',

    position: 'relative',

    margin: '12px 0/0 15px',
  },
  reasonTitle: {
    position: 'absolute',
    top: '72px/153px',
    left: 0,
    width: '100%',

    fontWeight_: `${400}/${500}`,
    fontSize: '26px/28px',
    lineHeight: '40px/40px',
    color: 'white',
    textAlign: 'center',
  },
  reasonDesc: {
    position: 'absolute',
    bottom: '60px/187px',
    left: 0,
    width: '100%',

    fontSize: '16px/22px',
    lineHeight: '26px/32px',
    color: 'white',
    textAlign: 'center',
    whiteSpace: 'pre',
  },
  reasonImage: {
    width: '100%',
    height: '100%',

    position: 'absolute',
    left: 0,
    top: 0,
  },
}, isMobile || isTablet, false);
