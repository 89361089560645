import React from 'react';
import { useRecoilValue } from 'recoil';
import { css } from 'aphrodite';

import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';
import { spoqa } from '../../../../shared/css/css-utils';
import { Assets } from '../../shared/Assets';
import { Image } from '../../../../shared/css/Image';
import KAISTLogo from '../../../assets/pngs/kaist-logo.png';
import { NotDesktopBr } from '../../../../shared/presentation/NotDesktopBr';

const DIFFERENCES = [
  {
    number: '01',
    diffImageDesktop: Assets.DiffImage01Desktop,
    diffImageMobile: Assets.DiffImage01Mobile,
    desktopText: '국가공인 창업전문가\n가맹거래사의\n무료 컨설팅',
    mobileText: '국가공인 창업전문가\n가맹거래사의 무료 컨설팅',
    blurColor: '#000',
  },
  {
    number: '02',
    diffImageDesktop: Assets.DiffImage02Desktop,
    diffImageMobile: Assets.DiffImage02Mobile,
    desktopText: '빅데이터 기반\n객관적 브랜드\n비교분석',
    mobileText: '빅데이터 기반 객관적\n브랜드 비교분석',
    blurColor: '#1F242A',
  },
  {
    number: '03',
    diffImageDesktop: Assets.DiffImage03Desktop,
    diffImageMobile: Assets.DiffImage03Mobile,
    desktopText: '안정적 수입을 위한\n최고의 조건 창업',
    mobileText: '안정적 수입을 위한\n최고의 조건 창업',
    blurColor: '#000',
  },
];

function Difference() {
  const {isMobile, isTablet, isDesktop} = useRecoilValue(totalScreenState);
  const styles = DifferenceStyle(isMobile, isTablet);

  return (
    <div className={css(styles.wrapper)}>
      <div className={css(styles.titleText)}>
        가맹거래사와 <NotDesktopBr/>
        <span className={css(styles.titleBold)}>카이스트 연구팀</span>이 함께합니다.
      </div>
      <div className={css(styles.subText)}>
        프랜차이즈창업센터는 수많은 고객을 통해&nbsp;{!isDesktop && <br/>}
        <span className={css(styles.titleBold500)}>검증된 창업 전문가 그룹입니다.</span>
      </div>
      <div className={css(styles.differenceBox)}>
        {DIFFERENCES.map((item, index) => (
          <div key={index} className={css(styles.differenceCard)}>
            {isDesktop ?
              <Image
                src={item.diffImageDesktop}
                alt={''}
                className={css(styles.diffImage)}
              />
              :
              <Image
                src={item.diffImageMobile}
                alt={''}
                className={css(styles.diffImage)}
              />
            }
            <div className={css(styles.textBox)}>
              <div
                className={css(
                  styles.numberText,
                  index === 1 && styles.secondCard,
                  index === 2 && styles.thirdCard
                )}
              >
                {item.number}
              </div>
              <div>
                {isDesktop ? item.desktopText : item.mobileText}
              </div>
              {index === 0 &&
              <Image
                src={Assets.GongJeongWee}
                alt={''}
                className={css(styles.gongJeongWee)}
              />
              }
              {
                index === 1 &&
                <div className={css(styles.logoBox)}>
                  <Image src={KAISTLogo} alt={'kaist-logo'} className={css(styles.kaistLogo)}/>
                </div>
              }
            </div>
          </div>
        ))}
      </div>
      <div className={css(styles.descriptionBox)}>
        <span className={css(styles.titleBold500)}>가맹거래사란?<br/></span>
        공정거래위원회 주관 프랜차이즈와 창업에 관한 유일한<NotDesktopBr/> 국가자격사로서 프랜차이즈 전문가(Franchise Expert)입니다.
      </div>
    </div>
  );
}

export default Difference;

const DifferenceStyle = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  wrapper: {
    width: '100%',
    height: '1073px/1030px',
    paddingTop: '40px/130px',

    textAlign: 'center',

    fontFamily: spoqa,
    fontWeight: 300,
    color: '#454F5D',
  },
  titleText: {
    fontSize: '24px/40px',
    lineHeight: '36px/60px',
    letterSpacing: '-1px/-1px',

    marginBottom: '8px/12px',
  },
  titleBold: {
    fontWeight: 700,
  },
  titleBold500: {
    fontWeight: 500,
  },
  subText: {
    fontWeight_: `${300}`,
    fontSize: '14px/22px',
    lineHeight: '24px/32px',

    marginBottom: '14px/60px',
  },
  differenceBox: {
    display: 'flex',
    flexDirection_: 'column/',
    justifyContent: 'center',
  },
  differenceCard: {
    width: '370px/393px',
    height: '240px/500px',

    margin: '6px 22px/0 15px',

    position: 'relative',
  },
  diffImage: {
    width: '100%',
    height: '100%',

    borderRadius: '10px/15px',

    position: 'absolute',
    top: 0,
    left: 0,

    zIndex: -5,
  },
  textBox: {
    color: 'white',
    fontFamily: spoqa,
    fontWeight_: `${300}/${400}`,
    fontSize: '26px/28px',
    lineHeight: '40px/45px',
    whiteSpace: 'pre',

    display_: '/flex',
    flexDirection_: '/column',
    alignItems: 'center',
  },
  numberText: {
    marginTop: '23px/89px',
    marginBottom: '8px/38px',

    fontWeight_: `${500}/${500}`,
  },
  secondCard: {
    marginTop: '34px/89px',
  },
  thirdCard: {
    marginTop: '39px/89px',
  },
  gongJeongWee: {
    width: '160px/200px',
    marginRight: '10px/20px',

    marginTop: '13px/66px',
  },
  logoBox: {
    width: '/150px',
    marginTop: '/70px',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection_: 'row/column',

    position: 'relative',
  },
  kaistLogo: {
    width: '83px/100%',
    marginTop: '25px/',
  },
  descriptionBox: {
    marginTop: '30px/65px',
    fontSize: '13px/22px',
    lineHeight: '22px/32px',
    letterSpacing: '-0.3px/',
  }
}, isMobile || isTablet, false);
