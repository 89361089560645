import React from 'react';
import { useRecoilValue } from 'recoil';
import { css } from 'aphrodite';

import { totalScreenState } from '../../../../shared/presentation/MobileFrame';
import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { spoqa } from '../../../../shared/css/css-utils';
import { NotDesktopBr } from '../../../../shared/presentation/NotDesktopBr';

const REASON_LIST_DESKTOP = [
  {
    item: '창업 컨설팅',
    existing: '객관적인 비교가 어려운 본사 직원의 컨설팅',
    ours: '가맹거래사의 100% 객관적인 비교 컨설팅',
  },
  {
    item: '성공 가능성',
    existing: '창업 지식 부족으로 불확실한 성공 가능성',
    ours: '상권 분석 등 가맹거래사의 1:1 밀착 서포트',
  },
  {
    item: '정보 정확성',
    existing: '과장된 매출 등 검증하기 어려운 정보 ',
    ours: 'AI 검증 시스템으로 허위·과장 정보 검증',
  },
  {
    item: '창업 예산',
    existing: '최초 예산 계획보다 초과된 창업 다수',
    ours: '본사와의 협상력을 활용한 최고의 창업 조건',
  },
  {
    item: '법적 이슈 대응',
    existing: '본사와의 정보 비대칭으로 다양한 법적 이슈 발생',
    ours: '프랜차이즈창업센터 가맹거래사의 법적 문제 중재',
  },
];

const REASON_LIST_MOBILE = [
  {
    item: '창업 컨설팅',
    existing: '본사 직원의\n' +
      '컨설팅',
    ours: '가맹거래사의\n' +
      '객관적 비교 컨설팅',
  },
  {
    item: '성공 가능성',
    existing: '불확실한\n' +
      '성공 가능성',
    ours: '상권 분석 등\n' +
      '가맹거래사 1:1 서포트',
  },
  {
    item: '정보 정확성',
    existing: '검증하기\n' +
      '어려운 정보',
    ours: '허위·과장 정보 검증',
  },
  {
    item: '창업 예산',
    existing: '예산 초과 창업\n' +
      '다수',
    ours: '최고의 창업 조건을\n' +
      '위한 본사 협상'
  },
  {
    item: '법적 이슈 대응',
    existing: '다양한 법적 이슈',
    ours: '가맹거래사의\n' +
      '법적 문제 중재',
  },
];

function Reason() {
  const {isMobile, isTablet, isDesktop} = useRecoilValue(totalScreenState);
  const styles = ReasonStyle(isMobile, isTablet);

  return (
    <div className={css(styles.wrapper)}>
      <div className={css(styles.titleText)}>
        왜 프랜차이즈창업센터를 {!isDesktop && <br/>}이용할까요?
      </div>
      <div className={css(styles.subText)}>
        <span className={css(styles.coloredText)}>더 낮은 비용, 더 좋은 조건</span>으로 창업할 수 있습니다.
      </div>
      <div className={css(styles.listBox)}>
        <div className={css(styles.header)}>
          <div className={css(styles.headerItem)}>항목</div>
          <div className={css(styles.headerExisting)}>기존 가맹점 창업<NotDesktopBr/></div>
          <div className={css(styles.headerOurs)}>프랜차이즈창업센터<NotDesktopBr/></div>
        </div>
        {isDesktop ?
          REASON_LIST_DESKTOP.map((reason, index) => (
            <div key={index}>
              <div className={css(styles.row)}>
                <div className={css(styles.rowItem)}>{reason.item}</div>
                <div className={css(styles.rowExisting)}>{reason.existing}</div>
                <div className={css(styles.rowOurs)}>{reason.ours}</div>
              </div>
              {index !== (REASON_LIST_DESKTOP.length - 1) &&
              <div className={css(styles.rowLine)}/>
              }
            </div>
          ))
          :
          REASON_LIST_MOBILE.map((reason, index) => (
            <div key={index}>
              <div className={css(styles.row)}>
                <div className={css(styles.rowItem)}>{reason.item}</div>
                <div className={css(styles.rowExisting)}>{reason.existing}</div>
                <div className={css(styles.rowOurs)}>{reason.ours}</div>
              </div>
              {index !== (REASON_LIST_MOBILE.length - 1) &&
              <div className={css(styles.rowLine)}/>
              }
            </div>
          ))
        }
      </div>
    </div>
  );
}

export default Reason;

const ReasonStyle = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  wrapper: {
    width: '100%',
    height: '630px/820px',

    backgroundColor: '#1f242a',

    fontFamily: spoqa,
    fontWeight: 300,
    color: 'white',
    textAlign: 'center',

    paddingTop: '41px/130px',

    display_: 'flex/',
    flexDirection_: 'column/',
    alignItems_: 'center/'
  },
  titleText: {
    fontWeight_: `${300}`,
    fontSize: '24px/40px',
    lineHeight: '34px/60px',
    letterSpacing: '-1px/-1px',

    marginBottom: '16px/8px',
  },
  subText: {
    fontWeight_: `${300}`,
    fontSize: '14px/22px',
    lineHeight: '24px/32px',

    marginBottom: '32px/57px',
  },
  coloredText: {
    color: '#9CBEE1',
  },
  listBox: {
    textAlign: 'left',

    paddingLeft: '/272px',

    width: '370px/',
  },

  header: {
    display: 'flex',

    color: '#9CBEE1',

    marginBottom: '3px/10px',
  },
  headerItem: {
    width: '107px/282px',

    fontWeight_: `${300}/${500}`,
    fontSize: '15px/18px',
    lineHeight: '24px/30px',
  },
  headerExisting: {
    width: '124px/525px',

    fontWeight_: `${300}/${500}`,
    fontSize: '15px/18px',
    lineHeight: '24px/30px',
  },
  headerOurs: {
    fontWeight_: `${300}/${500}`,
    fontSize: '15px/18px',
    lineHeight: '24px/30px',
    whiteSpace_: 'pre/',
  },
  row: {
    display: 'flex',
    alignItems_: '/center',

    height: '70px/74px',

    paddingTop: '10px/',
    whiteSpace_: 'pre/',
  },
  rowItem: {
    width: '107px/282px',

    fontWeight_: `${300}`,
    fontSize: '15px/18px',
    lineHeight: '24px/30px',
    color: 'white',
  },
  rowExisting: {
    width: '124px/525px',

    fontWeight_: `${300}`,
    fontSize: '15px/23px',
    lineHeight: '24px/34px',
    color: '#F9FAFB',
    opacity: 0.5,
  },
  rowOurs: {
    fontWeight_: `${300}`,
    fontSize: '15px/23px',
    lineHeight: '24px/34px',
    color: 'white',
  },
  rowLine: {
    borderBottom: '1px solid #E7E7E7',
    opacity: 0.25,
    width: '/1376px',
  }
}, isMobile || isTablet, false);
