import React from 'react';
import { css } from 'aphrodite';
import { useRecoilValue } from 'recoil';

import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';
import { Image } from '../../../../shared/css/Image';
import { Assets } from '../../shared/Assets';
import { spoqa } from '../../../../shared/css/css-utils';
import { requestPage, useMyHistory } from '../../../../shared/presentation/HistoryHelper';

function FreeService() {
  const {isMobile, isTablet, isDesktop} = useRecoilValue(totalScreenState);
  const styles = FreeServiceStyle(isMobile, isTablet);
  const history = useMyHistory();

  return (
    <div className={css(styles.wrapper)}>
      {isDesktop ?
        <Image
          className={css(styles.backgroundImage)}
          src={Assets.FreeServiceBackDesktop}
          alt="배경"
        />
        :
        <>
          <div className={css(styles.backgroundDarker)}/>
          <Image
            className={css(styles.backgroundImage)}
            src={Assets.FreeServiceBackMobile}
            alt="배경"
          />
        </>
      }
      <div className={css(styles.firstFont)}>
        {isDesktop ?
          <>
            프랜차이즈창업센터는&nbsp;
            <span style={{fontWeight: 700}}>100% 무료 서비스</span>입니다
          </>
          :
          <>
            프랜차이즈창업센터는<br/>
            <span style={{fontWeight: 500}}>100% 무료 서비스</span>입니다
          </>
        }
      </div>
      <div className={css(styles.secondFont)}>
        {isDesktop ?
          <>
            예비창업자가 프랜차이즈창업센터에 지불하시는 매칭수수료는 전혀 없습니다.<br/>
            비용은 무료이지만 원하는 프랜차이즈를 찾을 때까지 프리미엄 서비스를 약속 드립니다.
          </>
          :
          <>
            비용은 무료이지만 원하는 프랜차이즈를 찾을 때까지 <br/>
            프리미엄 서비스를 약속 드립니다.
          </>
        }
      </div>
      <div
        className={css(styles.requestButton)}
        onClick={() => history.push(requestPage())}
      >
        프랜차이즈 창업 무료신청
      </div>
    </div>
  );
}

export default FreeService;

const FreeServiceStyle = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  wrapper: {
    width: '100%',
    height: '357px/570px',

    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',

    position: 'relative',

    fontFamily: spoqa,
    fontWeight: 300,
    color: 'white',

    paddingTop: '69px/172px',
  },
  backgroundImage: {
    width: '100%',
    height: '100%/570px',

    position: 'absolute',
    top: 0,
    left: 0,

    zIndex: -5,
  },
  backgroundDarker: {
    width: '100%',
    height: '100%',

    position: 'absolute',
    top: 0,
    left: 0,

    zIndex: -4,

    backgroundColor: 'black',
    opacity: 0.6
  },
  firstFont: {
    fontSize: '24px/50px',
    lineHeight: '36px/55px',

    marginBottom: '9px/20px',

    textAlign_: 'center/',
  },
  secondFont: {
    fontSize: '14px/22px',
    lineHeight: '24px/36px',

    textAlign: 'center',

    marginBottom: '32px/36px',
  },
  requestButton: {
    width: '320px/319px',
    height: '54px/60px',
    border: '1px solid white',
    borderRadius: '5px/5px',
    backgroundColor: 'rgba(255, 255, 255, 0.15)/',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    fontWeight_: `${500}`,
    fontSize: '15px/17px',
    lineHeight: '19px/21px',

    cursor: 'pointer',

    ':hover': {
      backgroundColor: 'white',
      color: '#454F5D',
      transition: '0.3s',
    }
  },
}, isMobile || isTablet, false);
