import React, { RefObject } from 'react';
import { useRecoilValue } from 'recoil';
import { css } from 'aphrodite';
import { Link } from 'react-router-dom';
import { useAtom, useAtomValue } from 'jotai';

import { totalScreenState } from '../../../shared/presentation/MobileFrame';
import { CreateStyle } from '../../../shared/presentation/shared/CreateStyle';
import { spoqa } from '../../../shared/css/css-utils';
import { ReactComponent as PolicyChecker } from '../../assets/svgs/policy-checker.svg';
import { isEdgeLegacy, isIE } from '../../../shared/helper/Util';
import { requestPage, useMyHistory } from '../../../shared/presentation/HistoryHelper';
import { useInjection } from '../../../shared/presentation/InversifyProvider';
import { checkerAtom, RequestChecker, requestPartialAtom } from '../../../shared/store/RequestAtom';
import { requestErrorAtom } from '../../../shared/store/RequestErrorAtom';
import { CustomRequestService } from '../../../shared/service/RequestService';
import { totalKeywordAtom } from '../../../shared/presentation/KeywordAtom';

interface Props {
  reference?: RefObject<HTMLDivElement>,
}

function DefaultInputForm({ reference }: Props) {
  const { isMobile, isTablet } = useRecoilValue(totalScreenState);
  const styles = DefaultInputFormStyle(isMobile, isTablet);
  const [request, setRequest] = useAtom(requestPartialAtom);
  const err = useAtomValue<RequestChecker>(checkerAtom);
  const [error, setError] = useAtom(requestErrorAtom);
  const keyword = useAtomValue(totalKeywordAtom);
  const requestService = useInjection(CustomRequestService);
  const history = useMyHistory();

  const togglePolicy = () => setRequest({
    ...request,
    isPrivacyPolicyChecked: !request.isPrivacyPolicyChecked,
  });

  const moveToCheckPage = () => {
    if (reference?.current !== null &&
      !isIE() &&
      !isEdgeLegacy()) {
      reference?.current?.scrollTo(0, 0);
    }
    if (!request.isPrivacyPolicyChecked) {
      alert('개인정보취급방침에 동의해주세요.');
      return;
    }
    if (!err.isValid) {
      setError(err);
      return;
    }

    requestService?.sendRequest(request, keyword);
    history.push(requestPage(1));
  };

  const onChange = (
    params: {
      name?: string,
      phone?: string,
      question?: string,
    },
    errorParams: {
      isNameError?: boolean,
      isPhoneEmpty?: boolean,
      isPhoneError?: boolean,
      isQuestionError?: boolean,
    }
  ) => {
    setRequest({ ...request, ...params });
    setError({ ...error, ...errorParams });
  };

  return (
    <div className={css(styles.wrapper)}>
      <div className={css(styles.inputBox)}>
        <input
          className={css(styles.input)}
          placeholder={'이름(상호)'}
          onChange={e => onChange(
            { name: e.target.value },
            { isNameError: false },
          )}
          value={request.name}
        />
        {error.isNameError &&
          <div className={css(styles.error)}>이름(상호)를 입력해주세요.</div>
        }
      </div>
      <div className={css(styles.inputBox)}>
        <input
          className={css(styles.input)}
          placeholder={'휴대폰 번호'}
          onChange={e => onChange(
            { phone: e.target.value },
            { isPhoneEmpty: false, isPhoneError: false }
          )}
          value={request.phone}
        />
        {error.isPhoneError ?
          <div className={css(styles.error)}>정확한 번호를 입력해주세요.</div>
          :
          error.isPhoneEmpty &&
          <div className={css(styles.error)}>휴대폰 번호를 입력해주세요.</div>
        }
      </div>
      <div className={css(styles.textAreaBox)}>
        <textarea
          className={css(styles.textArea)}
          placeholder={'문의사항'}
          onChange={e => onChange(
            { question: e.target.value },
            { isQuestionError: false }
          )}
          value={request.question}
        />
        {error.isQuestionError &&
          <div className={css(styles.error)}>문의사항을 입력해주세요.</div>
        }
      </div>
      <div className={css(styles.privacyPolicyBox)}>
        <div
          className={css(styles.checkBox)}
          onClick={togglePolicy}
        >
          {request.isPrivacyPolicyChecked &&
            <PolicyChecker className={css(styles.policyChecker)}/>
          }
        </div>
        <Link
          className={css(styles.policyContentLink)}
          to={'/privacy_policy'}
          target={'_blank'}
        >
          개인정보취급방침
        </Link>
        <div
          className={css(styles.policyText)}
          onClick={togglePolicy}
        >
          에 동의합니다.
        </div>
      </div>
      <div
        className={css(styles.requestButton)}
        onClick={moveToCheckPage}
      >
        신청하기
      </div>
    </div>
  );
}

export default DefaultInputForm;

const DefaultInputFormStyle = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  wrapper: {
    padding: '24px 27px/64px 77px 54px 70px',

    fontFamily: spoqa,
    fontWeight: 400,
  },
  inputBox: {
    width: '100%',
    height: '52px/60px',

    marginBottom: '12px/20px',

    display: 'flex',
    position: 'relative',
  },
  input: {
    width: '100%',
    height: '100%',
    border: '1px solid #E5E9EA',
    borderRadius: '5px/5px',

    fontFamily: spoqa,
    fontWeight: 400,
    fontSize: '14px/14px',
    lineHeight: '20px/25px',
    color: '#1F242A',

    '::placeholder': {
      color: '#C9CFD7',
    },

    ':-ms-input-placeholder': {
      color: '#C9CFD7',
    },

    paddingLeft: '19px/26px',
  },
  error: {
    position: 'absolute',
    top: 0,
    right: '19px/26px',

    width: 'max-content',
    height: '52px/60px',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',

    fontFamily: spoqa,
    fontWeight: 400,
    fontSize: '14px/14px',
    lineHeight: '20px/25px',
    color: '#ff0000',

    cursor: 'default',
  },
  textAreaBox: {
    width: '100%',
    height: '160px/242px',

    marginBottom: '20px/20px',
    position: 'relative',
  },
  textArea: {
    width: '100%',
    height: '100%',
    border: '1px solid #E5E9EA',
    borderRadius: '5px/5px',

    fontFamily: spoqa,
    fontWeight: 400,
    fontSize: '14px/14px',
    lineHeight: '20px/25px',
    color: '#1F242A',

    '::placeholder': {
      color: '#C9CFD7',
    },

    ':-ms-input-placeholder': {
      color: '#C9CFD7',
    },

    paddingLeft: '19px/26px',
    paddingTop: '18px/18px',
    resize: 'none',
  },
  privacyPolicyBox: {
    display: 'flex',
    alignItems: 'center',

    fontFamily: spoqa,
    fontWeight_: `${400}/${300}`,
    fontSize: '15px/14px',
    lineHeight: '15px/25px',
    color: '#454F5D',

    marginBottom: '32px/23px',
  },
  checkBox: {
    width: '27px/20px',
    height: '27px/20px',
    border: 'solid 1px #E5E9EA/solid 1px rgba(0, 0, 0, 0.22)',
    borderRadius: '5px/5px',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    marginRight: '11px/6px',

    cursor: 'pointer',
  },
  policyChecker: {
    width: '18px/14px',
    height: '14px/10px',

    fill: 'black',
  },
  policyContentLink: {
    borderBottom: '1px solid #454f5d',
    paddingTop: '3px/1px',

    cursor: 'pointer',

    height: '23px/',
  },
  policyText: {
    borderBottom: '1px solid #ffffff',
    paddingTop: '3px/1px',

    height: '23px/',
  },
  requestButton: {
    width: '100%/393px',
    height: '56px/60px',
    borderRadius: '5px/5px',
    background: 'black',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    fontFamily: spoqa,
    fontWeight_: `${400}`,
    fontSize: '15px/16px',
    lineHeight: '19px/20px',
    color: 'white',

    cursor: 'pointer',

    marginBottom: '50px/',
  },
}, isMobile || isTablet, false);
