import React from 'react';
import { useRecoilValue } from 'recoil';
import { css } from 'aphrodite';

import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';
import { Image } from '../../../../shared/css/Image';
import { Assets } from '../../shared/Assets';
import { spoqa } from '../../../../shared/css/css-utils';
import { scrollWindow } from '../../../../shared/helper/Util';
import { DatePolicy } from '../../../../shared/domain/DatePolicy';

function Main() {
  const {isMobile, isTablet, isDesktop} = useRecoilValue(totalScreenState);
  const styles = MainStyle(isMobile, isTablet);
  const datePolicy = new DatePolicy();

  return (
    <div className={css(styles.wrapper)}>
      {isDesktop ?
        <Image
          src={Assets.Partnership.PartnerMainBackDesktop}
          alt={''}
          className={css(styles.backgroundImage)}
        />
        :
        <Image
          src={Assets.Partnership.PartnerMainBackMobile}
          alt={''}
          className={css(styles.backgroundImage)}
        />
      }
      <div className={css(styles.titleText)}>
        매월 수백명의 예비사장님들이<br/>
        가맹본사를 찾고 있습니다
      </div>
      <div className={css(styles.subTitleText)}>
        {isDesktop ?
          <>
            예비사장님들의 요구사항에 맞추어 파트너 프랜차이즈 본사로 연결을 해드리고 있습니다.
          </>
          :
          <>
            예비사장님들의 요구사항에 맞추어<br/>
            파트너 프랜차이즈 본사로 연결을 해드리고 있습니다.
          </>
        }
      </div>
      <div
        className={css(styles.requestButton)}
        onClick={() => scrollWindow('request')}
      >
        프랜차이즈 파트너십 신청
      </div>
      {isDesktop &&
      <div className={css(styles.bottomText)}>
        {/*추가모집기한 : 2021년 00월 00일까지*/}
        추가모집기한 : {datePolicy.getPartnershipRequestExpired(new Date())}
      </div>
      }
    </div>
  );
}

export default Main;

const MainStyle = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  wrapper: {
    width: '100%',
    height: '372px/704px',

    paddingTop: '112px/202px',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    position: 'relative',

    fontFamily: spoqa,
    fontWeight: 300,
    color: 'white',
  },
  backgroundImage: {
    width: '100%',
    height: '100%',

    position: 'absolute',
    left: 0,
    top: 0,

    zIndex: -10,
  },
  titleText: {
    fontSize: '24px/50px',
    lineHeight: '34px/70px',
    textAlign: 'center',

    marginBottom: '8px/20px',
  },
  subTitleText: {
    fontSize: '14px/22px',
    lineHeight: '24px/32px',
    textAlign_: 'center/',

    marginBottom: '28px/50px',
  },
  requestButton: {
    width: '320px/319px',
    height: '54px/60px',
    border: '1px solid #FFFFFF',
    borderRadius: '5px/5px',
    backgroundColor: 'rgba(255, 255, 255, 0.15)/',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    fontWeight_: `${500}`,
    fontSize: '15px/17px',
    lineHeight: '19px/21px',
    color: 'white',

    cursor: 'pointer',

    ':hover': {
      backgroundColor: 'white',
      color: '#454F5D',
      transition: '0.3s',
    },

    marginBottom: '/44px',
  },
  bottomText: {
    fontWeight: 400,
    fontSize: '/17px',
    lineHeight: '/21px'
  }
}, isMobile || isTablet, false);
