import React from 'react';
import { useRecoilValue } from 'recoil';
import { css } from 'aphrodite';
import { totalScreenState } from '../../../shared/presentation/MobileFrame';
import { CreateStyle } from '../../../shared/presentation/shared/CreateStyle';
import { Image } from '../../../shared/css/Image';
import { Assets } from '../shared/Assets';
import { spoqa } from '../../../shared/css/css-utils';

function RequestBanner() {
  const {isMobile, isTablet, isDesktop} = useRecoilValue(totalScreenState);
  const styles = BannerBoxStyle(isMobile, isTablet);

  return (
    <div className={css(styles.wrapper)}>
      {isDesktop ?
        <Image
          src={Assets.ReqBannerBackDesktop}
          alt={''}
          className={css(styles.bannerBackImage)}
        />
        :
        <Image
          src={Assets.ReqBannerBackMobile}
          alt={''}
          className={css(styles.bannerBackImage)}
        />
      }
      <div className={css(styles.bannerTitle)}>
        {isDesktop ?
          <>
            프랜차이즈창업<br/>
            간편신청하기
          </>
          :
          <>
            프랜차이즈창업 간편신청
          </>
        }
      </div>
      <div className={css(styles.bannerSubTitle)}>
        문의 남겨주시면 빠른 연락드리겠습니다.
      </div>
      <div className={css(styles.bannerBottomText)}>
        상담신청을 위하여 남겨주신 개인정보는 개인정보취급방침에<br/>
        의거하여 100% 안전하게 보호됩니다.
      </div>
    </div>
  );
}

export default RequestBanner;

const BannerBoxStyle = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  wrapper: {
    width: '100%/560px',
    height: '240px/648px',

    fontFamily: spoqa,
    fontWeight: 300,
    color: 'white',
    textAlign_: 'center/',

    position: 'relative',
    paddingTop: '77px/'
  },
  bannerBackImage: {
    width: '100%',
    height: '100%',

    position: 'absolute',
    top: 0,
    left: 0,

    zIndex: -10,
  },
  bannerTitle: {
    fontSize: '28px/40px',
    lineHeight: '32px/52px',
    letterSpacing: '/-1px',

    marginBottom: '4px/16px',

    position_: '/absolute',
    top: '/64px',
    left: '/70px',

  },
  bannerSubTitle: {
    fontSize: '14px/18px',
    lineHeight: '22px/31px',

    position_: '/absolute',
    top: '/184px',
    left: '/70px',

    marginBottom: '24px/'
  },
  bannerBottomText: {
    fontSize: '12px/14px',
    lineHeight: '20px/26px',

    position_: '/absolute',
    left: '/70px',
    bottom: '/59px',
  },
}, isMobile || isTablet, false);
