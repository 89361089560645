import React from 'react';
import { useRecoilValue } from 'recoil';
import { css } from 'aphrodite';
import { Link } from 'react-router-dom';

import { totalScreenState } from '../../../shared/presentation/MobileFrame';
import { CreateStyle } from '../../../shared/presentation/shared/CreateStyle';
import { ReactComponent as HeaderLogo } from '../../assets/svgs/franchise-logo.svg';
import FairTradeAttorneyLogo from '../../assets/pngs/fair-trade-attorney-logo.png';
import FairTradeAttorneyLogoBLACK from '../../assets/pngs/fair-trade-attorney-logo-black.png';
import { ReactComponent as HeaderCall } from '../../assets/svgs/call-icon.svg';
import { ReactComponent as HeaderKakao } from '../../assets/svgs/kakao-logo.svg';
import {
  isPartnershipPage,
  kakaoPage,
  menuPage,
  requestPage,
  useMyHistory
} from '../../../shared/presentation/HistoryHelper';
import { moveToHome, openKakaoMobilePage, scrollWindow } from '../../../shared/helper/Util';
import { ReactComponent as MenuIcon } from '../../../shared/presentation/icons/menu.svg';
import { spoqa } from '../../../shared/css/css-utils';
import { useInjection } from '../../../shared/presentation/InversifyProvider';
import { LOCATION_SERVICE, LocationService } from '../../../shared/store/LocationStore';
import { Image } from '../../../shared/css/Image';

function Header() {
  const {isMobile, isTablet, isDesktop, isTop} = useRecoilValue(totalScreenState);

  const styles = HeaderStyle(isMobile, isTablet, isTop);
  const history = useMyHistory();
  const locationService = useInjection<LocationService>(LOCATION_SERVICE);

  const BUTTON_LIST = (style: any) => [
    {
      buttonTitle: '블로그',
      onClick: () => window.open('https://blog.k-franchise.org')
    },
    {
      buttonTitle: '프랜차이즈 본사제휴 문의',
      onClick: () => window.open('/partnership')
    },
    {
      buttonIcon: <HeaderCall className={css(style)}/>,
      buttonTitle: `고객센터 ${process.env.REACT_APP_SERVICE_CENTER_NUMBER}`,
      onClick: () => history.push(requestPage()),
    },
    {
      buttonIcon: <HeaderKakao className={css(style)}/>,
      buttonTitle: '카카오톡 상담',
      onClick: () => history.push(kakaoPage()),
    }
  ];

  return (
    <div className={css(styles.wrapper)}>
      <div
        className={css(styles.logoBox)}
      >
        <HeaderLogo className={css(styles.franchiseLogo)} onClick={() => moveToHome(history)}/>
        {
          isDesktop && (
            <Image src={isTop ? FairTradeAttorneyLogo : FairTradeAttorneyLogoBLACK} alt={'fairTradeLogo'}
                   className={css(styles.fairTradeAttorneyLogo)}/>)
        }
      </div>
      <div className={css(styles.rightSide)}>
        {isDesktop ?
          isPartnershipPage(history) ?
            <div
              className={css(styles.buttonBox)}
              onClick={() => scrollWindow('request')}
            >
              <HeaderCall className={css(styles.buttonIcon)}/>
              고객센터 {process.env.REACT_APP_SERVICE_CENTER_NUMBER}
            </div>
            :
            <>
              {BUTTON_LIST(styles.buttonIcon).map(
                (item, index) => (
                  <div
                    className={css(styles.buttonBox)}
                    key={index}
                    onClick={item.onClick}
                  >
                    {item.buttonIcon}
                    {item.buttonTitle}
                  </div>
                )
              )}
            </>
          :
          <>
            {isTop &&
            <div
              className={css(styles.buttonBox)}
              onClick={() => openKakaoMobilePage()}
            >
              <HeaderKakao className={css(styles.buttonIcon)}/>
            </div>
            }
            <div
              className={css(styles.buttonBox)}
              onClick={() => locationService?.callToService()}
            >
              <HeaderCall className={css(styles.buttonIcon)}/>
            </div>
            <Link
              className={css(styles.buttonBox)}
              to={menuPage}
            >
              <MenuIcon className={css(styles.buttonIcon)}/>
            </Link>
          </>
        }
      </div>
    </div>
  );
}

export default Header;

const HeaderStyle = (isMobile: boolean, isTablet: boolean, isTop: boolean) => CreateStyle({
  wrapper: {
    width: '100%',
    height: '64px/90px',

    position: 'fixed',
    top: 0,
    left: 0,

    borderBottom: `/solid 1px ${isTop ? 'rgba(255, 255, 255, 0.25)' : 'rgba(69, 79, 93, 0.25)'}`,
    backgroundColor: isTop ? 'rgba(255, 255, 255, 0)' : 'rgba(255, 255, 255, 0.9)',
    backdropFilter: isTop ? undefined : 'blur(15px)',

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    zIndex: 10,

    transition: '0.3s',
  },
  logoBox: {
    margin: '19px 28px/28px 102px',

    position: 'relative',
    top: '1px',
  },
  franchiseLogo: {
    width: '/253px',
    height: '26px/40px',

    fill: isTop ? 'white' : '#454F5D',

    marginRight: '/36px',
    cursor: 'pointer',
  },
  fairTradeAttorneyLogo: {
    width: '/270px',
    height: '/40px',
  },
  rightSide: {
    display: 'flex',

    marginRight: '12px/',
  },
  buttonBox: {
    width: '/274px',
    height: '64px/90px',
    padding: '0 12px/',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    fontFamily: spoqa,
    fontWeight: 400,
    fontSize: '/19px',
    lineHeight: '/23px',
    color: isTop ? 'white' : '#454F5D',
    borderLeft: `/solid 1px ${isTop ? 'rgba(255, 255, 255, 0.25)' : 'rgba(69, 79, 93, 0.25)'}`,

    cursor: 'pointer',

    '-webkit-tap-highlight-color': 'transparent',
  },
  buttonIcon: {
    width: '24px/26px',
    height: '24px/26px',

    marginRight: '/8px',

    fill: isTop ? 'white' : '#454F5D',
  }
}, isMobile || isTablet, false);
