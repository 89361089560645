import React from 'react';
import { useRecoilValue } from 'recoil';
import { css } from 'aphrodite';

import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';
import { robotoRegular, spoqa } from '../../../../shared/css/css-utils';
import { Assets } from '../../shared/Assets';
import { Image } from '../../../../shared/css/Image';

function Review() {
  const {isMobile, isTablet, isDesktop} = useRecoilValue(totalScreenState);
  const styles = ReviewStyle(isMobile, isTablet);

  return (
    <div className={css(styles.wrapper)}>
      {isDesktop ?
        <Image
          src={Assets.ReviewBackDesktop}
          alt={''}
          className={css(styles.reviewBackground)}
        />
        :
        <>
          <Image
            src={Assets.ReviewBackMobile}
            alt={''}
            className={css(styles.reviewBackground)}
          />
          <div className={css(styles.gradientBack)}/>
        </>
      }
      <div className={css(styles.topText)}>REVIEW</div>
      <div className={css(styles.reviewComment)}>
        막막한 상황에 너무나 큰 도움 되었습니다.<br/>
        덕분에 창업에 성공할 수 있었습니다.<br/>
        ★ ★ ★ ★ ★
        {isDesktop &&
        <Image
          src={Assets.ReviewQuotes}
          alt={''}
          className={css(styles.quotes)}
        />
        }
      </div>
      <div className={css(styles.reviewer)}>이종원 사장님</div>
    </div>
  );
}

export default Review;

const ReviewStyle = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  wrapper: {
    width: '100%',
    height: '455px/760px',

    fontFamily: spoqa,
    fontWeight: 300,
    color: 'white',

    paddingTop: '/208px',
    paddingLeft: '/301px',

    position: 'relative',
  },
  reviewBackground: {
    width: '100%',
    height: '/100%',

    position: 'absolute',
    top: 0,
    left: 0,

    zIndex: -10,
  },
  gradientBack: {
    width: '100%',
    height: '311px/',

    position: 'absolute',
    bottom: 0,
    left: 0,

    zIndex: -5,

    background: 'linear-gradient(180deg, rgba(213, 213, 213, 0) 29.58%, #C4C2B9 57.18%)',
  },
  topText: {
    fontFamily: robotoRegular,
    fontWeight: 400,
    fontSize: '12px/20px',
    lineHeight: '14px/23px',
    letterSpacing: '2px/2px',

    marginBottom: '/81px',

    position_: 'absolute/',
    top: '39px/',
    left: '23px/'
  },
  reviewComment: {
    fontSize: '18px/24px',
    lineHeight: '29px/40px',

    marginBottom: '/40px',

    position_: 'absolute/relative',
    bottom: '72px/',
    left: '23px/'
  },
  reviewer: {
    fontWeight_: `${500}/${400}`,
    fontSize: '14px/17px',
    lineHeight: '24px/17px',

    position_: 'absolute/',
    bottom: '40px/',
    left: '23px/',
  },
  quotes: {
    width: '/26px',
    height: '/22px',

    position: 'absolute',
    top: '/-46px',
    left: 0,
  }
}, isMobile || isTablet, false);
