import CustomBackDesktop from '../../assets/jpgs/custom-back-desktop.jpg';
import CustomBackMobile from '../../assets/jpgs/custom-back-mobile.jpg';
import AdvantageIcon01 from '../../assets/svgs/advantage-icon1.svg';
import AdvantageIcon02 from '../../assets/svgs/advantage-icon2.svg';
import AdvantageIcon03 from '../../assets/svgs/advantage-icon3.svg';
import AdvantageIcon04 from '../../assets/svgs/advantage-icon4.svg';
import AdvantageIcon05 from '../../assets/svgs/advantage-icon5.svg';
import AdvantageIcon06 from '../../assets/svgs/advantage-icon6.svg';
import AwardSatisfaction from '../../assets/pngs/award-satisfaction.png';
import AwardService from '../../assets/pngs/award-service.png';
import DiffImage01Desktop from '../../assets/jpgs/difference1-desktop.jpg';
import DiffImage02Desktop from '../../assets/jpgs/difference2-desktop.jpg';
import DiffImage03Desktop from '../../assets/jpgs/difference3-desktop.jpg';
import DiffImage01Mobile from '../../assets/jpgs/difference1-mobile.jpg';
import DiffImage02Mobile from '../../assets/jpgs/difference2-mobile.jpg';
import DiffImage03Mobile from '../../assets/jpgs/difference3-mobile.jpg';
import GongJeongWee from '../../assets/svgs/gong-jeong-wee.svg';
import DamagedImage01 from '../../assets/jpgs/damaged1.jpg';
import FoundingImage01Desktop from '../../assets/pngs/founding1-desktop.png';
import FoundingImage01Mobile from '../../assets/jpgs/founding1-mobile.jpg';
import Underline from '../../assets/svgs/period-underline.svg';
import Service01Desktop from '../../assets/jpgs/service1-desktop.jpg';
import Service02Desktop from '../../assets/jpgs/service2-desktop.jpg';
import Service03Desktop from '../../assets/jpgs/service3-desktop.jpg';
import Service04Desktop from '../../assets/jpgs/service4-desktop.jpg';
import Service01Mobile from '../../assets/jpgs/service1-mobile.jpg';
import Service02Mobile from '../../assets/jpgs/service2-mobile.jpg';
import Service03Mobile from '../../assets/jpgs/service3-mobile.jpg';
import Service04Mobile from '../../assets/jpgs/service4-mobile.jpg';
import ServiceArrowDesktop from '../../assets/svgs/service-arrow-desktop.svg';
import ServiceArrowMobile from '../../assets/svgs/service-arrow-mobile.svg';
import FreeServiceBackDesktop from '../../assets/jpgs/free-service-back-desktop.jpg';
import FreeServiceBackMobile from '../../assets/jpgs/free-service-back-mobile.jpg';
import PreFounderDesktop from '../../assets/jpgs/pre-founder-desktop.jpg';
import PreFounderMobile from '../../assets/jpgs/pre-founder-mobile.jpg';
import HeadquartersDesktop from '../../assets/jpgs/headquarters-desktop.jpg';
import HeadquartersMobile from '../../assets/jpgs/headquarters-mobile.jpg';
import SideArrowDesktop from '../../assets/svgs/side-arrow-desktop.svg';
import SideArrowMobile from '../../assets/svgs/side-arrow-mobile.svg';
import StepCircle from '../../assets/svgs/step-circle.svg';
import ArrowCircle from '../../assets/svgs/arrow-circle.svg';
import CommIphone01 from '../../assets/pngs/communication-iphone1.png';
import CommIphone02 from '../../assets/pngs/communication-iphone2.png';
import CommArrowLeft from '../../assets/svgs/comm-arrow-left.svg';
import CommArrowRight from '../../assets/svgs/comm-arrow-right.svg';
import ProcessStep1 from '../../assets/jpgs/process-step1.jpg';
import ProcessStep2 from '../../assets/jpgs/process-step2.jpg';
import ProcessStep3 from '../../assets/jpgs/process-step3.jpg';
import ProcessStep4 from '../../assets/jpgs/process-step4.jpg';
import ProcessStep5 from '../../assets/jpgs/process-step5.jpg';
import ReviewQuotes from '../../assets/svgs/review-quotes.svg';
import ReviewBackDesktop from '../../assets/jpgs/review-back-desktop.jpg';
import ReviewBackMobile from '../../assets/jpgs/review-back-mobile.jpg';
import FooterKakao from '../../assets/svgs/footer-kakao.svg';
import FranchiseLogo from '../../assets/svgs/franchise-logo.svg';
import CallIcon from '../../assets/svgs/call-icon.svg';
import KakaoLogo from '../../assets/svgs/kakao-logo.svg';
import MainBackVideo from '../../assets/main-back-video.mp4';
import MainBackMobile from '../../assets/jpgs/main-back-mobile.jpg';
import MainMobileArrow from '../../assets/svgs/main-mobile-arrow.svg';
import ReqBannerBackDesktop from '../../assets/jpgs/req-banner-back-desktop.jpg';
import ReqBannerBackMobile from '../../assets/jpgs/req-banner-back-mobile.jpg';
import RecordBackMobile from '../../assets/pngs/record-back-mobile.png';
import DamagedCaseArrowLeft from '../../assets/svgs/damaged-case-arrow-left.svg';
import DamagedCaseArrowRight from '../../assets/svgs/damaged-case-arrow-right.svg';

import PartnerMainBackDesktop from '../../assets/partnership/main-back-desktop.jpg';
import PartnerMainBackMobile from '../../assets/partnership/main-back-mobile.jpg';
import PartnerReason01Desktop from '../../assets/partnership/reason1-desktop.jpg';
import PartnerReason02Desktop from '../../assets/partnership/reason2-desktop.jpg';
import PartnerReason03Desktop from '../../assets/partnership/reason3-desktop.jpg';
import PartnerReason01Mobile from '../../assets/partnership/reason1-mobile.jpg';
import PartnerReason02Mobile from '../../assets/partnership/reason2-mobile.jpg';
import PartnerReason03Mobile from '../../assets/partnership/reason3-mobile.jpg';
import PartnerStepCircle from '../../assets/partnership/step-circle.svg';
import PartnerCaseBackDesktop from '../../assets/partnership/case-back-desktop.jpg';
import PartnerCaseBackMobile from '../../assets/partnership/case-back-mobile.jpg';
import PartnerGraph from '../../assets/partnership/graph.png';

export const Assets = {
  AwardSatisfaction,
  AwardService,
  CustomBackDesktop,
  CustomBackMobile,
  AdvantageIcon01,
  AdvantageIcon02,
  AdvantageIcon03,
  AdvantageIcon04,
  AdvantageIcon05,
  AdvantageIcon06,
  DiffImage01Desktop,
  DiffImage02Desktop,
  DiffImage03Desktop,
  DiffImage01Mobile,
  DiffImage02Mobile,
  DiffImage03Mobile,
  GongJeongWee,
  DamagedImage01,
  FoundingImage01Desktop,
  FoundingImage01Mobile,
  Underline,
  PreFounderDesktop,
  PreFounderMobile,
  HeadquartersDesktop,
  HeadquartersMobile,
  SideArrowDesktop,
  SideArrowMobile,
  ServiceArrowDesktop,
  ServiceArrowMobile,
  FreeServiceBackDesktop,
  FreeServiceBackMobile,
  Service01Desktop,
  Service02Desktop,
  Service03Desktop,
  Service04Desktop,
  Service01Mobile,
  Service02Mobile,
  Service03Mobile,
  Service04Mobile,
  StepCircle,
  ArrowCircle,
  CommIphone01,
  CommIphone02,
  CommArrowLeft,
  CommArrowRight,
  ProcessStep1,
  ProcessStep2,
  ProcessStep3,
  ProcessStep4,
  ProcessStep5,
  ReviewQuotes,
  ReviewBackDesktop,
  ReviewBackMobile,
  FooterKakao,
  FranchiseLogo,
  CallIcon,
  KakaoLogo,
  MainBackVideo,
  MainBackMobile,
  MainMobileArrow,
  ReqBannerBackDesktop,
  ReqBannerBackMobile,
  RecordBackMobile,
  DamagedCaseArrowLeft,
  DamagedCaseArrowRight,

  Partnership: {
    PartnerMainBackDesktop,
    PartnerMainBackMobile,
    PartnerReason01Desktop,
    PartnerReason02Desktop,
    PartnerReason03Desktop,
    PartnerReason01Mobile,
    PartnerReason02Mobile,
    PartnerReason03Mobile,
    PartnerStepCircle,
    PartnerCaseBackDesktop,
    PartnerCaseBackMobile,
    PartnerGraph,
  },
};
