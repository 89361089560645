import React from 'react';
import { css } from 'aphrodite';
import { useRecoilValue } from 'recoil';

import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';
import { spoqa } from '../../../../shared/css/css-utils';
import { Image } from '../../../../shared/css/Image';

import PressImage01 from '../../../assets/pngs/press-img1.png';
import PressImage02 from '../../../assets/pngs/press-img2.png';
import PressImgM1 from '../../../assets/pngs/press-img-m1.png';
import PressImgM2 from '../../../assets/pngs/press-img-m2.png';
import PressLogo01 from '../../../assets/svgs/press-logo1.svg';
import PressLogo02 from '../../../assets/svgs/press-logo2.svg';

const PRESS_LIST = [
  {
    img: PressImage01,
    imgM: PressImgM1,
    title: '프랜차이즈창업센터, 예비창업자를 돕는 ‘무료 창업상담 서비스’ 운영',
    description: '프랜차이즈 전문 컨설팅 회사 프랜차이즈창업센터가 예비창업자를 대상으로 ‘무료 창업상담 서비스’를 제공하고 있다고 밝혔다. 프랜차이즈창업센터는 카이스트 출신 빅데이터 연구팀, 가맹거래사, 공인회계사, 세무사 등 전문가를 보유하고 있어 예비창업자에게 경영, 세무, 법률 등 체계화된 원스톱 창업상담이 가능하다. ',
    url: 'https://www.fnnews.com/news/202112141029092148',
    logo: PressLogo01,
    sliceTitleLength: 34,
    sliceContentLength: 52,
  },
  {
    img: PressImage02,
    imgM: PressImgM2,
    title: '프랜차이즈창업센터 IT 기반 무료 창업상담 서비스 제공',
    description: '"가맹점 창업상담은 공정거래위원회에 등록된 가맹거래사가 직접 진행하고 있으며 카이스트 출신 연구팀이 예비창업자가 접근하기 어려운 인터넷상의 방대한 자료를 IT 기술을 기반으로 데이터 가공·이용하기 편리한 형태로 제공한다"고 설명했다.',
    url: 'http://www.joseilbo.com/news/htmls/2021/12/20211215441174.html',
    logo: PressLogo02,
    sliceTitleLength: 0,
    sliceContentLength: 49,
  }
];

const sliceContents = (str: string, sliceLength: number): string => (str.length > sliceLength) && sliceLength !== 0 ? str.substring(0, sliceLength) + '…' : str;

const Press = () => {
  const {isMobile, isTablet, isDesktop} = useRecoilValue(totalScreenState);
  const styles = pressStyles(isMobile, isTablet);
  return (
    <div className={css(styles.container)}>
      <div className={css(styles.title)}>PRESS</div>
      {
        PRESS_LIST.map((item, index) =>
          (
            <div key={'press-item-' + index}>
              <div className={css(styles.articleBox)}>
                <Image src={isDesktop ? item.img : item.imgM} alt={'press' + index} className={css(styles.img)}/>
                <div className={css(styles.contents)}>
                  <div className={css(styles.articleTitle)}>
                    {isDesktop ? item.title : sliceContents(item.title, item.sliceTitleLength)}
                  </div>
                  <div className={css(styles.description)}>
                    {isDesktop ? item.description : sliceContents(item.description, item.sliceContentLength)}
                  </div>
                  <div
                    className={css(styles.articleButton)}
                    onClick={() => window.open(item.url)}
                  >기사 더 읽기 {`>`}</div>
                </div>
                {
                  isDesktop && (
                    <div className={css(styles.logo)}>
                      <Image src={item.logo} alt={'press' + index} className={css(styles.logo)}/>
                    </div>
                  )
                }
              </div>
              <hr className={css(styles.division, index === 1 && styles.invisible)}/>
            </div>
          ))
      }
    </div>
  );
};

export default Press;

const pressStyles = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  container: {
    width: '100%',
    height: '480px/890px',
    padding: '30px 21px/119px 279px 0 238px',

    fontFamily: spoqa,
  },
  title: {
    color: '#5E8EC0/#1F242A',
    letterSpacing: '2px',
    fontSize: '12px/15px',
    lineHeight: '14px/18px',

    marginBottom: '30px/36px',
  },
  articleBox: {
    height: '180px/209px',
    display: 'flex',
    marginBottom: '/115px',
  },
  img: {
    width: '149px/312px',
    height: '124px/',
    marginRight: '24px/50px',
  },
  articleTitle: {
    width: '198px/',
    fontSize: '14px/23px',
    lineHeight: '24px/34px',
    marginBottom: '/11px',
    wordBreak_: 'break-all/',
  },
  contents: {
    width: '198px/810px',

    display: 'flex',
    flexDirection: 'column',
    marginTop: '/17px',

    color: '#202B40',

    fontSize: '14px/18px',
    lineHeight: '25px/30px',
    marginRight: '24px/28px',
    wordBreak_: 'break-all/',
  },
  description: {
    opacity: 0.5,
    marginBottom: '12px/13px',
    wordBreak_: 'break-all/',
    letterSpacing: '-0.3px/',
  },
  articleButton: {
    color: '#9CBEE1',
    cursor: 'pointer',
    marginBottom: '20px/',
    fontSize: '14px/18px',
    lineHeight: '25px/30px',
  },
  logo: {
    minWidth: '/162px',
    height: '/35px',
    marginTop: '/10px',
  },
  division: {
    width: '100%',
    height: '/1px',
    backgroundColor: '#202B40',
    opacity: 0.2,

    marginBottom: '24px/46px',
  },
  invisible: {
    display: 'none',
  }
}, isMobile || isTablet, false);
