import React, { useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { css } from 'aphrodite';
import { Link } from 'react-router-dom';

import { CreateStyle } from '../../../shared/presentation/shared/CreateStyle';
import { totalScreenState } from '../../../shared/presentation/MobileFrame';
import DefaultInputForm from './DefaultInputForm';
import RequestBanner from './RequestBanner';
import { ReactComponent as CloseButton } from '../../assets/svgs/request-close.svg';
import {
  closedPage,
  isCheckProcess,
  isInputProcess,
  isShowRequestPopup,
  isSuccessProcess,
  useMyHistory
} from '../../../shared/presentation/HistoryHelper';
import CheckProcess from './CheckProcess';
import SuccessProcess from './SuccessProcess';

function RequestPopup() {
  const {isMobile, isTablet} = useRecoilValue(totalScreenState);
  const styles = RequestPopupStyle(isMobile, isTablet);
  const history = useMyHistory();
  const myRef = useRef<HTMLDivElement>(null);

  return (
    <>
      {isShowRequestPopup(history) &&
      <div className={'popup background'}>
        <div className={css(styles.wrapper)} ref={myRef}>
          <CloseButton
            className={css(styles.closeButton)}
            onClick={() => history.push(closedPage)}
          />
          <RequestBanner/>
          {isInputProcess(history) &&
          <DefaultInputForm reference={myRef} />
          }
          {isCheckProcess(history) &&
          <CheckProcess />
          }
          {isSuccessProcess(history) &&
          <SuccessProcess/>
          }
        </div>
        <Link
          className="background touchable"
          to={closedPage}
          data-testid="background"
        />
      </div>
      }
    </>
  );
}

export default RequestPopup;

const RequestPopupStyle = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  wrapper: {
    width: '100%/1100px',
    height: '100%/648px',

    backgroundColor: 'white',

    position: 'relative',

    display_: '/flex',

    zIndex: 100,

    overflow_: 'scroll/'
  },
  closeButton: {
    width: '21px/21px',
    height: '21px/21px',

    position: 'absolute',
    top: '30px/27px',
    right: '26px/20px',

    fill: 'white/black',

    cursor: 'pointer',

    zIndex: 10,
  },
}, isMobile || isTablet, false);
