import React, { useEffect, useState } from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { css } from 'aphrodite';
import CountUp from 'react-countup';
import Slider from 'react-slick';
import * as _ from 'lodash';

import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';
import { robotoRegular, spoqa } from '../../../../shared/css/css-utils';
import { Assets } from '../../shared/Assets';
import { Image } from '../../../../shared/css/Image';
import { createPixel } from '../../../../shared/helper/Util';
import { customersState } from '../../../../shared/store/CustomerStore';
import { TriggerAnimate } from '../../../../shared/FadeIn';

const sliderSetting = {
  autoplay: true,
  dots: false,
  arrows: false,
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  speed: 1000,
  autoplaySpeed: 1000,
  vertical: true,
  verticalSwiping: true,
  swipeToSlide: false,
  touchMove: false,
};

function Record() {
  const {isMobile, isTablet, isDesktop} = useRecoilValue(totalScreenState);
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [isTriggerAni, setIsTriggerAni] = useState(true);
  const styles = RecordStyle({isMobile, isTablet, innerWidth});
  const customers = useRecoilValueLoadable(customersState(process.env.REACT_APP_CUSTOMER_API_URL!));

  useEffect(() => {
    window.addEventListener(
      'resize',
      _.throttle(() => setInnerWidth(window.innerWidth), 100)
    );
    return () => window.removeEventListener(
      'resize',
      _.throttle(() => setInnerWidth(window.innerWidth), 100)
    );
  });

  return (
    <TriggerAnimate
      play={() => setIsTriggerAni(true)}
      stop={() => setIsTriggerAni(false)}
      className={css(styles.wrapper)}
      isStartAni
    >
      <div className={css(styles.recordBox)}>
        {!isDesktop &&
        <Image
          src={Assets.RecordBackMobile}
          alt={''}
          className={css(styles.backgroundMobile)}
        />
        }
        <div className={css(styles.awardBox)}>
          <Image
            src={Assets.AwardSatisfaction}
            alt={'만족도'}
            className={css(styles.awardSatisfaction)}
          />
          <Image
            src={Assets.AwardService}
            alt={'서비스'}
            className={css(styles.awardService)}
          />
        </div>
        <div>
          <div className={css(styles.countText)}>
            추천 가맹본부&nbsp;
            <span style={{fontWeight: 700}}>
              {isTriggerAni && (
                <CountUp end={11741} duration={1} separator={','}/>
              )}
              개
            </span>
          </div>
          <div className={css(styles.countText)}>
            이용고객수&nbsp;
            <span style={{fontWeight: 700}}>
              {isTriggerAni && (
                <CountUp end={21000} duration={1} separator={','}/>
              )}
              명
            </span>
          </div>
          <div className={css(styles.countText)}>
            고객 만족도&nbsp;
            <span style={{fontWeight: 700}}>
              {isTriggerAni && (
                <CountUp end={98.7} duration={1} decimals={1}/>
              )}
              점
            </span>
          </div>
          <div className={css(styles.countTextBottom)}>
            &nbsp;Award Winning Korea Marketing Forum
          </div>
        </div>
      </div>
      <div className={css(styles.realtimeTotalBox)}>
        <div className={css(styles.realtimeTitle)}>
          <div className={css(styles.realtimeTitleText)}>실시간 신청현황</div>
          <div className={css(styles.realtimeTitleDesc)}>(담당자 접수 기준)</div>
        </div>
        <div className={css(styles.realtimeRowBox)} style={{zIndex: 5}}>
          <span className={css(styles.realtimeHeaderText, styles.dateCreationWidth)}>
            날짜
          </span>
          <span className={css(styles.realtimeHeaderText, styles.userNameWidth)}>
            이름
          </span>
          <span className={css(styles.realtimeHeaderText, styles.industryWidth)}>
            업종
          </span>
          <span className={css(styles.realtimeHeaderText, styles.dosiNameWidth)}>
            지역
          </span>
        </div>
        <div className={css(styles.realtimeListBox)}>
          {customers.state === 'hasValue' &&
          <Slider {...sliderSetting}>
            {customers.contents.map((item, index) => (
              <div style={{outline: 'none'}} key={index}>
                <div className={css(styles.realtimeRowBox)}>
                  <span className={css(styles.realtimeColumnText, styles.dateCreationWidth)}>
                    {item.date_creation}
                  </span>
                  <span className={css(styles.realtimeColumnText, styles.userNameWidth)}>
                    {item.user_name}
                  </span>
                  <span className={css(styles.realtimeColumnText, styles.industryWidth)}>
                    {item.industry}
                  </span>
                  <span className={css(styles.realtimeColumnText, styles.dosiNameWidth)}>
                    {item.dosi_name}
                  </span>
                </div>
              </div>
            ))}
          </Slider>
          }
        </div>
      </div>
    </TriggerAnimate>
  );
}

export default Record;

const RecordStyle = (
  {
    isMobile,
    isTablet,
    innerWidth
  }: {
    isMobile: boolean,
    isTablet: boolean,
    innerWidth: number
  }) => CreateStyle({
  wrapper: {
    display: 'flex',
    flexDirection_: 'column/',
    justifyContent_: '/space-between',

    width: '100%',
    height: '671px/530px',

    padding: '/100px 279px 0 268px',

    fontFamily: spoqa,
  },
  backgroundMobile: {
    position: 'absolute',
    top: '24px/',
    left: 0,

    width: '100%',
    height: '300px/',
  },
  recordBox: {
    height: '338px/',
    position_: 'relative/',

    padding: '43px 0/',
  },
  awardBox: {
    display_: 'flex/',
    justifyContent_: 'center/',

    marginBottom: '23px/33px',
  },
  awardSatisfaction: {
    width: '130px/162px',
    height: '68px/84px',

    marginRight: '12px/26px',
  },
  awardService: {
    width: '140px/185px',
    height: '69px/85px',
  },
  countText: {
    fontWeight: 300,
    fontSize: '26px/40px',
    lineHeight: '34px/52px',
    textAlign_: 'center/',
    color: '#454F5D',
    marginBottom: '2px/8px',
  },
  countTextBottom: {
    fontFamily: `${robotoRegular}/${spoqa}`,
    fontWeight_: `${500}/${400}`,
    fontSize: '12px/15px',
    lineHeight: '14px/19px',
    textAlign_: 'center/',

    marginTop: '9px/20px',
    color: '#454F5D',
  },
  realtimeTotalBox: {
    height: '333px/',
    backgroundColor: '#F9FAFB/',

    fontWeight: 400,
    color: '#454F5D',

    position: 'relative',

    padding: '35px 20px/',
  },
  realtimeTitle: {
    display: 'flex',
    justifyContent_: 'space-between/',
    alignItems: 'center',

    marginBottom: '5px/25px',
  },
  realtimeTitleText: {
    fontWeight_: `${500}/${400}`,
    fontSize: '18px/28px',
    lineHeight: '29px/39px',

    marginRight: '/394px',
  },
  realtimeTitleDesc: {
    fontWeight_: `${300}/${400}`,
    fontSize: '12px/14px',
    lineHeight: '20px/25px',
  },
  realtimeListBox: {
    width: '369px/36vw',
    height: '45vw/12vw',

    overflow: 'hidden',

    position: 'absolute',
    top: '/120px',
    left: 0,

    marginLeft: '20px/',
  },
  realtimeRowBox: {
    width: '369px/685px',
    height: (isMobile || isTablet) ? createPixel(48, 414, innerWidth) : createPixel(58, 1920, innerWidth),

    display: 'flex',
    alignItems: 'center',

    borderBottom: '1px solid #E5E9EA/1px solid #DFE0E2',
  },
  realtimeHeaderText: {
    fontWeight: 400,
    fontSize: '12px/18px',
    lineHeight: '20px/30px',

    paddingLeft: '1px/',
  },
  realtimeColumnText: {
    fontSize: '14px/18px',
    fontWeight: 300,
    lineHeight: '24px/30px',
  },
  dateCreationWidth: {
    minWidth: '88px/',
    width: '/170px',
    marginRight: '20px/',
  },
  userNameWidth: {
    minWidth: '48px/',
    width: '/170px',
    marginRight: '10px/'
  },
  industryWidth: {
    width: '96px/170px',
    marginRight: '20px/',
  },
  dosiNameWidth: {
    width: '150px/170px'
  },
}, isMobile || isTablet, false);
