import React from 'react';
import { css } from 'aphrodite';
import { useRecoilValue } from 'recoil';

import { totalScreenState } from '../../../../shared/presentation/MobileFrame';
import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { Image } from '../../../../shared/css/Image';
import { Assets } from '../../shared/Assets';
import { robotoMedium, robotoRegular, spoqa } from '../../../../shared/css/css-utils';

const STEP_LIST = [
  {
    stepTextDesktop: 'STEP 1',
    stepTextMobile: 'Step 1',
    contentTextDesktop: '프랜차이즈\n' +
      '빅데이터 분석',
    contentTextMobile: '전문가 파트너십\n' +
      '신청서 접수',
    isMobileLeft: true
  },
  {
    stepTextDesktop: 'STEP 2',
    stepTextMobile: 'Step 2',
    contentTextDesktop: '데이터 기반 유망\n' +
      '프랜차이즈 1차 검증',
    contentTextMobile: '서류검증',
    isMobileCenter: true
  },
  {
    stepTextDesktop: 'STEP 3',
    stepTextMobile: 'Step 3',
    contentTextDesktop: '본사 미팅을 통한\n' +
      '2차 검증',
    contentTextMobile: '대면검증',
    isMobileRight: true
  },
  {
    stepTextDesktop: 'STEP 4',
    stepTextMobile: 'Step 4',
    contentTextDesktop: '실제 시장 내 분석\n' +
      '기반 3차 검증',
    contentTextMobile: '실제 서비스 검증',
    isMobileLeft: true
  },
  {
    stepTextDesktop: 'STEP 5',
    stepTextMobile: 'Step 5',
    contentTextDesktop: '최종선정',
    contentTextMobile: '최종선정',
    isMobileCenter: true
  },
  {
    stepTextDesktop: 'STEP 6',
    stepTextMobile: 'Step 6',
    contentTextDesktop: '지속적 고객피드백',
    contentTextMobile: '지속적 고객 피드백',
    isMobileRight: true,
    isLast: true,
  },
];

function System() {
  const {isMobile, isTablet, isDesktop} = useRecoilValue(totalScreenState);
  const styles = SystemStyle(isMobile, isTablet);

  return (
    <div className={css(styles.wrapper)}>
      <div className={css(styles.firstTitle)}>
        {isDesktop ?
          <>
            프랜차이즈창업센터의 브랜드&nbsp;
            <span style={{fontWeight: 700}}>심층 검증 시스템</span>
          </>
          :
          <>
            프랜차이즈창업센터의<br/>
            브랜드 <span style={{fontWeight: 500}}>심층 검증 시스템</span>
          </>
        }
      </div>
      <div className={css(styles.secondTitle)}>
        프랜차이즈창업센터에서 추천드리는 브랜드는&nbsp;{!isDesktop && <br/>}
        <span style={{fontWeight: 500}}>엄격한 검증 시스템을 100% 만족한 브랜드</span>입니다.
      </div>
      {isDesktop ?
        <div className={css(styles.stepTotalBox)}>
          {STEP_LIST.map(
            (item, index) => (
              <div key={index} className={css(styles.stepBox)}>
                <div>
                  <div className={css(styles.stepText)}>
                    {item.stepTextDesktop}
                  </div>
                  <Image
                    className={css(styles.stepCircleImage)}
                    src={Assets.StepCircle}
                    alt="점"
                  />
                  <div className={css(styles.contentText)}>
                    {item.contentTextDesktop}
                  </div>
                </div>
                {item.isLast ?
                  <Image
                    className={css(styles.arrow)}
                    src={Assets.ArrowCircle}
                    alt={'화살표'}
                  />
                  :
                  <div className={css(styles.stepLine)}/>
                }
              </div>
            )
          )}
        </div>
        :
        <>
          <div className={css(styles.stepTotalBox)}>
            {STEP_LIST.filter(
              (item, filterIndex) => filterIndex <= 2).map(
              (item, mapIndex) => (
                <div key={mapIndex} className={css(styles.stepBox)}>
                  <div>
                    <div
                      className={css(
                        styles.stepText,
                        item.isMobileLeft && styles.textMobileLeft,
                        item.isMobileCenter && styles.textMobileCenter,
                        item.isMobileRight && styles.textMobileRight
                      )}
                    >
                      {item.stepTextMobile}
                    </div>
                    <Image
                      className={css(styles.stepCircleImage)}
                      src={Assets.StepCircle}
                      alt="점"
                    />
                    <div
                      className={css(
                        styles.contentText,
                        item.isMobileLeft && styles.textMobileLeft,
                        item.isMobileCenter && styles.textMobileCenter,
                        item.isMobileRight && styles.textMobileRight
                      )}
                    >
                      {item.contentTextMobile}
                    </div>
                  </div>
                  {!item.isMobileRight && <div className={css(styles.stepLine)}/>}
                </div>
              )
            )}
          </div>
          <div className={css(styles.stepTotalBox)}>
            {STEP_LIST.filter(
              (item, filterIndex) => filterIndex > 2).map(
              (item, mapIndex) => (
                <div key={mapIndex} className={css(styles.stepBox)}>
                  <div>
                    <div
                      className={css(
                        styles.stepText,
                        item.isMobileLeft && styles.textMobileLeft,
                        item.isMobileCenter && styles.textMobileCenter,
                        item.isMobileRight && styles.textMobileRight
                      )}
                    >
                      {item.stepTextMobile}
                    </div>
                    <Image
                      className={css(styles.stepCircleImage)}
                      src={Assets.StepCircle}
                      alt="점"
                    />
                    <div
                      className={css(
                        styles.contentText,
                        item.isMobileLeft && styles.textMobileLeft,
                        item.isMobileCenter && styles.textMobileCenter,
                        item.isMobileRight && styles.textMobileRight
                      )}
                    >
                      {item.contentTextMobile}
                    </div>
                  </div>
                  {!item.isMobileRight && <div className={css(styles.stepLine)}/>}
                </div>
              )
            )}
            <Image
              className={css(styles.arrow)}
              src={Assets.ArrowCircle}
              alt={'화살표'}
            />
          </div>
        </>
      }
    </div>
  );
}

export default System;

const SystemStyle = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  wrapper: {
    width: '100%',
    height: '570px/670px',

    fontFamily: spoqa,
    fontWeight: 300,
    color: '#454F5D',

    paddingTop: '44px/130px',
    paddingLeft: '/265px',

    display_: 'flex/',
    flexDirection_: 'column/',
    alignItems_: 'center/',

    background: '#F9FAFB',
  },
  firstTitle: {
    fontSize: '24px/40px',
    lineHeight: '36px/60px',
    letterSpacing: '-1px/-1px',
    textAlign_: 'center/',

    marginBottom: '9px/8px',
  },
  secondTitle: {
    fontSize: '14px/22px',
    lineHeight: '24px/32px',
    textAlign_: 'center/',

    marginBottom: '45px/167px',
  },
  stepTotalBox: {
    display: 'flex',

    paddingLeft: '/6px',

    height: '169px/',

    position: 'relative',
  },
  stepBox: {
    display: 'flex',

    position: 'relative',

    width: '100%/',
  },
  stepCircleImage: {
    width: '35px/35px',
    height: '35px/35px',
  },
  stepLine: {
    width: '120px/200px',

    margin: '18px 7px/16px 16px',

    borderTop: '1.5px solid #E5E9EA',
  },
  stepText: {
    position: 'absolute',
    top: '40px/-40px',
    left: '/-82px',
    textAlign_: '/center',

    width: '120px/200px',

    fontFamily: `${robotoMedium}/${robotoRegular}`,
    fontSize: '13px/15px',
    lineHeight: '24px/18px',
    letterSpacing: '/2px',
    color: '#5E8EC0/#9CBEE1',
  },
  contentText: {
    position: 'absolute',
    top: '66px/56px',
    left: '/-82px',
    textAlign_: '/center',

    width: '120px/200px',

    fontWeight_: `${400}/`,
    fontSize: '14px/18px',
    lineHeight: '22px/35px',
    color: '#454F5D',

    whiteSpace: 'pre',
  },
  textMobileLeft: {
    left: '5px/',
  },
  textMobileCenter: {
    left: '-42px/',
    textAlign: 'center'
  },
  textMobileRight: {
    right: '10px/',
    textAlign: 'right'
  },
  arrow: {
    position: 'absolute',
    top: '/-118px',
    left: '/-226px',

    right: '25px/',
    bottom: '30px/',

    width: '156px/222px',
  },
}, isMobile || isTablet, false);
