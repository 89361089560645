import React from 'react';
import { useRecoilValue } from 'recoil';
import { css } from 'aphrodite';

import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';
import { spoqa } from '../../../../shared/css/css-utils';
import { ReactComponent as Arrow } from '../../../assets/svgs/arrow-icon.svg';
import { NotDesktopBr } from '../../../../shared/presentation/NotDesktopBr';

const BENEFIT_LIST = (isDesktop: boolean) => [
  {
    title: '상권분석',
    desc: '상권과 입지조건에 따른 창업 아이템 추천',
    mobile_desc: '상권과 입지조건에 따른 \n창업 아이템 추천',
    price: '200,000',
  },
  {
    title: isDesktop ? '빅데이터 기반 브랜드 비교분석' : '빅데이터 기반 \n브랜드 비교분석',
    desc: '창업조건에 맞는 브랜드 객관적 비교분석',
    mobile_desc: '창업조건에 맞는 브랜드\n객관적 비교분석',
    price: '200,000',
  },
  {
    title: '사업성 검토',
    desc: '창업 타당성 및 예상 손익 분석',
    mobile_desc: '창업 타당성 및 예상 손익 \n분석',
    price: '300,000',
  },
  {
    title: '가맹본부 정보공개서/재무제표 분석',
    desc: '가맹본부의 사업 현황, 영업조건,\n' +
      '재무건전성 분석',
    mobile_desc: '가맹본부의 사업 현황, \n' +
      '영업조건, 재무건전성 분석',
    price: '250,000',
  },
  {
    title: '가맹계약서 검토',
    desc: '가맹계약서 내 불공정 계약내용 검토 및 삭제',
    mobile_desc: '가맹계약서 내 불공정 계약\n내용 검토 및 삭제',
    price: '200,000',
  },
  {
    title: '권리금 평가',
    desc: '이전 임차인에게 지불하는 권리금 분석 및 평가',
    mobile_desc: '이전 임차인에게 지불하는\n권리금 분석 및 평가',
    price: '300,000',
  },
];

function SixBenefit() {
  const {isMobile, isTablet, isDesktop} = useRecoilValue(totalScreenState);
  const styles = SixBenefitStyles(isMobile, isTablet);

  return <div className={css(styles.container)}>
    <div className={css(styles.titleBox)}>
      <div className={css(styles.title)}>
        프랜차이즈창업센터의<br/>
        <span className={css(isDesktop ? undefined : styles.bold700)}>6가지 무료 혜택</span>
      </div>
      <div className={css(styles.description)}>
        프랜차이즈창업센터 이용 시 총&nbsp;
        <span className={css(styles.bold500, styles.heightColor)}>1,450,000원</span>의&nbsp;
        <NotDesktopBr/>혜택을 제공받으실 수 있습니다.
      </div>
    </div>
    <div className={css(styles.benefitBox)}>
      {
        BENEFIT_LIST(isDesktop).map((item, index) =>
          <div key={'benefit' + index} className={css(styles.benefitItem)}>
            <div className={css(styles.itemTitle)}>{item.title}</div>
            <div className={css(styles.itemDescription)}>{isDesktop ? item.desc : item.mobile_desc}</div>
            <div className={css(styles.itemPrice)}>
              <div className={css(styles.priceLine)}><span className={css(styles.cancellationLine)}>{item.price}</span>원
              </div>
              {isDesktop && <Arrow/>}
              <div className={css(styles.freeText)}>&nbsp;무료</div>
            </div>
          </div>
        )
      }
    </div>
  </div>;
}

export default SixBenefit;

const SixBenefitStyles = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  container: {
    height: '780px/993px',
    backgroundColor: '#1F242A',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    fontFamily: spoqa,
  },
  titleBox: {
    color: 'white',
    fontWeight_: '300',

    textAlign: 'center',
    paddingTop: '40px/131px',

    marginBottom: '29px/82px',
  },
  title: {
    fontSize: '24px/40px',
    lineHeight: '34px/60px',
    marginBottom: '12px',
    letterSpacing: '-1px',
  },
  description: {
    fontSize: '14px/22px',
    lineHeight: '24px/32px',
    letterSpacing: '-0.3px/',
  },
  heightColor: {
    color: '#9CBEE1',
  },
  bold700: {
    fontWeight: 700
  },
  bold500: {
    fontWeight: 500
  },
  benefitBox: {
    margin: '/0 331px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent_: 'center/space-between',

    color: '#454F5D',
  },
  benefitItem: {
    width: '180px/406px',
    height: '168px/220px',
    background: '#FFF',
    marginBottom: '10px/20px',
    borderRadius: '5px/10px',

    padding: '20px 16px 15px 16px/30px 23px 25px 22px',
    margin: '5px/',

    position: 'relative',
  },
  itemTitle: {
    fontSize: '16px/24px',
    lineHeight: '24px/36px',
    fontWeight: 500,
    whiteSpace_: 'pre-wrap/nowrap',
    marginBottom: '5px/8px',
    letterSpacing: '-0.1px',
  },
  itemDescription: {
    fontSize: '13px/18px',
    lineHeight: '20px/32px',
    marginBottom: '/47px',
    whiteSpace: 'pre-wrap',
  },
  itemPrice: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',

    fontSize: '14px/24px',
    lineHeight: '24px/45px',

    position: 'absolute',
    bottom: '15px/22px',
    right: '16px/30px',
  },
  priceLine: {
    color: '#ACB6C3/#ACB6C4',
    marginRight: '5px',
  },
  cancellationLine: {
    textDecoration: 'line-through',
  },
  freeText: {
    marginLeft: '3px',
    color: '#6599FD',
    letterSpacing: '-2px',
    fontWeight: 500,
  }
}, isMobile || isTablet, false);

