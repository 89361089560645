import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { css } from 'aphrodite';
import { Link } from 'react-router-dom';

import { CreateStyle } from '../../../shared/presentation/shared/CreateStyle';
import { totalScreenState } from '../../../shared/presentation/MobileFrame';
import { spoqa } from '../../../shared/css/css-utils';
import { isWorkTimeState } from '../../../shared/helper/Util';
import { defaultPage } from '../../../shared/presentation/HistoryHelper';
import { useInjection } from '../../../shared/presentation/InversifyProvider';
import { SCRIPT_LOGGER, ScriptLogger } from '../../../shared/domain/ScriptLogger';
import TagManager from 'react-gtm-module';

function SuccessProcess() {
  const {isMobile, isTablet, isDesktop} = useRecoilValue(totalScreenState);
  const styles = SuccessProcessStyle(isMobile, isTablet);
  const isWorkTime = useRecoilValue(isWorkTimeState);
  const scriptLogger = useInjection<ScriptLogger>(SCRIPT_LOGGER);

  useEffect(() => {
    scriptLogger?.sendPathApproachedLog('/request/2');
    const tagManagerArgs = {
      dataLayer: {
        event: "application_completed",
      },
    }
   
    TagManager.dataLayer(tagManagerArgs);
  }, []);

  return (
    <div className={css(styles.wrapper)}>
      <div className={css(styles.successTitle)}>접수가 완료되었습니다</div>
      <div className={css(styles.hrStyle)}/>
      {isWorkTime ?
        <>
          <div className={css(styles.successSubTitle)}>
            연락 가능시간 : 평균 10분 이내
          </div>
          <div className={css(styles.successDesc)}>
            {isDesktop ?
              <>
                작성해주신 정보는 정상적으로 접수되었습니다.<br/>
                고객님의 소중한 문의 감사합니다. 신속히 연락 드리겠습니다.
              </>
              :
              <>
                작성해주신 정보는 정상적으로 접수되었습니다.<br/>
                고객님의 소중한 문의 감사합니다.<br/>
                신속히 연락 드리겠습니다.
              </>
            }
          </div>
          <div className={css(styles.buttonsBox)}>
            <Link
              className={css(styles.okayButton)}
              to={defaultPage}
            >
              확인
            </Link>
          </div>
        </>
        :
        <>
          <div className={css(styles.successSubTitle)}>
            업무시간: 평일 09:00-19:00
          </div>
          <div className={css(styles.successDesc)}>
            작성해주신 정보는 정상적으로 접수되었습니다.<br/>
            업무시간에 신속히 연락드리도록 하겠습니다. 감사합니다.
          </div>
          <div className={css(styles.buttonsBox)}>
            <Link
              className={css(styles.okayButton)}
              to={defaultPage}
            >
              확인
            </Link>
          </div>
        </>
      }
    </div>
  );
}

export default SuccessProcess;

const SuccessProcessStyle = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  wrapper: {
    padding: '24px 27px/94px 73px 54px 70px',

    fontFamily: spoqa,
    fontWeight: 300,
    color: '#454f5d',

    position: 'relative',
  },
  successTitle: {
    fontWeight_: `${300}`,
    fontSize: '24px/30px',
    lineHeight: '34px/30px',
    letterSpacing: '/1px',
  },
  hrStyle: {
    width: '/397px',
    borderTop: '1px solid #E5E9EA',
    margin: '6px 0 16px/18px 0 20px',
  },
  successSubTitle: {
    fontWeight: 400,
    fontSize: '16px/17px',
    lineHeight: '24px/21px',

    marginBottom: '8px/8px',
  },
  successDesc: {
    fontWeight_: `${300}`,
    fontSize: '13px/14px',
    lineHeight: '22px/24px',
  },
  buttonsBox: {
    position_: '/absolute',
    left: '/70px',
    bottom: '/54px',

    marginTop: '215px/',
  },
  okayButton: {
    width: '100%/393px',
    height: '56px/60px',
    background: 'black',
    borderRadius: '5px/5px',

    fontWeight: 400,
    fontSize: '15px/16px',
    lineHeight: '19px/20px',
    color: 'white',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
}, isMobile || isTablet, false);
