import React from 'react';
import { css, StyleSheet } from 'aphrodite';

import { MOBILE_SIZE } from '../helper/Util';

export function MobileBr() {
  return (<br className={css(style.mobile)}/>);
}

const style = StyleSheet.create({
  mobile: {
    display: 'none',
    [`@media ( max-width: ${MOBILE_SIZE ?? 700}px )`]: {
      display: 'block',
    }
  }
})
