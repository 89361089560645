import React from 'react';
import { useRecoilValue } from 'recoil';
import { css } from 'aphrodite';
import { Link } from 'react-router-dom';

import { totalScreenState } from '../../../shared/presentation/MobileFrame';
import { CreateStyle } from '../../../shared/presentation/shared/CreateStyle';
import { spoqa } from '../../../shared/css/css-utils';
import { Image } from '../../../shared/css/Image';
import { Assets } from './Assets';
import {
  isPartnershipPage,
  kakaoPage,
  requestPage,
  useMyHistory
} from '../../../shared/presentation/HistoryHelper';
import { scrollWindow } from '../../../shared/helper/Util';
import blogIcon from '../../assets/pngs/blog-icon.png';
import { useInjection } from '../../../shared/presentation/InversifyProvider';
import { LOCATION_SERVICE, LocationService } from '../../../shared/store/LocationStore';

function Footer() {
  const {isMobile, isTablet, isDesktop} = useRecoilValue(totalScreenState);
  const styles = FooterStyle(isMobile, isTablet);
  const history = useMyHistory();

  const MENU_LIST = [
    {
      text: '제휴 신청',
      onclick: () => window.open('/partnership'),
    },
    {
      text: '서비스 안내',
      onclick: () => scrollWindow('custom-match'),
    },
    {
      text: '서비스 이용방법',
      onclick: () => scrollWindow('process'),
    },
    {
      text: '무료상담 신청',
      onclick: () => history.push(requestPage()),
      isLast: true,
    },
  ];

  const locationService = useInjection<LocationService>(LOCATION_SERVICE);

  const showRequestOrCall = () => {
    if (isMobile) {
      locationService.callToService();
      return;
    }
  };

  return (
    <div className={css(styles.wrapper)}>
      <div className={css(styles.leftSide)}>
        <div
          className={css(styles.cxNumber)}
          onClick={showRequestOrCall}
        >
          고객센터 {process.env.REACT_APP_SERVICE_CENTER_NUMBER}
        </div>
        <div className={css(styles.workTime)}>
          오전 9시 - 오후 7시 (주말, 공휴일 제외)
        </div>
        {isDesktop &&
        (
          <>
            <Image
              src={Assets.KakaoLogo}
              alt={'kakao-icon'}
              className={css(styles.kakaoLogo)}
              onClick={() => history.push(kakaoPage())}
            />
            <Image
              src={blogIcon}
              alt={'blog-icon'}
              className={css(styles.blogLogo)}
              onClick={() => window.open('https://blog.k-franchise.org')}
            />
          </>
        )
        }
      </div>
      <div className={css(styles.rightSide)}>
        <Image
          src={Assets.FranchiseLogo}
          alt={''}
          className={css(styles.franchiseLogo)}
        />
        <div className={css(styles.menuText)}>
          {!isPartnershipPage(history) &&
          MENU_LIST.map(
            (item, index) => (
              <span
                key={index}
                className={css(styles.cursorPointer)}
                onClick={item.onclick}
              >
                  {item.text}{!item.isLast && <>&nbsp;|&nbsp;</>}
                </span>
            )
          )}
        </div>
        <div className={css(styles.policyText)}>
          <Link
            className={css(styles.cursorPointer)}
            to={'/privacy_policy'}
            target={'_blank'}
          >
            서비스 이용약관
          </Link>
          <span>&nbsp;|&nbsp;</span>
          <Link
            className={css(styles.cursorPointer)}
            to={'/terms_of_service'}
            target={'_blank'}
          >
            개인정보처리방침
          </Link>
          <span>&nbsp;|&nbsp;</span>
          <span>
            프랜차이즈창업센터
          </span>
          {isDesktop
            ? <span>&nbsp;|&nbsp;</span>
            : <br/>
          }
          <span>서울특별시 강남구 대치동 테헤란로70길 12 2층</span>
        </div>
        {!isDesktop && <br/>}
        <div className={css(styles.policyText)}>© 세무법인 세이브택스 프랜차이즈창업센터</div>
      </div>
    </div>
  );
}

export default Footer;

const FooterStyle = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  wrapper: {
    width: '100%',
    height: '350px/310px',

    backgroundColor: '#1F242A',

    fontFamily: spoqa,
    fontWeight: 300,
    color: 'white',
    textAlign_: 'center/',

    display: 'flex',
    flexDirection_: 'column/',
    alignItems_: 'center/',
    justifyContent_: 'center/'
  },
  leftSide: {
    marginTop: '/69px',
    marginLeft: '/274px',

    width: '/1000px',
  },
  rightSide: {
    marginTop: '/79px',
    marginRight: '/274px',

    textAlign_: '/right',

    width: '100%',
  },
  cxNumber: {
    fontSize: '24px/40px',
    lineHeight: '34px/52px',
    letterSpacing: '/-0.2px',

    marginBottom: '4px/6px',
  },
  workTime: {
    fontSize: '14px/18px',
    lineHeight: '24px/30px',

    marginBottom: '32px/43px',
  },
  kakaoLogo: {
    width: '/30px',
    height: '/30px',

    cursor: 'pointer',
  },
  blogLogo: {
    marginLeft: '/30px',
    width: '/30px',
    height: '/30px',

    cursor: 'pointer',
  },
  franchiseLogo: {
    width: '187px/249px',
    height: '/40px',

    marginBottom: '-2px/23px',
  },
  menuText: {
    fontSize: '12px/16px',
    lineHeight: '25px/25px',

    marginBottom: '16px/32px',
  },
  policyText: {
    fontSize: '12px/14px',
    lineHeight: '24px/35px',
  },
  cursorPointer: {
    textDecoration: 'none',
    color: 'inherit',

    cursor: 'pointer',
  },
}, isMobile || isTablet, false);
