import React, { useCallback, useState } from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { css } from 'aphrodite';
import * as _ from 'lodash';
import Slider from 'react-slick';

import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';
import { spoqa } from '../../../../shared/css/css-utils';
import { Image } from '../../../../shared/css/Image';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { MobileBr } from '../../../../shared/presentation/MobileBr';
import ArrowLeft from '../../../assets/svgs/arrow-left.svg';
import ArrowRight from '../../../assets/svgs/arrow-right.svg';
import { BlogContents, blogContentsState, DUMMY_DATA_FC } from '../../../../shared/store/BlogStore';

const sliderSetting = (isDesktop: boolean) => (
  {
    autoplay: true,
    dots: false,
    arrows: false,
    infinite: true,
    centerMode: true,
    slidesToShow: isDesktop ? 7 : 3,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
    speed: 500,
    pauseOnHover: false,
    centerPadding: isDesktop ? '24' : '0',
    draggable: false,
  }
);

const Blog = () => {
  const {isMobile, isTablet, isDesktop} = useRecoilValue(totalScreenState);
  const data = useRecoilValueLoadable(blogContentsState('fc'));
  const styles = BlogStyles(isMobile, isTablet);
  const SLIDER_DATA = data.state !== 'hasValue'
    ? DUMMY_DATA_FC
    : data.contents.length > 0
      ? data.contents
      : DUMMY_DATA_FC;
  const [slider, setSlider] = useState<any>();
  const [sliderIndex, setSliderIndex] = useState(0);

  const selectCase = (index: number) => {
    slider.slickGoTo(index);
    setSliderIndex(index);
  };
  const nextCase = () => {
    if (sliderIndex === SLIDER_DATA.length - 1) {
      selectCase(0);
      return;
    }
    selectCase(sliderIndex + 1);
  };
  const previousCase = () => {
    if (sliderIndex === 0) {
      selectCase(SLIDER_DATA.length - 1);
      return;
    }
    selectCase(sliderIndex - 1);
  };

  const convertDesc = useCallback((str: string, length: number) => {
    if (str.length === 0 || str.length < length) {
      return str;
    }
    return str.slice(0, length) + '...';
  }, [SLIDER_DATA]);

  return <div className={css(styles.container)}>
    <div className={css(styles.title)}>
      프랜차이즈 창업에 꼭 필요한 정보,<br/>
      <span className={css(styles.bold)}>프랜차이즈창업센터 블로그</span>에서<MobileBr/> 확인하세요!
    </div>
    <div className={css(styles.slider_outline)}>
      {
        data.state !== 'hasValue' || SLIDER_DATA.length === 0 ?
          <></>
          :
          <Slider
            ref={c => setSlider(c)}
            {...sliderSetting(isDesktop)}
            className={css(styles.slider_container)}
            beforeChange={(o, index) => setSliderIndex(index)}
            touchThreshold={20}
          >
            {
              isMobile ?
                _.slice(SLIDER_DATA, 0, Number(SLIDER_DATA.length / 2)).map((item: any, index: number) =>
                  <div
                    key={'slide' + index}
                    className={css(
                      styles.slider_box,
                      sliderIndex === index && styles.focused
                    )}
                    onClick={() => window.open(item.link)}
                  >
                    <Image src={item.img} alt={'thumbnail-img'} className={css(styles.slider_img_box)}/>
                    <div className={css(styles.slider_desc)}>
                      {convertDesc(item.desc, 140)}
                    </div>
                    <div className={css(styles.slider_date)}>
                      {`${item.author} / ${item.date}`}
                    </div>
                  </div>
                )
                :
                SLIDER_DATA.map((item: BlogContents, index: number) =>
                  <div
                    key={'slide' + index}
                    className={css(
                      styles.slider_box,
                      sliderIndex === index && styles.focused
                    )}
                    onClick={() => window.open(item.link)}
                  >
                    <Image src={item.img} alt={'thumbnail-img'} className={css(styles.slider_img_box)}/>
                    <div className={css(styles.slider_desc)}>
                      {
                        convertDesc(item.desc, 142)
                      }
                    </div>
                    <div className={css(styles.slider_date)}>
                      {`${item.author} / ${item.date}`}
                    </div>
                  </div>
                )
            }
          </Slider>
      }
      <Image
        src={ArrowLeft}
        alt={''}
        className={css(styles.arrowButtonLeft)}
        onClick={() => previousCase()}
      />
      <Image
        src={ArrowRight}
        alt={''}
        className={css(styles.arrowButtonRight)}
        onClick={() => nextCase()}
      />
    </div>
    <div className={css(styles.blog_button)} onClick={() => window.open('https://blog.k-franchise.org')}>블로그 바로가기</div>
  </div>;
};

export default Blog;

const BlogStyles = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  container: {
    height: '659px/910px',
    background: '#1F242A',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    overflow: 'hidden',
  },
  title: {
    margin: '41px /114px 0 37px',
    color: '#FFF',
    fontFamily: spoqa,

    fontSize: '24px/40px',
    lineHeight: '34px/60px',
    letterSpacing: '-1px',
    fontWeight: 300,

    textAlign: 'center',
  },
  bold: {
    fontWeight_: '500/700',
  },
  slider_outline: {
    width: '200%/120%',
    height: '368px/447px',
    position: 'relative',
  },
  slider_container: {
    width: '100%',
    height: '100%',

    // position: 'relative',
    // left: '1%',
    //
    // display: 'flex',
    // justifyContent: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  slider_box: {
    width: '256px/300px',
    height: '325px/',

    background: '#FFF',
    borderRadius: '6px/10px',
    transform: 'scale(0.9)',
    opacity: 0.8,

    fontFamily: spoqa,

    cursor: 'pointer',
  },
  slider_img_box: {
    width: '100%',
    borderRadius: '6px/10px',

    position: 'relative',
  },
  slider_desc: {
    height: '100px/110px',
    overflow: 'hidden',
    color: '#6A717A',
    fontSize: '12px/12px',
    lineHeight: '17px/20px',

    marginBottom: '12px/12px',
    padding: '2px 16px/0px 17px',
  },
  slider_date: {
    fontSize: '10px/10px',
    lineHeight: '17px/20px',
    textAlign: 'right',
    color: '#6A717A',

    padding: '10px 12px/14px 17px',
  },
  arrowButtonLeft: {
    position: 'absolute',
    top: '/147px',
    left: '/15%',

    width: '/86px',
    height: '/110px',

    cursor: 'pointer',
  },
  arrowButtonRight: {
    position: 'absolute',
    top: '/147px',
    right: '/15%',

    width: '/86px',
    height: '/110px',

    cursor: 'pointer',
  },
  blog_button: {
    marginTop: '/23px',
    color: '#FFF',
    border: '1px solid #DBDBDB',
    borderRadius: '50px',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    width: '185px/319px',
    height: '56px/60px',

    fontSize: '16px/17px',
    lineHeight: '20px/21px',

    cursor: 'pointer',
  },
  focused: {
    transform: 'scale(1)',
    opacity: 1,
  },
}, isMobile || isTablet, false);
