import React from 'react';
import { css } from 'aphrodite';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { SharedAssets } from '../../../shared/assets/SharedAssets';
import { totalScreenState } from '../../../shared/presentation/MobileFrame';
import { kakaoPage } from '../../../shared/presentation/HistoryHelper';
import { CreateStyle } from '../../../shared/presentation/shared/CreateStyle';

function FloatingKakao() {
  const {isTop, ...props} = useRecoilValue(totalScreenState);
  const styles = FloatingKakaoStyles(props);

  return (
    <Link
      className={css(styles.kakaoButton, !isTop && styles.shown)}
      to={kakaoPage()}
    >
      <SharedAssets.Kakao className={css(styles.kakaoStyle)}/>
    </Link>
  );
}

export default FloatingKakao;

const FloatingKakaoStyles = (
  {
    isMobile,
    isTablet = false,
  }: {
    isMobile: boolean,
    isTablet?: boolean,
  }
) => CreateStyle({
  kakaoButton: {
    display_: 'flex/none@flex',
    justifyContent: 'center',
    alignItems: 'center',

    position: 'fixed',
    right: '-90px/3.86vw@-130px',
    bottom: '11vw/11vw@35px',

    width: '13.04vw/13.04vw@82px',
    height: '13.04vw/13.04vw@82px',
    borderRadius: '50%',
    backgroundColor: '#ffe900',

    zIndex: 18,
    cursor: 'pointer',
    textDecoration: 'none',
    color: 'inherit',

    transition: '0.5s/@1s',
    transform: 'translateX(0)',
  },
  kakaoStyle: {
    width: '31px/34px@47px',
    height: '29px/32px@44px',
    objectFit: 'contain',

    fill: '#401C1D',
  },
  shown: {
    transition: '1s',
    transform: 'translateX(-200%)',
  },
}, isMobile || isTablet, false);
