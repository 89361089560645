import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { css } from 'aphrodite';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';
import { spoqa } from '../../../../shared/css/css-utils';
import { Assets } from '../../shared/Assets';
import { Image } from '../../../../shared/css/Image';

const DAMAGED_CASES = [
  {
    titleIndex: '피해사례 01',
    titleDesc: '가맹금(가맹가입비) 사기',
    caseDescDesktop:
      <>
        가맹본부가 <span>사기성으로 가맹점을 모집</span>하였고, 계약 체결과정에서<br/>
        계약금 및 가맹사업비를 본부가 수령하고 <span>연락이 되지 않는 피해 사례</span>
      </>,
    caseDescMobile:
      <>
        가맹본부가 <span>사기성으로 가맹점을 모집</span>하였고, 계약 체결<br/>
        과정에서 계약금 및 가맹사업비를 본부가 수령하고<br/>
        <span>연락이 되지 않는 피해 사례</span>
      </>,
    solutionDescDesktop:
      <>
        <span>가맹금 예치제도</span>를 통하여 사기성 가맹점 모집으로 인한 피해를<br/>
        예방할 수 있습니다. 가맹점사업자는 계약금 및 가맹가입비, 보증금, 교육비 등<br/>
        「가맹사업거래의 공정화에 관한 법률 제6조의 5」에서 정하는 예치가맹금을<br/>
        예치기관에 예치하여 가맹본부의 채무불이행을 <span>사전 예방</span>할 수 있습니다.
      </>,
    solutionDescMobile:
      <>
        <span>가맹금 예치제도</span>를 통하여 사기성 가맹점 모집으로 인한<br/>
        피해를 예방할 수 있습니다. 가맹점사업자는 계약금 및 가맹<br/>
        가입비, 보증금, 교육비 등 「가맹사업거래의 공정화에 관한<br/>
        법률 제6조의 5」에서 정하는 예치가맹금을 예치기관에 예치<br/>
        하여 <span>가맹본부의 채무불이행을 사전 예방</span>할 수 있습니다
      </>
  },
  {
    titleIndex: '피해사례 02',
    titleDesc: '가맹본부의 허위·과장된 정보제공 행위',
    caseDescDesktop:
      <>
        가맹본부가 사실과 다르게 <span>예상매출액을 과장하여 제공</span>함으로써,<br/>
        가맹점 오픈 후 실제 매출액이 가맹본부가 제공한 정보와 다른 피해 사례
      </>,
    caseDescMobile:
      <>
        가맹본부가 사실과 다르게 <span>예상매출액을 과장하여 제공</span>함으로써,
        가맹점 오픈 후 실제 매출액이 가맹본부가 제공한 정보와 다른 피해 사례
      </>,
    solutionDescDesktop:
      <>
        <span>전문가 검토</span>를 통해 가맹본부가 제공하는 예상매출액 산정서의 타당성<br/>
        분석을 하여 <span>허위정보를 사전에 예방</span>할 수 있습니다. 사후에 허위성을<br/>
        인식했을  경우에는 가맹금반환청구를 통하여 구제받을 수 있습니다.<br/>
        (「가맹사업거래의 공정화에 관한 법률 제10조 제1항」)
      </>,
    solutionDescMobile:
      <>
        <span>전문가 검토</span>를 통해 가맹본부가 제공하는 예상매출액 산정서의 타당성
        분석을 하여 <span>허위정보를 사전에 예방</span>할 수 있습니다. 사후에 허위성을
        인식했을  경우에는 가맹금반환청구를 통하여 구제받을 수 있습니다.
        (「가맹사업거래의 공정화에 관한 법률 제10조 제1항」)
      </>,
  },
  {
    titleIndex: '피해사례 03',
    titleDesc: '불공정 가맹계약',
    caseDescDesktop:
      <>
        가맹계약서는 <span>본사에 유리한 내용</span>이 많이 포함되어 있는 경우가 일반적으로<br/>
        예비창업자가 <span>불공정 항목</span>을 알아차리지 못하고 <span>계약하는 피해 사례</span>
      </>,
    caseDescMobile:
      <>
        가맹계약서는 <span>본사에 유리한 내용</span>이 많이 포함되어 있는 경우가 일반적으로
        예비창업자가 <span>불공정 항목</span>을 알아차리지 못하고 <span>계약하는 피해 사례</span>
      </>,
    solutionDescDesktop:
      <>
        <span>전문가의 검토</span>를 통해 「가맹사업거래의 공정화에 관한 법률 제11조 제2항」에서<br/>
        정하는 <span>가맹계약서 필수적 기재사항 누락여부 확인</span>과  불공정한 내용이<br/>
        반영되었을 경우 <span>계약 체결 전에 사전에 수정</span>하는 것이 무엇보다 중요합니다.
      </>,
    solutionDescMobile:
      <>
        <span>전문가의 검토</span>를 통해 「가맹사업거래의 공정화에 관한 법률 제11조 제2항」에서
        정하는 <span>가맹계약서 필수적 기재사항 누락여부 확인</span>과  불공정한 내용이
        반영되었을 경우 <span>계약 체결 전에 사전에 수정</span>하는 것이 무엇보다 중요합니다.
      </>,
  }
];

function Case() {
  const { isMobile, isTablet, isDesktop } = useRecoilValue(totalScreenState);
  const styles = CaseStyle(isMobile, isTablet);

  const [caseIndex, setCaseIndex] = useState(0);
  const selectCase= (index: number) => {
    slider.slickGoTo(index);
    setCaseIndex(index);
  }
  const nextCase = () => {
    if (caseIndex === DAMAGED_CASES.length - 1) {
      selectCase(0);
      return;
    }
    selectCase(caseIndex + 1);
  };
  const previousCase = () => {
    if (caseIndex === 0) {
      selectCase(DAMAGED_CASES.length - 1);
      return;
    }
    selectCase(caseIndex - 1);
  }

  let slider: any;
  const sliderSettingsDesktop = {
    autoplay: true,
    dots: false,
    infinite: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 300,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    beforeChange: (oldIndex: number, newIndex: number) => selectCase(newIndex),
  }

  const sliderSettingsMobile = {
    autoplay: true,
    dots: true,
    infinite: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 200,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    beforeChange: (oldIndex: number, newIndex: number) => selectCase(newIndex),
  }

  return (
    <div className={css(styles.wrapper)}>
      {isDesktop ?
        <>
          <div className={css(styles.titleText)}>
            창업과정에서 발생할 수 있는&nbsp;
            <span style={{fontWeight: 700}}>피해를 미리 점검하세요</span>
          </div>

          <div className={css(styles.subText)}>
            프랜차이즈창업센터에서는 가맹거래 관련 피해가 발생하지 않도록&nbsp;
            <span style={{fontWeight: 500}}>처음부터 끝까지 도와드립니다.</span>
          </div>
          <div className={css(styles.caseBox)}>
            <Image
              className={css(styles.caseImage)}
              src={Assets.DamagedImage01}
              alt={''}
            />

            <Slider
              ref={c => slider = c}
              {...sliderSettingsDesktop}
              className={css(styles.sliderBox)}
            >
              {DAMAGED_CASES.map((item, index) => (
                <div className={css(styles.textTable)} key={index}>
                  <div className={css(styles.titleRow)}>
                    <div className={css(styles.titleIndex)}>
                      {item.titleIndex}
                    </div>
                    <div className={css(styles.titleDesc)}>
                      {item.titleDesc}
                    </div>
                  </div>
                  <div className={css(styles.caseRow)}>
                    <div className={css(styles.caseTitle)}>사례</div>
                    <div className={css(styles.caseDesc)}>
                      {item.caseDescDesktop}
                    </div>
                  </div>
                  <div className={css(styles.divisionLine)}/>
                  <div className={css(styles.solutionRow)}>
                    <div className={css(styles.solutionTitle)}>해결</div>
                    <div className={css(styles.solutionDesc)}>
                      {item.solutionDescDesktop}
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>

          <Image
            src={Assets.DamagedCaseArrowLeft}
            alt={''}
            className={css(styles.arrowButtonLeft)}
            onClick={() => previousCase()}
          />
          <Image
            src={Assets.DamagedCaseArrowRight}
            alt={''}
            className={css(styles.arrowButtonRight)}
            onClick={() => nextCase()}
          />
        </>
        :
        <>
          <div className={css(styles.titleText)}>
            창업과정에서 발생할 수 있는&nbsp;<br/>
            <span style={{fontWeight: 500}}>피해를 미리 점검하세요</span>
          </div>

          <Slider
            ref={c => slider = c}
            {...sliderSettingsMobile}
            className={css(styles.sliderBox)}
          >
            {DAMAGED_CASES.map((item, index) => (
              <div className={css(styles.caseBox)} key={index}>
                <div className={css(styles.textTable)}>
                  <div className={css(styles.titleRow)}>
                    <div className={css(styles.titleIndex)}>
                      {DAMAGED_CASES[caseIndex].titleIndex}
                    </div>
                    <div className={css(styles.titleDesc)}>
                      {DAMAGED_CASES[caseIndex].titleDesc}
                    </div>
                  </div>
                  <div className={css(styles.caseRow)}>
                    <div className={css(styles.caseTitle)}>사례</div>
                    <div className={css(styles.caseDesc)}>
                      {DAMAGED_CASES[caseIndex].caseDescMobile}
                    </div>
                  </div>
                  <div className={css(styles.solutionRow)}>
                    <div className={css(styles.solutionTitle)}>해결</div>
                    <div className={css(styles.solutionDesc)}>
                      {DAMAGED_CASES[caseIndex].solutionDescMobile}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </>
      }
    </div>
  );
}

export default Case;

const CaseStyle = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  wrapper: {
    width: '100%',
    height: '700px/810px',

    backgroundColor: '#FAFAFA',

    padding: '40px 0 0/130px 0 0 0',

    fontFamily: spoqa,
    fontWeight: 400,
    color: '#454F5D',

    position: 'relative',
  },
  titleText: {
    fontWeight: 300,
    fontSize: '24px/40px',
    lineHeight: '36px/60px',
    textAlign: 'center',
    letterSpacing: '-1px/-1px',
  },
  subText: {
    fontWeight_: `/${300}`,
    fontSize: '/22px',
    lineHeight: '/32px',
    textAlign: 'center',
  },
  caseBox: {
    display: 'flex',
    justifyContent_: 'center/',

    marginTop: '/101px',
    marginLeft: '/268px',
  },
  caseImage: {
    width: '/565px',
    height: '/340px',

    borderRadius: '/10px',

    marginRight: '/74px',
  },
  sliderBox: {
    width: '100%/739px',
    height: '/100%',
  },
  textTable: {
    width: '350px/',
  },
  titleRow: {
    display: 'flex',
    flexDirection_: 'column/',
    alignItems_: 'center/',

    height: '/90px',
  },
  titleIndex: {
    width: '100px/100px',
    height: '34px/36px',

    marginTop: '20px/4px',
    marginRight: '/59px',
    marginBottom: '28px/',
    borderRadius: '5px/5px',
    backgroundColor: '#454f5d',

    fontWeight: 500,
    fontSize: '14px/14px',
    lineHeight: '18px/18px',
    color: 'white',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleDesc: {
    fontWeight_: `${500}/${700}`,
    fontSize: '20px/28px',
    lineHeight: '34px/45px',
    textAlign_: 'center/',

    marginBottom: '16px/',
  },
  caseRow: {
    display_: '/flex',

    marginBottom: '/22px',
  },
  caseTitle: {
    width: '/62px',
    height: '36px/39px',

    marginRight: '/96px',
    borderRadius: '5px/5px',
    backgroundColor: '#EDEEEF',
    paddingLeft: '14px/',

    fontWeight: 500,
    fontSize: '16px/18px',
    lineHeight: '20px/23px',

    display: 'flex',
    alignItems: 'center',
    justifyContent_: '/center',
  },
  caseDesc: {
    height: '126px/',
    padding: '16px 3px 0/',

    fontWeight_: `${300}`,
    fontSize: '15px/18px',
    lineHeight: '26px/35px',
    letterSpacing: '-1px/',

    ':nth-child(1n) > span': {
      fontWeight: `${500}`
    }
  },
  divisionLine: {
    width: '/739px',
    borderTop: '1px solid #dfe0e2',

    marginBottom: '/24px',
  },
  solutionRow: {
    display_: '/flex',
  },
  solutionTitle: {
    width: '/62px',
    height: '36px/39px',

    marginRight: '/96px',
    borderRadius: '5px/5px',
    backgroundColor: '#E4EBF5',
    paddingLeft: '14px/',

    fontWeight: 500,
    fontSize: '16px/18px',
    lineHeight: '20px/23px',
    color: '#6599FD',

    display: 'flex',
    alignItems: 'center',
    justifyContent_: '/center',
  },
  solutionDesc: {
    height: '190px/',
    padding: '16px 3px 0/',

    fontWeight_: `${300}`,
    fontSize: '15px/18px',
    lineHeight: '26px/35px',
    letterSpacing: '-1px/',

    ':nth-child(1n) > span': {
      fontWeight: `${500}`
    }
  },

  arrowButtonLeft: {
    position: 'absolute',
    top: '/380px',
    left: '/111px',

    width: '/26px',
    height: '/50px',

    cursor: 'pointer'
  },
  arrowButtonRight: {
    position: 'absolute',
    top: '/380px',
    right: '/111px',

    width: '/26px',
    height: '/50px',

    cursor: 'pointer'
  }
}, isMobile || isTablet, false);
