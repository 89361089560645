import React from 'react';
import { useRecoilValue } from 'recoil';
import { css } from 'aphrodite';

import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';
import { spoqa } from '../../../../shared/css/css-utils';
import ReportImage from '../../../assets/pngs/report-img.png';
import ReportMobileImage from '../../../assets/pngs/report-img-m.png';
import { Image } from '../../../../shared/css/Image';
import { DesktopBr } from '../../../../shared/presentation/DesktopBr';
import { NotDesktopBr } from '../../../../shared/presentation/NotDesktopBr';

const Guide = () => {
  const {isMobile, isTablet, isDesktop} = useRecoilValue(totalScreenState);
  const styles = GuidStyles(isMobile, isTablet);
  return <div className={css(styles.container)}>
    <div className={css(styles.titleBox)}>
      {
        <>
          가맹거래사의 {isDesktop && '프랜차이즈 '}창업 컨설팅{isDesktop && ','}<br/>
          <span className={css(styles.bold)}>무료로 받을 수 있다는 사실<NotDesktopBr/></span> 알고 계셨나요?
          {isDesktop && <Image src={ReportImage} alt={'report-img'} className={css(styles.report)}/>}
        </>
      }
    </div>
    <div className={css(styles.subTitleBox)}>
      창업 후 필수인 세무기장을 맡기시면&nbsp;<DesktopBr/>
      140만원 이상의&nbsp;<NotDesktopBr/>
      <span className={css(styles.bold500)}>가맹거래사 창업 컨설팅을 100% 무료</span>로 이용할 수 있어요.
    </div>
    {
      !isDesktop && (
        <div>
          <Image
            src={ReportMobileImage}
            alt={'report-img'}
            className={css(styles.report)}/>
        </div>
      )
    }
    <div className={css(styles.desc_box)}>
      <span className={css(styles.desc)}>세무기장 비용 : 월 80,000원 [연매출 2억원 기준]</span>
      <span className={css(styles.small)}>*세무기장 3개월 이용 조건 (창업이 완료된 경우에만 발생됨)</span>
    </div>
  </div>;
};

export default Guide;

const GuidStyles = (isMobile: boolean, isTable: boolean) => CreateStyle({
  container: {
    backgroundColor: '#F9FAFB',
    height: '608px/516px',

    color: '#454F5D',
    fontFamily: spoqa,
    fontWeight: 300,

    padding: '46px 0/98px 337px 52px 264px',

    display: 'flex',
    flexDirection: 'column',
    justifyContent_: 'center/',
    textAlign_: 'center/',
  },
  bold: {
    fontWeight: 700,
  },
  bold500: {
    fontWeight: 500,
  },
  titleBox: {
    fontSize: '24px/40px',
    lineHeight: '34px/60px',
    letterSpacing: '-1px',
    marginBottom: '12px',

    position_: '/relative',
  },
  subTitleBox: {
    marginBottom: '55px/83px',
    fontSize: '14px/22px',
    lineHeight: '24px/32px',

    letterSpacing: '-0.3px/',
  },
  desc_box: {
    display: 'flex',
    flexDirection: 'column',

    fontSize: '14px/17px',
    lineHeight: '24px/21px',

    letterSpacing: '-0.3px/',
    marginTop: '46px/',
  },
  desc: {
    fontWeight_: '500/400',
    fontSize: '/17px',
    lineHeight: '/21px',
    letterSpacing: '-0.5px',
  },
  small: {
    fontSize: '/16px',
    lineHeight: '/27px',
  },
  report: {
    position_: 'relative/absolute',
    top_: '/0',
    right_: '/0',

    width: '331px/573px',
    height: '214px/370px',
  }
}, isMobile || isTable, false);
