import React from 'react';
import { useRecoilValue } from 'recoil';
import { css } from 'aphrodite';

import { totalScreenState } from '../../../../shared/presentation/MobileFrame';
import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { robotoBlack, spoqa } from '../../../../shared/css/css-utils';
import { Assets } from '../../shared/Assets';
import { requestPage, useMyHistory } from '../../../../shared/presentation/HistoryHelper';
import { Image } from '../../../../shared/css/Image';
import { scrollWindow } from '../../../../shared/helper/Util';
import FairTradeAttorneyLogoMobile from '../../../assets/pngs/fair-trade-attorney-logo-m.png';

function Main() {
  const {isMobile, isTablet, isDesktop} = useRecoilValue(totalScreenState);
  const styles = MainStyle(isMobile, isTablet);
  const history = useMyHistory();

  return (
    <div className={css(styles.wrapper)}>
      <div className={css(styles.backgroundDarker)}/>
      {isDesktop ?
        <>
          <video
            autoPlay loop playsInline muted
            className={css(styles.backgroundVideo)}
            id={'video'}
          >
            <source src={Assets.MainBackVideo} type={'video/mp4'}/>
          </video>
          <div className={css(styles.titleFirst)}>
            세무법인이 만들어 믿을 수 있는&nbsp;
            <span style={{fontWeight: 700}}>무료 프랜차이즈 창업 컨설팅</span>
          </div>
          <div className={css(styles.titleSecond)}>KOREA NO.1</div>
          <div className={css(styles.titleThird)}>
            낮은 창업자금, 안정적인 수익을 위한 가장 현명한 방법,<br/>
            최고의 프랜차이즈를 ‘맞춤’ 연결해드립니다.
          </div>
        </>
        :
        <>
          <Image
            src={Assets.MainBackMobile}
            alt={''}
            className={css(styles.backgroundImage)}
          />
          <div className={css(styles.titleFirst)}>
            <div className={css(styles.logoBox)}>
              <Image src={FairTradeAttorneyLogoMobile} alt={'fair-trade-logo'} className={css(styles.fairLogo)}/>
            </div>
            대한민국 대표<br/>
            <span style={{fontWeight: 700}}>프랜차이즈창업센터</span>
          </div>
          <div className={css(styles.titleThird)}>
            낮은 창업자금, 안정적인 수익을 위한 가장 현명한 방법,<br/>
            최고의 프랜차이즈를 ‘맞춤’ 연결해드립니다.
          </div>
        </>
      }

      <div
        className={css(styles.requestButton)}
        onClick={() => history.push(requestPage())}
      >
        프랜차이즈창업 무료 신청
      </div>
      {isDesktop ?
        <div className={css(styles.gongJeongWeeBox)}>
          <Image
            src={Assets.GongJeongWee}
            alt={''}
            className={css(styles.gongJeongWeeLogo)}
          />
          <span>등록 제771호 가맹거래사</span>
        </div>
        :
        <Image
          src={Assets.MainMobileArrow}
          alt={''}
          className={css(styles.downArrow, styles.floating)}
          onClick={() => scrollWindow('guide')}
        />
      }
    </div>
  );
}

export default Main;

const floatingKeyframes = {
  '0%': {
    transform: 'translateY(0)',
  },
  '50%': {
    transform: 'translateY(50%)',
  },
  '100%': {
    transform: 'translateY(0)',
  },
};

const MainStyle = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  wrapper: {
    width: '100%',
    height: '727px/968px',

    display: 'flex',
    flexDirection: 'column',
    alignItems_: '/center',
    paddingTop: '287px/246px',

    fontFamily: spoqa,
    color: 'white',

    position: 'relative',
    overflow: 'hidden'
  },
  backgroundDarker: {
    position: 'absolute',
    top: 0,
    left: 0,

    width: '100%',
    height: '100%',

    zIndex: -5,
    backgroundColor: 'black',
    opacity_: `${0.45}/${0.6}`,
  },
  backgroundVideo: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    width: '100%',
    height: 'auto',
    zIndex: -10,
  },
  backgroundImage: {
    position: 'absolute',
    top: 0,
    left: 0,

    width: '100%',
    height: '100%',

    zIndex: -10,
  },
  logoBox: {
    height: '34px/',
    marginBottom: '6px/',
  },
  fairLogo: {
    width: '233px/',
    height: '34px/',
  },
  titleFirst: {
    width: '330px/',

    fontWeight_: `${400}/${300}`,
    fontSize: '30px/27px',
    lineHeight: '44px/45px',

    marginBottom: '12px/24px',
    marginLeft: '29px/',
  },
  titleSecond: {
    fontFamily: robotoBlack,
    fontWeight: 900,
    fontSize: '/155px',
    lineHeight: '/140px',
    letterSpacing: '/8px',

    marginBottom: '/30px',
    marginLeft: '29px/',
    letterSpacing_: '/-0.2px',
  },
  titleThird: {
    fontWeight_: '400/300',
    fontSize: '16px/20px',
    lineHeight: '28px/36px',
    textAlign_: '/center',

    marginBottom: '53px/60px',
    marginLeft: '29px/',

    wordBreak_: 'keep-all',
    whiteSpace_: 'pre-wrap/',
  },
  requestButton: {
    width: '330px/319px',
    height: '54px/60px',
    border: '1px solid white',
    borderRadius: '5px/5px',
    backgroundColor: 'rgba(255, 255, 255, 0.15)/',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf_: 'center/',

    fontWeight: 400,
    fontSize: '15px/20px',
    lineHeight: '19px/21px',

    cursor: 'pointer',

    ':hover': {
      backgroundColor: 'white',
      color: '#454F5D',
      transition: '0.3s',
    }
  },
  gongJeongWeeBox: {
    fontWeight: 400,
    fontSize: '/18px',
    lineHeight: '/18px',

    position: 'absolute',
    right: '/110px',
    bottom: '/90px',

    display: 'flex',
    alignItems: 'center',
  },
  gongJeongWeeLogo: {
    width: '/200px',
  },
  downArrow: {
    height: '16px/',

    marginTop: '95px/',
  },
  floating: {
    animationName: [floatingKeyframes],
    animationDuration: '1.5s',
    animationTimingFunction: 'cubic-bezier(0, 0, 1, 2)',
    animationDelay: `0.5s`,
    animationIterationCount: 'infinite',
    transition: `transform 0.75s 0s, opacity 0.75s 0s`,
  },
}, isMobile || isTablet, false);
