import React from 'react';
import { useRecoilValue } from 'recoil';
import { css } from 'aphrodite';

import { totalScreenState } from '../../../../shared/presentation/MobileFrame';
import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { spoqa } from '../../../../shared/css/css-utils';
import { requestPage, useMyHistory } from '../../../../shared/presentation/HistoryHelper';
import { Image } from '../../../../shared/css/Image';

import SiteLogo from '../../../assets/pngs/logo-group.png';
import SiteLogoM from '../../../assets/pngs/logo-group-m.png';

function SimpleRequest() {
  const {isMobile, isTablet, isDesktop} = useRecoilValue(totalScreenState);
  const styles = SimpleRequestStyle(isMobile, isTablet);
  const history = useMyHistory();

  return (
    <div className={css(styles.wrapper)}>
      <div className={css(styles.titleText)}>
        {isDesktop ?
          <>
            프랜차이즈창업&nbsp;
            <span style={{fontWeight: 700}}>
              <span style={{letterSpacing: '1px'}}>30초</span> 간편신청
            </span>
          </>
          :
          <>
            프랜차이즈창업&nbsp;<span style={{fontWeight: 500}}>30초 간편신청</span>
          </>
        }
      </div>
      <div
        className={css(styles.requestButton)}
        onClick={() => history.push(requestPage())}
      >
        프랜차이즈창업 무료 신청
      </div>
      <div className={css(styles.bottomText)}>
        * 상담신청을 위하여 남겨주신 개인정보는 개인정보취급방침에 {!isDesktop && <br/>}
        의거하여 100% 안전하게 보호됩니다.
      </div>
      {
        <div className={css(styles.bottomIconList)}>
          {
            isDesktop ?
              <Image
                src={SiteLogo}
                alt={'site'}
                className={css(styles.icon)}
              />
              :
              <Image
                src={SiteLogoM}
                alt={'site'}
                className={css(styles.icon)}
              />
          }
        </div>
      }
    </div>
  );
};

export default SimpleRequest;

const SimpleRequestStyle = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  wrapper: {
    width: '100%',
    height: '411px/530px',

    fontFamily: spoqa,
    fontWeight: 300,

    padding: '52px 0/88px 0 57px',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    position: 'relative',
  },
  titleText: {
    fontSize: '24px/40px',
    lineHeight: '34px/60px',
    color: '#454F5D',
    textAlign: 'center',
    letterSpacing: '-1px/-1px',

    marginBottom: '16px/24px',
  },
  requestButton: {
    width: '309px/319px',
    height: '52px/60px',
    border: '1px solid #1F242A',
    borderRadius: '5px/5px',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    fontWeight_: `${500}`,
    backgroundColor: '#1F242A',
    color: 'white',
    fontSize: '15px/17px',
    lineHeight: '19px/21px',

    marginBottom: '24px/46px',

    cursor: 'pointer',

    ':hover': {
      backgroundColor: 'white',
      color: '#1F242A',
      transition: '0.3s',
    }
  },
  bottomText: {
    fontSize: '14px/16px',
    lineHeight: '24px/20px',
    textAlign: 'center',
    color: '#454F5D',
    opacity: 0.5,
  },
  bottomIconList: {
    position_: '/absolute',
    bottom: 60,
    padding: '51px 42px/0 274px',
    width: '100%',
    height: '39px/',

    display: 'flex',
    alignItems: 'center',
    flexWrap_: 'wrap/nowrap',
    justifyContent_: 'space-between',
  },
  icon: {
    width: '329px/100%',
  }
}, isMobile || isTablet, false);
