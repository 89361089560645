import React from 'react';

import { withHome } from '../../../shared/WithHome';
import Main from './1-main/Main';
import Record from './2-record/Record';
import CustomMatch from './3-custom-match/CustomMatch';
import Difference from './4-difference/Difference';
import DamagedCase from './5-damaged-case/DamagedCase';
import Reason from './6-reason/Reason';
import FoundingCase from './7-founding-case/FoundingCase';
import FreeService from './11-free-service/FreeService';
import System from './12-system/System';
import Review from './15-review/Review';
import SimpleRequest from './16-simple-request/SimpleRequest';
import Footer from '../shared/Footer';
import Header from '../shared/Header';
import FloatingKakao from '../shared/FloatingKakao';
import Press from './#14-press/Press';
import Process from './14-process/Process';
import SixBenefit from './#5-six-benefit/SixBenefit';
import Guide from './#2-guide/Guide';
import Report from './#7-report/Report';
import BizType from './#11-biz-type/BizType';
import Blog from './#13-blog/Blog';

const SECTION_LIST = [
  'main',
  'record',
  'custom-match',
  'difference',
  'damaged-case',
  'reason',
  'founding-case',
  'brand-considering',
  'service',
  'choice-reason',
  'free-service',
  'system',
  'communication',
  'process',
  'review',
  'simple-request',
];

function Home() {
  return (
    <>
      <Header/>
      <FloatingKakao/>
      <div id={'main'}>
        <Main/>
      </div>
      <div id={'guide'}>
        <Guide/>
      </div>
      <div id={'record'}>
        <Record/>
      </div>
      <div id={'custom-match'}>
        <CustomMatch/>
      </div>
      <div id={'six-benefit'}>
        <SixBenefit/>
      </div>
      <div id={'difference'}>
        <Difference/>
      </div>
      <div id={'report'}>
        <Report/>
      </div>
      <div id={'damaged-case'}>
        <DamagedCase/>
      </div>
      <div id={'reason'}>
        <Reason/>
      </div>
      <div id={'free-service'}>
        <FreeService/>
      </div>
      <div id={'bizType'}>
        <BizType/>
      </div>
      <div id={'founding-case'}>
        <FoundingCase/>
      </div>
      <div id={'blog'}>
        <Blog/>
      </div>
      <div id={'process'}>
        <Process/>
      </div>
      <div id={'system'}>
        <System/>
      </div>
      <div id={'press'}>
        <Press/>
      </div>
      <div id={'review'}>
        <Review/>
      </div>
      <div id={'simple-request'}>
        <SimpleRequest/>
      </div>
      <Footer/>
    </>
  );
}

export default withHome(Home, SECTION_LIST);
