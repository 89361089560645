import React from 'react';
import { useRecoilValue } from 'recoil';
import { css } from 'aphrodite/no-important';
import { Link } from 'react-router-dom';

import { CreateStyle } from '../../../shared/presentation/shared/CreateStyle';
import { totalScreenState } from '../../../shared/presentation/MobileFrame';
import { spoqa } from '../../../shared/css/css-utils';
import {
  closedPage, isPartnershipPage,
  isShowMenu, openPartnership, requestPage, useMyHistory,
} from '../../../shared/presentation/HistoryHelper';
import { BackgroundButton } from '../../../shared/presentation/kakao-popup/BackgroundButton';
import { ReactComponent as CloseIcon } from '../../../shared/presentation/icons/close.svg';
import { useInjection } from '../../../shared/presentation/InversifyProvider';
import {
  LOCATION_SERVICE,
  LocationService
} from '../../../shared/store/LocationStore';
import { openKakaoMobilePage, scrollWindow } from '../../../shared/helper/Util';
import { ReactComponent as Kakao } from '../../../shared/presentation/icons/kakao.svg';
import { ReactComponent as Call } from '../../../shared/presentation/icons/call.svg';

function MobileMenu() {
  const {isMobile, isTablet, isDesktop} = useRecoilValue(totalScreenState);
  const styles = MobileMenuStyle(isMobile, isTablet);
  const history = useMyHistory();
  const locationService = useInjection<LocationService>(LOCATION_SERVICE);

  const onClickRequest = () => {
    if (isPartnershipPage(history)) {
      history.push(closedPage);
      scrollWindow('request');
      return;
    }
    history.push(requestPage());
  };

  const MENU_LIST = [
    {
      title: '블로그',
      callback: () => window.open('https://blog.k-franchise.org')
    },
    {
      title: '간편신청하기',
      callback: () => onClickRequest(),
    },
    {
      title: '파트너십 신청',
      callback: () => openPartnership(history),
    },
  ];

  if (isDesktop || !isShowMenu(history)) {
    return (
      <></>
    );
  }

  return (
    <div className={css(styles.background)}>
      <BackgroundButton/>
      <div className={css(styles.wrapper)}>
        <div className={css(styles.closeBox)}>
          <Link className={css(styles.closeLink)} to={closedPage}>
            <CloseIcon className={css(styles.closeIcon)}/>
          </Link>
        </div>
        <div>
          {isPartnershipPage(history) ?
            <div
              className={css(styles.itemTitle)}
              onClick={() => onClickRequest()}
            >
              파트너십 신청
            </div>
            :
            MENU_LIST.map((item, index) => (
              <div
                onClick={item.callback}
                key={index}
                className={css(styles.itemTitle)}
              >
                {item.title}
              </div>
            ))
          }
          <p className={css(styles.itemTitle)} onClick={() => openKakaoMobilePage()}>
            <Kakao className={css(styles.itemIcon)}/>
            카카오톡 상담
          </p>
          <p className={css(styles.itemTitle)} onClick={() => locationService.callToService()}>
            <Call className={css(styles.itemIcon)}/>
            고객센터 상담
          </p>
        </div>
        <div>
          <p className={css(styles.cxTeam)}>
            고객센터 {process.env.REACT_APP_REACT_APP_SERVICE_CENTER_NUMBER}
          </p>
          <p className={css(styles.workTime)}>
            오전 9시 - 오후 7시<br/>
            (주말, 공휴일 제외)
          </p>
        </div>

      </div>
    </div>
  );
}

export default MobileMenu;

const MobileMenuStyle = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  background: {
    zIndex: 45,
    justifyContent: 'flex-end',

    width: '100vw',
    height: '100vh',
    position: 'fixed',
    left: 0,
    top: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',

    borderRadius: 'inherit',
  },
  closeBox: {
    width: '77%/',
    height: '53px/',
    background: 'white',

    position: 'fixed',
    top: '0/',

    zIndex: 50,
  },
  closeLink: {
    position: 'fixed',
    top: '30px/',
    right: '26px/',

    '-webkit-tap-highlight-color': 'transparent',
  },
  closeIcon: {
    width: '20px/',
    height: '20px/',
    fill: '#1F242A',
  },
  wrapper: {
    width: '77%',
    height: '100%',
    background: 'white',

    paddingTop: '130px/',
    fontFamily: spoqa,
    fontWeight: 400,
    fontSize: '24px/',
    lineHeight: '32px/',
    textAlign: 'right',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    color: '#1F242A',

    position: 'fixed',
    right: 0,
    top: 0,

    zIndex: 50,
    overflow_: 'scroll/',
  },
  itemTitle: {
    marginRight: '36px/',
    marginBottom: '24px/',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },

  itemIcon: {
    width: '27px/',
    height: '24px/',

    marginRight: '5px/',

    fill: '#1F242A',
  },

  cxTeam: {
    lineHeight: '24px/',

    marginRight: '26px/',
    marginBottom: '18px/',
  },
  workTime: {
    fontSize: '16px/',
    lineHeight: '28px/',

    marginRight: '26px/',
    marginBottom: '185px/',
  },
}, isMobile || isTablet, false);
