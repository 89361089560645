import React from 'react';
import { useRecoilValue } from 'recoil';
import { css } from 'aphrodite';

import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';
import { spoqa } from '../../../../shared/css/css-utils';
import { Assets } from '../../shared/Assets';
import { Image } from '../../../../shared/css/Image';

function FoundingCase() {
  const {isMobile, isTablet, isDesktop} = useRecoilValue(totalScreenState);
  const stylesDesktop = FoundingCaseStyleDesktop(isMobile, isTablet);
  const stylesMobile = FoundingCaseStyleMobile(isMobile, isTablet);

  return (
    <div className={css(stylesDesktop.wrapper)}>
      <div className={css(stylesDesktop.titleText)}>
        프랜차이즈창업센터 {!isDesktop && <br/>}
        <span style={{fontWeight: isDesktop ? 700 : 500}}>고객 창업 사례</span>
      </div>
      {isDesktop ?
        <>
          <div className={css(stylesDesktop.caseBox)}>
            <Image
              className={css(stylesDesktop.caseImage)}
              src={Assets.FoundingImage01Desktop}
              alt={''}
            />
            <div className={css(stylesDesktop.detailBox)}>
              <div className={css(stylesDesktop.detailBoxTop)}>
                일반음식점
                <div className={css(stylesDesktop.topSeparator)}/>
                중식당 프랜차이즈
              </div>
              <div className={css(stylesDesktop.detailBoxMiddle)}>
                <div className={css(stylesDesktop.middleCol)}>
                  <span className={css(stylesDesktop.middleColored)}>
                    지역
                  </span>
                  서울시 강남구
                </div>
                <div className={css(stylesDesktop.colSeparator)}/>
                <div className={css(stylesDesktop.middleCol)}>
                  <span className={css(stylesDesktop.middleColored)}>
                    창업 경험여부
                  </span>
                  첫 창업
                </div>
                <div className={css(stylesDesktop.colSeparator)}/>
                <div className={css(stylesDesktop.middleCol)}>
                  <span className={css(stylesDesktop.middleColored)}>
                    현재 연차
                  </span>
                  2년 6개월
                </div>
              </div>
              <div className={css(stylesDesktop.detailBoxBottom)}>
                <div className={css(stylesDesktop.bottomRows)}>
                  <div className={css(stylesDesktop.row)}>
                    <span className={css(stylesDesktop.rowFirst)}>
                      창업비용
                    </span>
                    <div className={css(stylesDesktop.secondColumn)}>
                      <div>1억 1,750 만원</div>
                      <div className={css(stylesDesktop.small)}>&nbsp;(임대보증금 제외)</div>
                    </div>
                  </div>
                  <div className={css(stylesDesktop.rowSeparator)}/>
                  <div className={css(stylesDesktop.row)}>
                    <span className={css(stylesDesktop.rowFirst)}>
                      연매출액
                    </span>
                    6억 1,320 만원
                  </div>
                  <div className={css(stylesDesktop.rowSeparator)}/>
                  <div className={css(stylesDesktop.row)}>
                    <span className={css(stylesDesktop.rowFirst)}>
                      연순이익
                    </span>
                    <div className={css(stylesDesktop.secondColumn)}>
                      <div>9,198 만원</div>
                    </div>
                  </div>
                </div>
                <div className={css(stylesDesktop.periodBox)}>
                  <div className={css(stylesDesktop.periodTextFirst)}>
                    투자비 회수기간
                  </div>
                  <div className={css(stylesDesktop.periodTextSecond)}>
                    1년 3개월
                  </div>
                  <Image
                    className={css(stylesDesktop.underlineImage)}
                    src={Assets.Underline}
                    alt={''}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
        :
        <>
          <div className={css(stylesMobile.caseImageBox)}>
            <Image
              src={Assets.FoundingImage01Desktop}
              alt={''}
              className={css(stylesMobile.caseImage)}
            />
            <div className={css(stylesMobile.caseImageBottomText)}>
              일반음식점
              <div className={css(stylesMobile.textSeparator)}/>
              중식당 프랜차이즈
            </div>
          </div>
          <div className={css(stylesMobile.tabletBox)}>
            <div className={css(stylesMobile.tableRow, stylesMobile.rowSeparator)}>
              <div className={css(stylesMobile.firstCol)}>
                창업비용
                <span className={css(stylesDesktop.small)}>&nbsp;(임대보증금 제외)</span>
              </div>
              1억 1,750 만원
            </div>
            <div className={css(stylesMobile.tableRow, stylesMobile.rowSeparator)}>
              <div className={css(stylesMobile.firstCol)}>연매출액</div>
              6억 1,320 만원
            </div>
            <div className={css(stylesMobile.tableRow, stylesMobile.rowSeparator)}>
              <div className={css(stylesMobile.firstCol)}>연순이익</div>
              9,198 만원
            </div>
            <div className={css(stylesMobile.tableRow)}>
              <div className={css(stylesMobile.firstCol)}>투자비 회수기간</div>
              <>
                <div className={css(stylesMobile.periodText)}>
                  1년 3개월
                  <Image
                    className={css(stylesMobile.underlineImage)}
                    src={Assets.Underline}
                    alt={''}
                  />
                </div>
              </>
            </div>
          </div>
        </>
      }
    </div>
  );
}

export default FoundingCase;

const FoundingCaseStyleDesktop = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  wrapper: {
    width: '100%',
    height: '630px/710px',

    backgroundColor: 'white',

    fontFamily: spoqa,
    fontWeight: 400,
    color: '#454f5d',

    paddingTop: '46px/126px',
    paddingLeft: '/268px',

    display_: 'flex/',
    flexDirection_: 'column/',
    alignItems_: 'center/',
  },
  titleText: {
    fontWeight: 300,
    fontSize: '24px/40px',
    lineHeight: '36px/60px',
    textAlign_: 'center/',
    letterSpacing: '-1px/-1px',

    marginBottom: '22px/',
    marginLeft: '/409px',
  },
  caseBox: {
    display: 'flex',
    marginTop: '/62px',
  },
  caseImage: {
    width: '/623px',
    height: '/340px',

    borderRadius: '/10px',
  },
  detailBox: {
    marginLeft: '/58px',
  },
  detailBoxTop: {
    width: '/638px',
    height: '/60px',
    borderRadius: '/10px',

    display: 'flex',
    alignItems: 'center',
    paddingLeft: '/30px',

    backgroundColor: '#f4f8fe',
    fontWeight: 500,
    fontSize: '/20px',
    lineHeight: '/45px',
    color: '#1f242a',
  },
  topSeparator: {
    height: '/16px',
    borderLeft: '1px solid #DFE0E2',
    marginRight: '/18px',
    marginLeft: '/18px',
  },
  detailBoxMiddle: {
    height: '/79px',
    display: 'flex',
    alignItems: 'center',

    paddingLeft: '/30px',
  },
  middleCol: {
    fontWeight_: `/${400}`,
    fontSize: '/17px',
    lineHeight: '/20px',
  },
  middleColored: {
    fontWeight_: `/${500}`,
    fontSize: '/17px',
    lineHeight: '/20px',
    color: '#9CBEE1',

    marginRight: '/11px',
  },
  colSeparator: {
    height: '/16px',
    borderLeft: '1px solid #DFE0E2',
    marginRight: '/28px',
    marginLeft: '/28px',
  },
  detailBoxBottom: {
    display: 'flex',
  },
  bottomRows: {
    paddingLeft: '/30px',
  },
  row: {
    fontSize: '/20px',
    lineHeight: '/30px',
    display: 'flex',
  },
  rowFirst: {
    fontWeight_: `/${500}`,
    marginRight: '/100px',
  },
  secondColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  small: {
    color: '#9FA7B1',
    fontSize: '11px/12px',
    fontWeight: 300,
    lineHeight: '20px/20px',
  },
  rowSeparator: {
    width: '/317px',
    borderTop: '1px solid #DFE0E2',
    marginTop: '/18px',
    marginBottom: '/18px',
  },
  periodBox: {
    position: 'relative',
    paddingTop: '/30px',
    paddingLeft: '/53px',
  },
  periodTextFirst: {
    fontWeight_: `${700}/${500}`,
    fontSize: '/20px',
    lineHeight: '/26px',

    marginBottom: '/8px',
  },
  periodTextSecond: {
    fontSize: '/37px',
    lineHeight: '/48px',
    fontWeight_: `${700}/${500}`,
    marginBottom: '/10px',
  },
  underlineImage: {
    width: '/160px',

    position: 'absolute',
    //bottom: '/50px',
    left: '23%',
  },
}, isMobile || isTablet, false);

const FoundingCaseStyleMobile = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  caseImageBox: {
    position: 'relative',

    marginBottom: '15px/',
  },
  caseImage: {
    width: '370px/623px',
    height: '220px/340px',

    borderRadius: '10px/10px',
  },
  caseImageBottomText: {
    position: 'absolute',
    left: 0,
    bottom: 0,

    width: '100%',
    height: '51px/',

    backgroundColor: '#E4EBF5',
    zIndex: 5,

    fontWeight: 500,
    fontSize: '16px/',
    lineHeight: '20px/',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    borderRadius: '0 0 10px 10px/',
  },
  textSeparator: {
    height: '14px/',
    border: '1px solid #454f5d',
    opacity: 0.2,

    margin: '0 19px 0 14px/',
  },
  tabletBox: {
    width: '370px/',

    fontSize: '17px/',
    lineHeight: '52px/',
  },
  tableRow: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: '20px/',
  },
  firstCol: {
    width: 'max-contents/',
    paddingLeft: '10px/',
    fontWeight: 500,
  },
  rowSeparator: {
    width: '100%',
    borderBottom: '1px solid #EDEEEF',
  },
  periodText: {
    fontWeight: 700,
    fontSize: '17px/',
    lineHeight: '52px/',

    position: 'relative'
  },
  underlineImage: {
    position: 'absolute',
    left: '-10px/',
    bottom: '7px/',

    width: '80px/',
  },
}, isMobile || isTablet, false);
