import '../shared/helper/IEHelper';

import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Switch } from 'react-router-dom';

import '../index.css';
import * as serviceWorker from '../serviceWorker';
import { RootFrame } from '../shared/presentation/RootFrame';
import Home from './pages/home/Home';
import RequestPopup from './pages/request-popup/RequestPopup';
import Partnership from './pages/partnership/Partnership';
import KakaoPopUp from '../shared/presentation/kakao-popup/KakaoPopUp';
import MobileMenu from './pages/shared/MobileMenu';
import PrivacyPolicy from '../shared/presentation/privacy-policy/PrivacyPolicy';
import TermsOfService
  from '../shared/presentation/terms-of-service/TermsOfService';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-T6L6BVV'
}

TagManager.initialize(tagManagerArgs);


ReactDOM.render(
  <RootFrame project={'franchise'}>
    <Route component={KakaoPopUp}/>
    <Route component={RequestPopup}/>
    <Route component={MobileMenu}/>
    <Switch>
      <Route exact path="/privacy_policy" component={PrivacyPolicy}/>
      <Route exact path="/terms_of_service" component={TermsOfService}/>
      <Route exact path="/partnership" component={Partnership}/>
      <Route exact path="/request" render={() => <Home request/>}/>
      <Route exact path="/kakao" render={() => <Home kakao/>}/>
      <Route component={Home}/>
    </Switch>
  </RootFrame>
  , document.getElementById('root')
);

serviceWorker.unregister();
